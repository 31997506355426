export const reportQueryKey = {
  quotaCount: "report-quota-count-key",
  projectInfo: "report-project-info-key",
  dailyParticipation: "report-daily-participation-key",
  timeParticipation: "report-time-participation-key",
  preferenceReward: "report-preference-reward-key",
  messageSendCount: "report-message-send-key",
  reactionTotalCount: "report-reaction-total-count-key",
  reactionCount: "report-reaction-count-key",
} as const;
