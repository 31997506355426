import { UpdateTemplateState } from "@bizchat/api-interface";
import { axiosInstance } from "@libs/axios/axiosInstance.tsx";

const TEMPLATE = `/template`;

export const createMessageTemplateApi = async (
  templateId: string,
  body: UpdateTemplateState,
) => {
  const url = `${TEMPLATE}/${templateId}`;
  const newBody = {
    ...body,
    rcs: {
      ...body.rcs,
      opts: {},
    },
  };
  const { data } = await axiosInstance.patch(url, newBody);
  return data;
};
