import { TermsContent } from "@components/terms/TermsContent.tsx";
import React, { ChangeEvent, ReactElement } from "react";
import { Typography } from "@components/common/Typography";
import { TermsLayout } from "@components/terms/styles/Terms.styled.ts";

interface TermsProps {
  agree: boolean;
  phone: string;
  onChangeInputText: (value: string) => void;
  onChangeInputRadio: (value: boolean) => void;
  price: number;
  validationMessage: string;
}

export const Terms = ({
  agree,
  phone,
  onChangeInputRadio,
  onChangeInputText,
  price,
  validationMessage,
}: TermsProps): ReactElement => {
  const handleChangeInputText = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeInputText(e.target.value);
  };

  const handleChangeRadio = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeInputRadio(e.target.value === "yes");
  };

  return (
    <TermsLayout>
      <TermsContent price={price} />
      <div className={"agree-container"}>
        <label>
          <input
            type="radio"
            name="agree"
            value="yes"
            checked={agree}
            onChange={handleChangeRadio}
          />
          <Typography
            as={"span"}
            $fontColor={"textBlack200"}
            $fontSize={14}
            $fontWeight={500}
          >
            개인정보 수집.활용 및 개인정보 제3자 제공에 동의합니다.
          </Typography>
        </label>
        <label>
          <input
            type="radio"
            name="agree"
            value="no"
            checked={!agree}
            onChange={handleChangeRadio}
          />
          <Typography
            as={"span"}
            $fontColor={"textBlack200"}
            $fontSize={14}
            $fontWeight={500}
          >
            개인정보 수집.활용 및 개인정보 제3자 제공에 동의하지 않습니다.
          </Typography>
        </label>
      </div>

      {agree && (
        <div className={"phoneNumber-container"}>
          <Typography
            $fontWeight={600}
            $fontColor={"textBlack200"}
            $fontSize={15}
          >
            모바일 문화상품권 제공을 위해 아래 사항을 기재해주세요.
          </Typography>
          <label className={"input-text-group"}>
            <Typography
              $fontColor={"textBlack200"}
              $fontSize={14}
              $fontWeight={500}
            >
              응답자 휴대폰 번호
            </Typography>
            <input
              type={"text"}
              value={phone}
              onChange={handleChangeInputText}
              name={"phone"}
            />
          </label>
          {validationMessage.length > 0 && (
            <Typography $fontSize={14} $fontColor={"textRed000"}>
              {validationMessage}
            </Typography>
          )}
        </div>
      )}
    </TermsLayout>
  );
};
