import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { ProgressTab } from "@components/project/common/ProgressTab.tsx";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import { MAX_WIDTH } from "@styles/styled-components";
import { Outlet, useParams } from "react-router";
import { setProjectTab, Tabs } from "@store/slices/projectSlice";

const ReportPageLayout = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${MAX_WIDTH}px;
  margin: auto;
`;

export const ReportPage = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { currentTab } = useAppSelector((state) => state.projectStore);
  const dispatch = useAppDispatch();

  const locationPathObserver = () => {
    dispatch(setProjectTab(Tabs.REPORT));
  };

  useEffect(() => {
    locationPathObserver();
  }, []);

  return (
    <ReportPageLayout>
      <ProgressTab currentTab={currentTab} />
      <Outlet
        context={{
          id,
        }}
      />
    </ReportPageLayout>
  );
};
