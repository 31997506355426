import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useOutletContext } from "react-router";
import { requestProjectInfoApi } from "@apis/project";
import { Button } from "@components/common/button";
import FlexBox from "@components/common/flex-box/FlexBox";
import {
  AtsListType,
  CampaignStatusLabel,
  GenerateStatus,
  RequestFormProjectInfo,
  SamplingFilterList,
  SelectedFilter,
} from "@bizchat/api-interface";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import {
  campaignApprovalRequestAllApi,
  requestListApi,
  updateHistoryCampaignStatus,
} from "@apis/campaign";
import * as S from "./styles/RequestView.styled.ts";
import { Toast, ToastMessageType } from "@components/common/toast/Toast";
import { RequestListTable } from "@components/project/sampling/request/RequestListTable/RequestListTable";
import { RequestFilterList } from "@components/project/sampling/request/RequestFilterList.tsx";
import { RequestSampleCount } from "@components/project/sampling/request/RequestSampleCount.tsx";
import { RequestInfo } from "@components/project/sampling/request/RequestInfo.tsx";
import { useAuth } from "@providers/AuthProvider";
import { TbLocation } from "react-icons/tb";
import { Typography } from "@components/common/Typography";
import { BounceSpinner } from "@components/common/spinner/BounceLoader.tsx";
import { generateAtsMosuApi, getFilterListApi } from "@apis/ats";
import { IoClose } from "react-icons/io5";
import { EventHandler } from "@libs/event-source-polyfill/EventHandler.tsx";
import { Option } from "@components/common/select-box/SelectBox";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import { setMdnFileId, setMdnUpload } from "@store/slices/campaignSlice.ts";
import { isProd } from "@config/processConfig.ts";
import { MdnUpload } from "@components/upload/MdnUpload.tsx";
import { queryClient } from "@libs/tanstack/queryClient.ts";
import { campaignQueryKey } from "@services/queries/keys/campaignQueryKey.ts";
import moment from "moment";
import { AxiosError } from "axios";

export interface RequestAtsList extends AtsListType {
  requestCount?: number;
  reject: boolean;
}
export const LIFE_STAGE_SEG_CODE = "life_stage_seg";

export const RequestView = (): ReactElement => {
  const navigate = useNavigate();
  const mdnUpload = useAppSelector((state) => state.campaignStore.mdnUploader);
  const sendDate = useAppSelector((state) => state.requestStore.requestDate);

  const { id } = useOutletContext<{ id: string }>();
  const { getToken } = useAuth();
  const [projectInfo, setProjectInfo] = useState<RequestFormProjectInfo>({
    campaignTitle: "",
    sndNum: {
      value: "",
      label: "",
      key: "",
    },
    sndGoalCnt: 0,
    atsGenerate: GenerateStatus.READY,
    toCallApi: false,
  });
  const [filterListInfo, setFilterListInfo] = useState<SamplingFilterList[]>(
    [],
  );
  const [requestListState, setRequestListState] = useState<RequestAtsList[]>(
    [],
  );
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter[]>([]);
  const [filterCodeList, setFilterCodeList] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const loadRequestList = async () => {
    const requestListInfo = await requestListApi(id);
    if (requestListInfo) setRequestListState(requestListInfo);
    return requestListInfo;
  };

  const loadData = async () => {
    try {
      const data = await requestProjectInfoApi(id);
      if (data) setProjectInfo(data);
      // 발송 내역
      const filterInfo = await getFilterListApi(id);

      if (filterInfo) {
        setFilterListInfo(filterInfo);
        const codeCategory: string[] = filterInfo.flatMap(
          ({ options, usage, metaType }) => {
            if (options.length > 0) return options.map(({ code }) => code);
            else if (usage.length > 0)
              return usage.map(
                ({ cat1, cat2, cat3 }) =>
                  `${metaType}-${cat1.name}-${cat2.name}-${cat3.name}`,
              );
            return [];
          },
        );
        setFilterCodeList(codeCategory);
      }
      const requestListInfo = await loadRequestList();
      if (requestListInfo && requestListInfo.length > 0) {
        const recentlyFilter = requestListInfo[0].filterQuery
          .map(({ code, data, dataType, metaType }) => {
            // usage 항목은 code가 없다. metaType으로 code 대체
            if (dataType === "cate") {
              const dataArr = data as Array<{
                cat1: string;
                cat2: string;
                cat3: string;
              }>;
              const usageData = dataArr.map((v) => {
                const code = `${v.cat1}-${v.cat2}-${v.cat3}`;
                return {
                  code,
                  label: `${v.cat1} > ${v.cat2} > ${v.cat3}`,
                };
              });
              return {
                code: metaType,
                data: usageData,
              };
            }
            return {
              code,
              data: [],
            };
          })
          .flat();

        const lifeStage: SelectedFilter | undefined = recentlyFilter.find(
          ({ code }) => code === LIFE_STAGE_SEG_CODE,
        );
        const options = filterInfo?.flatMap(({ options }) => options);
        // 제일 상단 filterQuery 기준으로 체크

        requestListInfo[0].filterQuery.forEach(({ code, data }) => {
          if (
            lifeStage &&
            code === LIFE_STAGE_SEG_CODE &&
            Array.isArray(data)
          ) {
            data.forEach((lifeCode) => {
              const label =
                options?.find((option) => option.code === lifeCode)?.label ||
                "";
              lifeStage.data.push({
                code: lifeCode,
                label,
              });
            });
          }
        });
        setSelectedFilter(recentlyFilter);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCreateCampaignSingle = (index: number) => {
    const updatedRequestListState: RequestAtsList[] = [...requestListState];
    updatedRequestListState[index] = {
      ...updatedRequestListState[index],
      status: GenerateStatus.CREATING,
    };
    setRequestListState(updatedRequestListState);
  };

  const handleCreateCampaignMultiple = (selectedCampaign: string[]) => {
    const updateData = requestListState.map((ats) => {
      if (selectedCampaign.includes(ats._id)) {
        return {
          ...ats,
          status: GenerateStatus.CREATING,
        };
      }
      return ats;
    });
    // 스피너
    setRequestListState(updateData);
  };

  const handleChangeCampaignStatus = async (
    v: Option,
    atsId: string,
    campaignId: string,
  ) => {
    const { value } = v;
    const isConfirm = confirm(`캠페인 상태를 ${value}(으)로 변경합니다.`);
    if (isConfirm) {
      try {
        const body: {
          targetProject: string;
          campaignId: string;
          campaignStatus: CampaignStatusLabel;
        } = {
          targetProject: id as string,
          campaignId,
          campaignStatus: value as CampaignStatusLabel,
        };
        const { result } = await updateHistoryCampaignStatus(body);
        if (result) {
          const newRequestListState = [...requestListState];
          const history = newRequestListState
            .find(({ _id }) => _id === atsId)
            ?.historyList?.find((item) => item.campaignId === campaignId);
          if (history) history.campaignStatus = value as CampaignStatusLabel;
          setRequestListState(newRequestListState);
          Toast.success("캠페인 상태를 변경하였습니다.");
        }
      } catch (e) {
        Toast.error("캠페인 상태 변경에 실패하였습니다.");
        console.log(e);
      }
    }
  };

  // 발송 모수 재 조회 - 실패 시
  const handleClickReGenerateAtsMosu = async () => {
    const confirmed = confirm("발송 모수를 재 조회 합니다.");
    if (!confirmed) return;

    await atsStatusUpdate();
    await generateAtsMosuApi(id);
  };

  const toastHandler = (message: string, type: ToastMessageType) => {
    switch (type) {
      case ToastMessageType.INFO:
        Toast.info(message);
        break;
      case ToastMessageType.SUCCESS:
        Toast.success(message);
        break;
      case ToastMessageType.ERROR:
        Toast.error(message);
        break;
      default:
        throw new Error(`Unsupported toast type: ${type}`);
    }
  };

  const atsSuccessHandler = async (data: string, done: boolean) => {
    const message = "발송 모수가 조회되었습니다.";
    const atsId = data.trim().replace(/"/g, "");
    if (done) {
      toastHandler(message, ToastMessageType.SUCCESS);
      await loadData();
    } else {
      if (atsId === "true" || atsId === "preflight") {
        await loadData();
      } else {
        await loadRequestList();
      }
    }
  };

  const atsFailedHandler = async (data: string, done: boolean) => {
    const atsId = data.replace(/"/g, "");
    if (done && atsId !== "preflight") {
      await loadData();
    } else {
      if (atsId === "true" || atsId === "preflight") {
        await loadData();
      } else {
        await loadRequestList();
      }
    }
  };

  const campaignSuccessHandler = async (data: any, done: boolean) => {
    const message = "캠페인이 등록되었습니다.";
    toastHandler(message, ToastMessageType.SUCCESS);
    await loadData();
  };

  const campaignFailedHandler = async (data: any, done: boolean) => {
    const message = "캠페인 등록에 실패하였습니다. 다시 시도해주세요.";
    toastHandler(message, ToastMessageType.ERROR);
    await loadData();
  };

  // 캠페인 상태 업데이트 체크
  const campaignStatusUpdateHandler = async (status?: string) => {
    if (status === "success")
      Toast.success("캠페인 상태가 업데이트 되었습니다.");
    else if (status === "failed")
      Toast.error("캠페인 상태 업데이트에 실패하였습니다.");
    // 캠페인 상세보기 캐시 초기화
    await queryClient.invalidateQueries({
      queryKey: [campaignQueryKey.details],
    });
    await loadData();
  };

  const atsStatusUpdate = async (index?: number) => {
    // project update
    setProjectInfo({
      ...projectInfo,
      atsGenerate: GenerateStatus.CREATING,
    });
    // ats update
    const updateRequestList = [...requestListState];

    if (index !== undefined && index > -1) {
      updateRequestList[index] = {
        ...updateRequestList[index],
        atsGenerate: GenerateStatus.CREATING,
      };
      setRequestListState(updateRequestList);
    } else {
      const updateState = updateRequestList.map((ats) => {
        return {
          ...ats,
          atsGenerate: GenerateStatus.CREATING,
        };
      });
      setRequestListState(updateState);
    }
  };

  const eventListeners = [
    {
      eventName: "ats-generate",
      callback: (event: any) => atsSuccessHandler(event.data, false),
    },
    {
      eventName: "ats-generate-all-done",
      callback: async (event: any) => atsSuccessHandler(event.data, true),
    },
    {
      eventName: "ats-generate-failed",
      callback: async (event: any) => atsFailedHandler(event.data, false),
    },
    {
      eventName: "ats-generate-all-failed",
      callback: async (event: any) => atsFailedHandler(event.data, true),
    },
    {
      eventName: "campaign-generate-success",
      callback: async (event: any) => campaignSuccessHandler(event.data, true),
    },
    {
      eventName: "campaign-generate-failed",
      callback: async (event: any) => campaignFailedHandler(event.data, true),
    },
    {
      eventName: "campaign-status-update",
      callback: async (event: any) => campaignStatusUpdateHandler(),
    },
    {
      eventName: "campaign-terminate",
      callback: async (event: any) => campaignStatusUpdateHandler(),
    },
    {
      eventName: "campaign-update-success",
      callback: async (event: any) => campaignStatusUpdateHandler("success"),
    },
    {
      eventName: "campaign-update-failed",
      callback: async (event: any) => campaignStatusUpdateHandler("failed"),
    },
  ];

  useEffect(() => {
    loadData().catch();
  }, []);

  const handleClickCampaignApprove = async (): Promise<void> => {
    try {
      const confirmed = confirm("캠페인 수정 끝");
      if (!confirmed) return;

      const campaignIds = requestListState
        .filter((v) => v.reject)
        .flatMap((value) => {
          const rejectedHistories = value.historyList.filter(
            (vv) => vv.campaignStatus === CampaignStatusLabel.REJECT,
          );

          return rejectedHistories.map(
            (rejectedHistory) => rejectedHistory.campaignId,
          );
        })
        .filter((id) => id);

      const body = {
        targetProject: id,
        campaignIds,
        sendDate: moment(sendDate).format("YYYY-MM-DD, HH:mm:ss"),
      };
      await campaignApprovalRequestAllApi(body);
    } catch (e: any) {
      const error = e instanceof AxiosError;
      if (error) {
        const axiosError = e as unknown as AxiosError;
        if (axiosError.response) {
          const { message } = axiosError.response.data as AxiosError;
          Toast.error(message);
        }
      } else {
        console.log(e);
        Toast.error("캠페인 승인 요청에 실패하였습니다.");
      }
    }
  };

  const hasReject = useMemo(() => {
    return requestListState.some((item) => item.reject);
  }, [requestListState]);

  /**
   * @description mdn 업로드의 경우 개발기에서만 사용가능
   * **/
  const handleChangeMdnUpload = useCallback(
    (value: boolean) => {
      if (!value) dispatch(setMdnFileId(""));
      dispatch(setMdnUpload(value));
    },
    [mdnUpload],
  );

  return (
    <>
      <RequestInfo projectInfo={projectInfo} />
      {!isProd && (
        <MdnUpload isUpload={mdnUpload} onChange={handleChangeMdnUpload} />
      )}

      <S.RequestFilterContainer>
        <FlexBox
          $flexDirection={"row"}
          $justifyContent={"space-between"}
          $alignItems={filterCodeList.length < 3 ? "center" : "flex-end"}
          className={"filter-container"}
        >
          {0 < filterListInfo.length && (
            <RequestFilterList
              projectInfo={projectInfo}
              requestListState={requestListState}
              filterCodeList={filterCodeList}
              filterListInfo={filterListInfo}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              onClickAtsUpdate={atsStatusUpdate}
            />
          )}
          <RequestSampleCount
            projectInfo={projectInfo}
            loadRequestList={loadRequestList}
          />
        </FlexBox>
      </S.RequestFilterContainer>

      {hasReject && (
        <FlexBox
          className={"mb-16"}
          $flexDirection={"row"}
          $gap={10}
          $justifyContent={"flex-start"}
        >
          <IoClose color={"#ff2e2e"} size={24} />
          <Typography
            $fontSize={14}
            $fontWeight={600}
            $fontColor={"textRed000"}
          >
            반려된 캠페인이 있습니다. 캠페인을 확인해주세요.
          </Typography>

          <Button type={"button"} onClick={handleClickCampaignApprove}>
            <TbLocation size={16} color={"#fff"} />
            <Typography $fontSize={14} as={"span"}>
              승인 요청
            </Typography>
          </Button>
        </FlexBox>
      )}

      {projectInfo.atsGenerate === GenerateStatus.FAILED && (
        <FlexBox
          className={"mb-16"}
          $flexDirection={"row"}
          $gap={10}
          $justifyContent={"flex-start"}
        >
          <IoClose color={"#ff2e2e"} size={24} />
          <Typography
            $fontSize={14}
            $fontWeight={600}
            $fontColor={"textRed000"}
          >
            모수 조회가 되지 않은 ATS가 있습니다. 목록을 확인해 주세요.
          </Typography>

          <Button type={"button"} onClick={handleClickReGenerateAtsMosu}>
            <TbLocation size={16} color={"#fff"} />
            <Typography $fontSize={14} as={"span"}>
              발송 모수 전체 재조회
            </Typography>
          </Button>
        </FlexBox>
      )}

      {projectInfo.atsGenerate === GenerateStatus.CREATING && (
        <FlexBox
          className={"mb-16"}
          $flexDirection={"row"}
          $gap={10}
          $justifyContent={"flex-start"}
        >
          <BounceSpinner size={20} />
          <Typography
            $fontSize={14}
            $fontWeight={600}
            $fontColor={"textRed000"}
          >
            발송 모수를 조회하고 있습니다.
          </Typography>
        </FlexBox>
      )}

      <RequestListTable
        projectInfo={projectInfo}
        filterCodeList={filterCodeList}
        requestListState={requestListState}
        onClickAtsUpdate={atsStatusUpdate}
        handleCreateCampaignSingle={handleCreateCampaignSingle}
        handleCreateCampaignMultiple={handleCreateCampaignMultiple}
        handleChangeCampaignStatus={handleChangeCampaignStatus}
        refetch={loadData}
      />
      <FlexBox
        $gap={20}
        $flexDirection={"row"}
        $justifyContent={"flex-end"}
        className={"mt-30"}
      >
        <Button
          aria-label={"previous-button"}
          type={"button"}
          onClick={() => {
            if (projectInfo.toCallApi) {
              navigate(`/project/details/${id}`);
            } else {
              navigate(`/edit/${id}/filtering`);
            }
          }}
        >
          <FaArrowLeft color={"#fff"} size={20} />
        </Button>
        <Button
          aria-label={"next-button"}
          type={"button"}
          disabled={!projectInfo.toCallApi}
          onClick={() => navigate(`/project/report/${id}`)}
        >
          <FaArrowRight color={"#fff"} size={20} />
        </Button>
      </FlexBox>

      <EventHandler
        id={id}
        token={getToken()}
        eventListeners={eventListeners}
      />
    </>
  );
};
