import styled from "styled-components";

export const ListLayout = styled.article`
  table {
    border-collapse: collapse !important;
    th,
    td {
      text-align: center !important;
      vertical-align: middle !important;
      padding: 0.5rem !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }

    td {
      border-top: 1px solid #adafba;
    }
  }

  .empty-list {
    width: 100%;
    background-color: white;
    text-align: center;
    padding: 1em 0;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
`;

export const EmptyTbody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 120px;
  background-color: white;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`;
