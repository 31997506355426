import { Reward, RewardType } from "@bizchat/api-interface";

/**
 * =========================================================
 * @description MARK
 * 프로젝트 등록 및 프로젝트 편집에서 사용되는
 * 리워드 관련 함수 모음 입니다.
 * =========================================================
 */

/**
 * @param {RewardType} type
 * @param{Reward[]} rewards
 */
export const rewardInputDisabled = (
  type: RewardType,
  rewards: Reward[],
): boolean => {
  const foundReward = rewards.find(({ type: stateType }) => stateType === type);
  return !foundReward;
};

/**
 * @description 리워드 총 예상 비용
 * @param {number} completedCount
 * @param {Reward[]} rewards
 */
export const rewardTotal = (
  completedCount: number,
  rewards: Reward[],
): string => {
  let total = 0;
  let isEliminated = false;
  rewards.forEach(({ completedReward, eliminateReward }) => {
    if (eliminateReward) isEliminated = true;
    if (completedReward) total += completedReward * completedCount;
  });
  if (!total) return "";
  return `${total.toLocaleString()}원 ${isEliminated ? " + ɑ" : ""}`;
};

/**
 * @description 리워드 합
 * @param {number} completedCount
 * @param {RewardType} rewardType
 * @param {Reward[]} rewards
 */
export const rewardSum = (
  completedCount: number,
  rewardType: RewardType,
  rewards: Reward[],
): string => {
  const completedReward = rewards.find(
    ({ type }) => type === rewardType,
  )?.completedReward;
  const eliminateReward = rewards.find(
    ({ type }) => type === rewardType,
  )?.eliminateReward;
  if (!completedReward) return "";
  if (eliminateReward) {
    return `${(completedCount * completedReward).toLocaleString()} 원 + ɑ`;
  }
  return `${(completedCount * completedReward).toLocaleString()} 원`;
};

/**
 * @description 탈락 리워드 value
 * @param {RewardType} rewardType
 * @param {Reward[]} rewards
 */
export const eliminateRewardInputValue = (
  rewardType: RewardType,
  rewards: Reward[],
): string => {
  return (
    rewards
      .find(({ type }) => type === rewardType)
      ?.eliminateReward?.toLocaleString() || ""
  );
};

/**
 * @description 완료 리워드 value
 * @param {RewardType} rewardType
 * @param {Reward[]} rewards
 */
export const completedRewardInputValue = (
  rewardType: RewardType,
  rewards: Reward[],
): string => {
  return (
    rewards
      .find(({ type }) => type === rewardType)
      ?.completedReward?.toLocaleString() || ""
  );
};

/**
 * @description 리워드 체크 boolean
 * @param {RewardType} rewardType
 * @param {Reward[]} rewards
 * @param {number} rewardsTypeLength
 * @param {boolean} informationConsent
 */
export const rewardCheck = (
  rewardType: RewardType,
  rewards: Reward[],
  rewardsTypeLength: number,
  informationConsent: boolean,
): boolean => {
  return (
    rewards.map(({ type }) => type).includes(rewardType) ||
    (rewards.length === rewardsTypeLength - 2 &&
      rewardType !== RewardType.OKCASHBACK) ||
    (!informationConsent &&
      rewards.length === rewardsTypeLength - 3 &&
      rewardType !== RewardType.OKCASHBACK &&
      rewardType !== RewardType.GIFTCERTIFICATE)
  );
};
