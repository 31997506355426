export type LetterSpacing = "-2" | "-1.5" | "-1" | "-0.5" | "1";
export type LineHeight = 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32 | 34;
export type FontSize =
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 20
  | 22
  | 24
  | 26
  | 28
  | 30
  | 32;

export type Variant = "h1" | "h2" | "h3" | "h4" | "h5" | "p";

export type FontWeight = 300 | 400 | 500 | 700 | 600;

export type FontColor =
  | "textDefault"
  | "textWhite"
  | "textBlack000"
  | "textBlack100"
  | "textBlack200"
  | "textGray000"
  | "textGray100"
  | "textGray200"
  | "textRed000"
  | "textRed300"
  | "textPurple000";

export const FontColor = {
  textDefault: "var(--textDefault)",
  textWhite: "var(--textWhite)",
  textBlack000: "var(--textBlack000)",
  textBlack100: "var(--textBlack100)",
  textBlack200: "var(--textBlack200)",
  textGray000: "var(--textGray000)",
  textGray100: "var(--textGray100)",
  textGray200: "var(--textGray200)",
  textRed000: "var(--textRed000)",
  textRed300: "var(--textRed300)",
  textPurple000: "var(--purple000)",
};
