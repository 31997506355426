import { RewardSelect } from "@components/terms/RewardSelect.tsx";
import { Terms } from "@components/terms/Terms.tsx";
import { useParams } from "react-router";
import React, { FormEvent, ReactElement, useEffect, useState } from "react";
import { AgreeBody, RewardPoint, RewardType } from "@bizchat/api-interface";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import {
  createRewardHistoryApi,
  getProjectInfoByUserApi,
} from "@apis/survey/survey.api.ts";
import { useNavigate } from "react-router-dom";
import { TermsPageLayout } from "@pages/styles/TermsPage.styled.ts";
import { Typography } from "@components/common/Typography";

export const TermsPage = (): ReactElement => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const [rewardType, setRewardType] = useState<RewardType>(RewardType.NONE);
  const [rewards, setRewards] = useState<RewardPoint[]>([]);
  const [agree, setAgree] = useState(true);
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    rewardInit();
  }, []);

  const rewardInit = async () => {
    try {
      if (userId) {
        const data = await getProjectInfoByUserApi(userId);
        const reward = data.filter((v) => v.point);
        setRewards(reward);
        // 리워드가 존재한다면 제일 첫 번째 값을 rewardType으로 할당
        if (reward.length > 0) {
          const type = reward[0].type as RewardType;
          if (type !== RewardType.GIFTCERTIFICATE) setAgree(false);
          setRewardType(type);
        }
      }
    } catch (e) {
      navigate({ pathname: "/error" }, { replace: true });
    }
  };

  // 이벤트 체크
  const handleChangeInput = (value: boolean | string) => {
    if (typeof value === "boolean") {
      setAgree(value);
      setPhone("");
    } else {
      setErrorMessage("");
      const numericValue = value.replace(/\D/g, "");
      setPhone(numericValue);
    }
  };

  const handleChangeRewardType = (type: RewardType) => {
    setRewardType(type);
    resetAgreeValue();
  };

  const resetAgreeValue = () => {
    setAgree(false);
    setPhone("");
  };

  const getCertificationPrice = (): number => {
    return (
      rewards.find((reward) => reward.type === RewardType.GIFTCERTIFICATE)
        ?.point || 0
    );
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    if (!userId) return;
    const inputCheck = validation();
    if (!inputCheck) return;

    try {
      const reward = rewards.find((reward) => reward.type === rewardType);
      if (reward) {
        const body: AgreeBody = {
          userId,
          certificateAgree: agree,
          phone,
          reward,
        };
        const result = await createRewardHistoryApi(body);
        if (result)
          navigate({ pathname: `/survey/end/${userId}` }, { replace: true });
      }
    } catch (e) {
      navigate({ pathname: "/error" }, { replace: true });
    }
  };

  const validation = (): boolean => {
    const certificate = rewardType === RewardType.GIFTCERTIFICATE;
    // 수집동의하고 문화상품권일 경우
    if (certificate && agree) {
      const phoneCheck = !phone || phone.length === 0;
      const message = phoneCheck ? "휴대폰 번호를 입력해주세요." : "";
      setErrorMessage(message);
      return !phoneCheck;
    }
    return true;
  };

  return (
    <TermsPageLayout>
      <form onSubmit={handleSubmit}>
        <div>
          <Typography
            className={"terms_title"}
            $fontWeight={600}
            $fontSize={18}
          >
            설문에 응답해 주셔서 감사합니다.
          </Typography>
          {rewards.length === 0 && (
            <Typography $fontSize={15} className={"mt-20"}>
              아래 버튼을 클릭하여 설문을 종료해주세요.
            </Typography>
          )}

          {rewards.length > 1 && (
            <RewardSelect
              rewardType={rewardType}
              onChangeRewardType={handleChangeRewardType}
              rewards={rewards}
            />
          )}
        </div>

        {rewardType === RewardType.GIFTCERTIFICATE && (
          <Terms
            agree={agree}
            phone={phone}
            onChangeInputText={handleChangeInput}
            onChangeInputRadio={handleChangeInput}
            price={getCertificationPrice()}
            validationMessage={errorMessage}
          />
        )}
        <FlexBox
          $flexDirection={"row"}
          $justifyContent={"flex-end"}
          className={"mt-20"}
        >
          <button className={"button"} type={"submit"}>
            설문 종료하기
          </button>
        </FlexBox>
      </form>
    </TermsPageLayout>
  );
};
