import { ModalHandler } from "@components/common/modal/ModalHandler";
import { PropsWithChildren } from "react";

interface ModalContainerProps extends PropsWithChildren {
  maxWidth?: number;
  showClose?: boolean;
  outClick?: boolean;
}

export const ModalContainer = ({
  children,
  maxWidth,
  showClose,
  outClick,
}: ModalContainerProps) => {
  return (
    <ModalHandler
      outClick={outClick}
      maxWidth={maxWidth || 800}
      showClose={showClose}
    >
      {children}
    </ModalHandler>
  );
};
