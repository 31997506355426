import { useQuery } from "@tanstack/react-query";
import { reportQueryKey } from "@services/queries/keys";
import {
  DonutChart,
  LineChartInfo,
  Quota,
  ReactionCount,
  ReactionTotalCount,
  SendSuccessCount,
} from "@bizchat/api-interface";
import { ReportProject } from "@interfaces/report/report.data.ts";
import {
  dailyParticipationApi,
  getStatusCountInReportApi,
  messageSendSuccessCountApi,
  preferenceRewardTypeApi,
  reactionCountApi,
  reactionTotalCountApi,
  reportProjectInfoApi,
  timeParticipationApi,
} from "@apis/report";

/**
 * @description 리포트 현황 조회
 * 우선 프로젝트 상세보기와 동일하게 현재의 쿼터만 표기
 */
export const useReportQuotaCountQuery = (targetProject: string) => {
  return useQuery<Quota[], unknown, Quota[], [string, string]>({
    queryKey: [reportQueryKey.quotaCount, targetProject],
    queryFn: () => getStatusCountInReportApi(targetProject),
    gcTime: 3 * 60 * 1000,
    staleTime: 30 * 1000,
    placeholderData: (previousData) => previousData,
  });
};
/**
 * @description 리포트 프로젝트 정보 조회
 */
export const useReportProjectQuery = (targetProject: string) => {
  return useQuery<ReportProject, unknown, ReportProject, [string, string]>({
    queryKey: [reportQueryKey.projectInfo, targetProject],
    queryFn: () => reportProjectInfoApi(targetProject),
    gcTime: 3 * 60 * 1000,
    staleTime: 30 * 1000,
    placeholderData: (previousData) => previousData,
  });
};

/**
 * @description 일별 반응수 통계 조회
 */
export const useDailyParticipationQuery = (targetProject: string) => {
  return useQuery<LineChartInfo, unknown, LineChartInfo, [string, string]>({
    queryKey: [reportQueryKey.dailyParticipation, targetProject],
    queryFn: () => dailyParticipationApi(targetProject),
    gcTime: 3 * 60 * 1000,
    staleTime: 30 * 1000,
    placeholderData: (previousData) => previousData,
  });
};

/**
 * @description 시간별 반응수 통계 조회
 */
export const useTimeParticipationQuery = (targetProject: string) => {
  return useQuery<LineChartInfo, unknown, LineChartInfo, [string, string]>({
    queryKey: [reportQueryKey.timeParticipation, targetProject],
    queryFn: () => timeParticipationApi(targetProject),
    gcTime: 3 * 60 * 1000,
    staleTime: 30 * 1000,
    placeholderData: (previousData) => previousData,
  });
};

/**
 * @description 완료자 리워드 유형 선호도 통계 조회
 */
export const usePreferenceRewardQuery = (targetProject: string) => {
  return useQuery<DonutChart[], unknown, DonutChart[], [string, string]>({
    queryKey: [reportQueryKey.preferenceReward, targetProject],
    queryFn: () => preferenceRewardTypeApi(targetProject),
    gcTime: 3 * 60 * 1000,
    staleTime: 30 * 1000,
    placeholderData: (previousData) => previousData,
  });
};

/**
 * @description 문자 발송 성공 통계 조회
 */
export const useMessageSendCountQuery = (targetProject: string) => {
  return useQuery<
    SendSuccessCount[],
    unknown,
    SendSuccessCount[],
    [string, string]
  >({
    queryKey: [reportQueryKey.messageSendCount, targetProject],
    queryFn: () => messageSendSuccessCountApi(targetProject),
    gcTime: 3 * 60 * 1000,
    staleTime: 30 * 1000,
    placeholderData: (previousData) => previousData,
  });
};

export const useReactionTotalCountQuery = (targetProject: string) => {
  return useQuery<
    ReactionTotalCount[],
    unknown,
    ReactionTotalCount[],
    [string, string]
  >({
    queryKey: [reportQueryKey.reactionTotalCount, targetProject],
    queryFn: () => reactionTotalCountApi(targetProject),
    gcTime: 3 * 60 * 1000,
    staleTime: 30 * 1000,
    placeholderData: (previousData) => previousData,
  });
};

export const useReactionCountQuery = (targetProject: string) => {
  return useQuery<ReactionCount[], unknown, ReactionCount[], [string, string]>({
    queryKey: [reportQueryKey.reactionCount, targetProject],
    queryFn: () => reactionCountApi(targetProject),
    gcTime: 3 * 60 * 1000,
    staleTime: 30 * 1000,
    placeholderData: (previousData) => previousData,
  });
};
