import styled from "styled-components";

export const DownloadGroupLayout = styled.div`
  button {
    width: 100px;
    min-width: 100px;
  }

  .react-datepicker-wrapper {
    max-width: 120px;
  }
`;
