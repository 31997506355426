import * as S from "@components/project/list/styles/ListBoard.styled.ts";
import moment from "moment/moment";
import { ReactElement } from "react";
import { ProjectList, ROLE } from "@bizchat/api-interface";
import { useNavigate } from "react-router";
import { useAuth } from "@providers/AuthProvider.tsx";
import { Table } from "@components/common/table/Table.tsx";
import classNames from "classnames";
import { Typography } from "@components/common/Typography";
import { Checkbox } from "@components/common/check-box/Checkbox.tsx";
import Skeleton from "react-loading-skeleton";

interface ListBoardProps {
  lists: ProjectList[];
  onChangeCheckbox?: (_id: string) => void; //
  onChangeCheckboxAll?: (check: boolean) => void;
  deletedListIds?: string[]; // 삭제할 아이디
  deleteAll?: boolean; // 전체 선택 (삭제)
  readonly?: boolean;
  pending?: boolean;
}

const ListBoardPendingRow = () => {
  return Array.from({ length: 12 }).map((_, index) => (
    <td key={`skeleton-td-${index}`}>
      <Skeleton />
    </td>
  ));
};

export const ListBoardPending = () => {
  return Array.from({ length: 10 }).map((_, index) => (
    <tr key={`tr-${index}`}>
      <ListBoardPendingRow />
    </tr>
  ));
};

export const ListBoard = ({
  onChangeCheckbox,
  onChangeCheckboxAll,
  deletedListIds,
  deleteAll,
  lists,
  readonly = false,
  pending = false,
}: ListBoardProps): ReactElement => {
  const { getUser } = useAuth();
  const user = getUser();
  const navigate = useNavigate();

  const deleteAuthority =
    user?.role === ROLE.MASTER || user?.role === ROLE.TEAM_LEADER;

  const deletePossible = (): boolean => {
    return !readonly && deleteAuthority;
  };

  const handleClickNavigateDetailsPage = (_id: string) => {
    window.scrollTo(0, 0);
    const currentUrl = new URL(window.location.href);
    const queryString = currentUrl.search;
    navigate(`/project/details/${_id}${queryString}`);
  };

  return (
    <S.ListLayout>
      <Table
        headChildren={
          <tr>
            {deletePossible() && (
              <th className={classNames("w-4")}>
                <Checkbox
                  checked={deleteAll || false}
                  onChange={() => {
                    onChangeCheckboxAll && onChangeCheckboxAll(!deleteAll);
                  }}
                ></Checkbox>
              </th>
            )}
            <th className={classNames("w-16")}>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                캠페인명
              </Typography>
            </th>
            <th className={classNames("w-10")}>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                시작일시
              </Typography>
            </th>
            <th className={classNames("w-10")}>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                종료일시
              </Typography>
            </th>
            <th className={classNames("w-5")}>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                상태
              </Typography>
            </th>
            <th className={classNames("w-7")}>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                담당자
              </Typography>
            </th>
            <th className={classNames("w-7")}>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                LOI
              </Typography>
            </th>
            <th className={classNames("w-10")}>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                발송 목표 건수
              </Typography>
            </th>
            <th>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                C(완료)
              </Typography>
            </th>
            <th>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                S(스크린아웃)
              </Typography>
            </th>
            <th>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                Q(쿼터풀)
              </Typography>
            </th>
            <th>
              <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                D(중단)
              </Typography>
            </th>
          </tr>
        }
        bodyChildren={
          <>
            {pending && <ListBoardPending />}
            {lists.length > 0 && (
              <>
                {lists.map(
                  ({
                    _id,
                    campaignTitle,
                    startDate,
                    endDate,
                    status,
                    managers,
                    loi,
                    completedCount,
                    complete,
                    screenOut,
                    quotaFull,
                    drop,
                  }) => {
                    return (
                      <tr key={_id}>
                        {deletePossible() && (
                          <td>
                            <Checkbox
                              onChange={() =>
                                onChangeCheckbox && onChangeCheckbox(_id)
                              }
                              checked={
                                (deletedListIds &&
                                  deletedListIds.includes(_id)) ||
                                false
                              }
                            />
                          </td>
                        )}
                        <td
                          onClick={() => handleClickNavigateDetailsPage(_id)}
                          className={classNames("subject")}
                        >
                          {campaignTitle || "-"}
                        </td>
                        <td>{moment(startDate).format("YYYY-MM-DD")}</td>
                        <td>{moment(endDate).format("YYYY-MM-DD")}</td>
                        <td>{status}</td>
                        <td>{managers || "-"}</td>
                        <td>{loi.toLocaleString()}분</td>
                        <td>{completedCount.toLocaleString()}</td>
                        <td>{complete?.toLocaleString()}</td>
                        <td>{screenOut?.toLocaleString()}</td>
                        <td>{quotaFull?.toLocaleString()}</td>
                        <td>{drop?.toLocaleString()}</td>
                      </tr>
                    );
                  },
                )}
              </>
            )}
          </>
        }
      />
      {!pending && lists.length === 0 && (
        <S.EmptyTbody>
          <Typography as={"span"} $fontSize={16} $fontWeight={700}>
            등록된 프로젝트가 없습니다.
            <br /> 새로운 프로젝트를 등록해 주세요.
          </Typography>
        </S.EmptyTbody>
      )}
    </S.ListLayout>
  );
};
