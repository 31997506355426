import { ReactElement } from "react";
import * as S from "@components/project/sampling/targeting/styles/SelectedLocation.styled";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox";

export const SelectedLocation = ({
  states,
}: {
  states: { code; name }[];
}): ReactElement => {
  const getSelectedLabel = () => {
    const state = states.filter(({ code }) => `${code}`.length === 2);
    const city = states.filter(({ code }) => `${code}`.length === 5);
    const town = states.filter(({ code }) => `${code}`.length === 8);
    return {
      state,
      city,
      town,
    };
  };
  return (
    <S.SelectedLocationLayout>
      <div className={"title-container"}>
        <Typography as={"span"} $fontWeight={500} $fontSize={17}>
          선택된 지역
        </Typography>
      </div>
      {0 < states.length && (
        <>
          {0 < getSelectedLabel().state.length && (
            <div>
              <Typography as={"span"} $fontWeight={500}>
                시/도
              </Typography>
              <div className={"selected-location"}>
                {getSelectedLabel().state.map(({ name, code }) => (
                  <Typography
                    as={"span"}
                    key={`selected-${code}`}
                    className={"badge"}
                  >
                    {name}
                  </Typography>
                ))}
              </div>
            </div>
          )}
          {0 < getSelectedLabel().city.length && (
            <div>
              <Typography as={"span"} $fontWeight={500}>
                시/군/구
              </Typography>
              <div className={"selected-location"}>
                {getSelectedLabel().city.map(({ name, code }) => (
                  <Typography
                    as={"span"}
                    key={`selected-${code}`}
                    className={"badge"}
                  >
                    {name}
                  </Typography>
                ))}
              </div>
            </div>
          )}
          {0 < getSelectedLabel().town.length && (
            <div>
              <Typography as={"span"} $fontWeight={500}>
                읍/면/동
              </Typography>
              <div className={"selected-location"}>
                {getSelectedLabel().town.map(({ name, code }) => (
                  <Typography
                    as={"span"}
                    key={`selected-${code}`}
                    className={"badge"}
                  >
                    {name}
                  </Typography>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {0 === states.length && (
        <FlexBox>
          <Typography as={"span"} $fontWeight={600}>
            현재 선택된 지역이 없습니다.
          </Typography>
        </FlexBox>
      )}
    </S.SelectedLocationLayout>
  );
};
