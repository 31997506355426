import { ReactElement } from "react";
import * as S from "./Header.styled.ts";
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "@components/common/Typography";
import { useAuth } from "@providers/AuthProvider.tsx";
import { Button } from "@components/common/button";
import { RxHamburgerMenu } from "react-icons/rx";
import { GoHomeFill } from "react-icons/go";

interface HeaderProps {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
}

export const Header = ({ isOpen, onClose }: HeaderProps): ReactElement => {
  const { getUser, signOut } = useAuth();
  const user = getUser();
  const navigate = useNavigate();

  const handleClickSignOut = async () => {
    const result = await signOut();
    if (result) navigate("/sign-in", { replace: true });
  };

  return (
    <S.HeaderLayout>
      <S.HeaderContainer>
        <div className={"responsive-layout"}>
          <Link to={"/"}>
            <GoHomeFill size={22} color={"#222"} />
          </Link>
          <button
            onClick={() => onClose(!isOpen)}
            className={"hamburger-button"}
            type={"button"}
            aria-label={"hamburger-menu-button"}
          >
            <RxHamburgerMenu size={22} color={"#222"} />
          </button>
        </div>

        <S.HeaderInfoContainer>
          <Typography as={"span"} $fontWeight={500} $fontSize={14}>
            {user?.displayName}
          </Typography>
          <Typography as={"span"} $fontWeight={500} $fontSize={14}>
            |
          </Typography>
          <Button
            variant={"icon"}
            onClick={handleClickSignOut}
            type={"button"}
            aria-label={"sign-out-button"}
          >
            <Typography as={"span"} $fontWeight={500} $fontSize={14}>
              로그아웃
            </Typography>
          </Button>
        </S.HeaderInfoContainer>
      </S.HeaderContainer>
    </S.HeaderLayout>
  );
};
