import styled from "styled-components";
import classNames from "classnames";
import { Typography } from "@components/common/Typography";
import React from "react";
import { CampaignTab } from "@components/campaign/modal/CampaignDetailModal.tsx";

export const CampaignTabContainer = styled.header`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor2};

  ul.tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    .tab {
      position: relative;
      top: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 40px;
      border: 1px solid ${({ theme }) => theme.colors.borderColor2};
      border-radius: 2px 2px 0 0;
      padding: 0.4em 0.8em;
      background-color: ${({ theme }) => theme.colors.gray250};

      button {
        width: 100%;
        height: 100%;

        &:hover {
          cursor: pointer;
          filter: brightness(95%);
        }

        .label {
          color: ${({ theme }) => theme.colors.gray100};
        }
      }

      &.active {
        background-color: white;
        border-bottom: transparent;

        button {
          &:hover {
            filter: brightness(100%);
            cursor: default;
          }

          .label {
            color: ${({ theme }) => theme.colors.black100};
          }
        }
      }
    }
  }
`;

interface CampaignDetailsTabProps {
  currentTab: CampaignTab;
  onClickTab: (tab: CampaignTab) => void;
}

export const CampaignDetailsTab = ({
  currentTab,
  onClickTab,
}: CampaignDetailsTabProps) => {
  const handleClickCampaignDetailTab = (tabType: CampaignTab) => {
    onClickTab(tabType);
  };

  return (
    <CampaignTabContainer>
      <ul className={"tabs"}>
        <li
          className={classNames(
            "tab",
            currentTab === CampaignTab.CAMPAIGN && "active",
          )}
        >
          <button
            type={"button"}
            onClick={() => handleClickCampaignDetailTab(CampaignTab.CAMPAIGN)}
          >
            <Typography className={"label"} $fontWeight={500}>
              캠페인
            </Typography>
          </button>
        </li>
        <li
          className={classNames(
            "tab",
            currentTab === CampaignTab.SEND && "active",
          )}
        >
          <button
            type={"button"}
            onClick={() => handleClickCampaignDetailTab(CampaignTab.SEND)}
          >
            <Typography className={"label"} $fontWeight={500}>
              발송 결과
            </Typography>
          </button>
        </li>
      </ul>
    </CampaignTabContainer>
  );
};
