import { ReactElement } from "react";
import { FaArrowRight, FaMinus } from "react-icons/fa6";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import * as S from "@components/project/sampling/targeting/styles/LocationGroupList.styled.ts";
import { LocationInitialState } from "@components/project/views/TargetingView.tsx";

interface LocationGroupListProps {
  location: LocationInitialState;
  setLocation: (location: LocationInitialState) => void;
  handleAddStateGroupClick: () => void;
}
export const LocationGroupList = ({
  location,
  setLocation,
  handleAddStateGroupClick,
}: LocationGroupListProps): ReactElement => {
  const handleRemoveGroupState = (index: number) => {
    const groupStates = [...location.groupStates];
    groupStates.splice(index, 1);
    setLocation({
      ...location,
      groupStates,
    });
  };

  const groupList = () => {
    const groupList: string[] = [];
    location.groupStates
      .map((state) => state.map(({ name }) => name))
      .forEach((state) => {
        groupList.push(state.join(","));
      });
    return groupList;
  };

  return (
    <>
      {location.groupMode && (
        <>
          <FlexBox>
            <S.AddGroupBtn onClick={handleAddStateGroupClick}>
              <FaArrowRight />
            </S.AddGroupBtn>
          </FlexBox>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            {groupList().map((group, index) => (
              <S.GroupList key={`location-group-${index}`}>
                <span>{group}</span>
                <div
                  className={"remove-btn"}
                  onClick={() => handleRemoveGroupState(index)}
                >
                  <FaMinus />
                </div>
              </S.GroupList>
            ))}
          </div>
        </>
      )}
    </>
  );
};
