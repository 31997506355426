import styled from "styled-components";

export const SideNavBarLogoBox = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SideNavBarMenuContainer = styled.div`
  width: 100%;

  .item-lists {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    li {
      padding-left: 35px;
    }

    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      align-items: center;
      height: 64px;

      &:hover {
        cursor: pointer;
      }

      button {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        gap: 16px;
      }
    }
  }

  .active {
    background-image: linear-gradient(90deg, #54b6ff, #cb03cd);
    border-radius: 50px 0 0 50px;
  }
`;
