import styled from "styled-components";

export const CampaignDetailModalLayout = styled.div`
  min-width: 850px;
  padding: 0 2em 1em;

  .campaign-details-form {
    width: 100%;
    background-color: #f7f8fa;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 40px 20px;
    margin-bottom: 1em;
    border-radius: 0 0 8px 8px;

    .campaign-details-container {
      width: 100%;
    }
  }

  .summary-layout {
    width: 100%;
  }

  .title_label {
    border-bottom: 1px solid #ededed;
    margin-bottom: 10px;
    padding-bottom: 0.5em;
  }
`;
