import React, { ChangeEvent, ReactElement } from "react";
import {
  rewardCheck,
  rewardInputDisabled,
  rewardSum,
  rewardTotal,
} from "../../utils";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { Options, Reward, RewardType } from "@bizchat/api-interface";
import {
  completedRewardInputValue,
  eliminateRewardInputValue,
  parseStringToNumber,
} from "@components/project/utils";
import * as S from "./styles/RewardCalculationTable.styled.ts";
import { Button } from "@components/common/button";
import { FaMinus, FaPlus } from "react-icons/fa6";

export interface Props {
  rewards: Reward[];
  informationConsent: boolean;
  completedCount: number;
  readOnly?: boolean;
  rewardsType: Options[];
  onChangeMessageType?: (name: string, value: string, key: string) => void;
  onChangeBillingType?: (value: number) => void;
  onChangeConsent?: (value: boolean, rewards?: Reward[]) => void;
  onChangeRewards?: (rewards: Reward[]) => void;
}

export interface FindRewardValue extends Reward {
  [key: string]: RewardType | number | undefined;
}

const rows = [
  RewardType.GIFTCERTIFICATE,
  RewardType.OKCASHBACK,
  RewardType.NAVERPAY,
];

const key = {
  completedReward: "completedReward",
  eliminateReward: "eliminateReward",
};

export const RewardCalculationTable = (props: Props): ReactElement => {
  const {
    rewards,
    informationConsent,
    completedCount,
    readOnly = false,
    rewardsType,
  } = props;

  const { onChangeRewards } = props;

  const handleChangeReward = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // 전체 선택 / 해제
    if (value === RewardType.ALL) {
      const size = informationConsent ? 2 : 3;
      if (rewards.length === rewardsType.length - size) {
        onChangeRewards && onChangeRewards([]);
      } else {
        const newRewards = informationConsent
          ? [
              {
                type: RewardType.GIFTCERTIFICATE,
                completedReward: 0,
                eliminateReward: 0,
              },
              {
                type: RewardType.NAVERPAY,
                completedReward: 0,
                eliminateReward: 0,
              },
            ]
          : [
              {
                type: RewardType.NAVERPAY,
                completedReward: 0,
                eliminateReward: 0,
              },
            ];
        onChangeRewards && onChangeRewards([...newRewards]);
      }
      return;
    }
    selectedRewardType(value as string);
  };

  //  유형 선택
  const selectedRewardType = (value: string) => {
    const newReward = [...rewards];
    const filteredReward = newReward.filter(
      ({ type }) => type !== RewardType.ALL,
    );

    const foundReward = filteredReward.find(({ type }) => type === value);
    if (foundReward) {
      const index = filteredReward.indexOf(foundReward);
      filteredReward.splice(index, 1);
    } else {
      const rewardValue = {
        type: value as RewardType,
        completedReward: 0,
        eliminateReward: 0,
      };
      if (value === RewardType.GIFTCERTIFICATE) {
        rewardValue.completedReward = 1000;
        rewardValue.eliminateReward = 0;
      }
      filteredReward.push(rewardValue);
    }
    onChangeRewards && onChangeRewards([...filteredReward]);
  };

  const handleChangeRewardCal = (
    e: ChangeEvent<HTMLInputElement>,
    type: RewardType,
    key: string,
  ) => {
    const { target } = e;
    const value = parseStringToNumber(target.value);
    const newReward = [...rewards];
    const foundReward = newReward.find(
      ({ type: stateType }) => stateType === type,
    ) as unknown as FindRewardValue;
    if (!foundReward) return;
    foundReward[key as keyof FindRewardValue] = value;
    onChangeRewards && onChangeRewards([...newReward]);
  };

  const handleClickGift = (isPlus: boolean, key: string) => {
    const newReward = [...rewards];
    const foundReward = newReward.find(
      ({ type: stateType }) => stateType === RewardType.GIFTCERTIFICATE,
    ) as unknown as FindRewardValue;
    if (!foundReward) return;
    const value = foundReward[key as keyof FindRewardValue] as number;
    if (isPlus) {
      const newValue = 100000000 < value + 1000 ? 100000000 : value + 1000;
      foundReward[key as keyof FindRewardValue] = newValue;
    } else {
      if (!value) return;
      foundReward[key as keyof FindRewardValue] = value - 1000;
    }
    onChangeRewards && onChangeRewards([...newReward]);
  };

  return (
    <S.RewardsLayout>
      <Typography $fontWeight={700}>리워드</Typography>
      <FlexBox $alignItems={"flex-start"} $gap={30}>
        <S.RewardContentLayout>
          <Typography className={"sub-title"}>리워드 설정(유형)</Typography>
          <FlexBox
            $flexDirection={"row"}
            $justifyContent={"flex-start"}
            $gap={110}
          >
            {rewardsType.map(({ label, value }) => (
              <div key={`reward-form-${value}`}>
                <input
                  onChange={handleChangeReward}
                  readOnly={readOnly}
                  disabled={
                    readOnly ||
                    value === RewardType.OKCASHBACK ||
                    !completedCount ||
                    (value === RewardType.GIFTCERTIFICATE &&
                      !informationConsent)
                  }
                  checked={rewardCheck(
                    value as RewardType,
                    rewards,
                    rewardsType.length,
                    informationConsent,
                  )}
                  name={label}
                  value={value}
                  type={"checkbox"}
                  id={value.toString()}
                />
                <label htmlFor={value.toString()}>{label}</label>
              </div>
            ))}
          </FlexBox>
        </S.RewardContentLayout>
        <S.RewardContentLayout>
          <Typography className={"sub-title"}>리워드 설정(계산)</Typography>
          <table>
            <thead>
              <tr>
                {rewardsType.map(({ label, value }) => (
                  <th key={`th-${value}`}>
                    {value !== RewardType.ALL && (
                      <div className={"reward_checkbox_wrapper"}>
                        <input
                          name={label}
                          value={value}
                          type={"checkbox"}
                          id={`${value}`}
                          onChange={handleChangeReward}
                          disabled={
                            readOnly ||
                            value === RewardType.OKCASHBACK ||
                            !completedCount ||
                            (value === RewardType.GIFTCERTIFICATE &&
                              !informationConsent)
                          }
                          checked={rewardCheck(
                            value as RewardType,
                            rewards,
                            rewardsType.length,
                            informationConsent,
                          )}
                        />
                        <label htmlFor={`${value}`}>{label}</label>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Typography as={"span"}>완료 리워드</Typography>
                </td>
                {rows.map((row) => (
                  <td key={`completed-${row}`}>
                    <FlexBox $flexDirection={"row"} $gap={1}>
                      <BasicInput
                        style={
                          row === RewardType.GIFTCERTIFICATE
                            ? { width: 150 }
                            : {}
                        }
                        readOnly={
                          readOnly || row === RewardType.GIFTCERTIFICATE
                        }
                        $textAlign={"end"}
                        disabled={readOnly || rewardInputDisabled(row, rewards)}
                        value={completedRewardInputValue(row, rewards)}
                        onChange={(e) =>
                          handleChangeRewardCal(e, row, key.completedReward)
                        }
                      />
                      <Typography as={"span"}>원</Typography>
                      {row === RewardType.GIFTCERTIFICATE && (
                        <FlexBox $flexDirection={"row"} $gap={10}>
                          <Button
                            variant={"icon"}
                            disabled={
                              readOnly ||
                              !rewards.find(
                                ({ type: stateType }) => stateType === row,
                              )
                            }
                            onClick={() =>
                              handleClickGift(true, key.completedReward)
                            }
                          >
                            <FaPlus size={13} />
                          </Button>
                          <Button
                            variant={"icon"}
                            disabled={
                              readOnly ||
                              !rewards.find(
                                ({ type: stateType }) => stateType === row,
                              )
                            }
                            onClick={() =>
                              handleClickGift(false, key.completedReward)
                            }
                          >
                            <FaMinus size={13} />
                          </Button>
                        </FlexBox>
                      )}
                    </FlexBox>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <Typography as={"span"}>탈락 리워드</Typography>
                </td>
                {rows.map((row) => (
                  <td key={`eliminate-${row}`}>
                    <FlexBox $flexDirection={"row"}>
                      <BasicInput
                        style={
                          row === RewardType.GIFTCERTIFICATE
                            ? { width: 150 }
                            : {}
                        }
                        readOnly={
                          readOnly || row === RewardType.GIFTCERTIFICATE
                        }
                        $textAlign={"end"}
                        disabled={readOnly || rewardInputDisabled(row, rewards)}
                        value={eliminateRewardInputValue(row, rewards)}
                        onChange={(e) =>
                          handleChangeRewardCal(e, row, key.eliminateReward)
                        }
                      />
                      <Typography as={"span"}>원</Typography>
                      {row === RewardType.GIFTCERTIFICATE && (
                        <FlexBox $flexDirection={"row"} $gap={10}>
                          <Button
                            variant={"icon"}
                            disabled={
                              readOnly ||
                              !rewards.find(
                                ({ type: stateType }) => stateType === row,
                              )
                            }
                            onClick={() =>
                              handleClickGift(true, key.eliminateReward)
                            }
                          >
                            <FaPlus size={13} />
                          </Button>
                          <Button
                            variant={"icon"}
                            disabled={
                              readOnly ||
                              !rewards.find(
                                ({ type: stateType }) => stateType === row,
                              )
                            }
                            onClick={() =>
                              handleClickGift(false, key.eliminateReward)
                            }
                          >
                            <FaMinus size={13} />
                          </Button>
                        </FlexBox>
                      )}
                    </FlexBox>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <Typography as={"span"}>합계</Typography>
                </td>
                {rows.map((row) => (
                  <td key={`sum-${row}`}>
                    {row !== RewardType.NAVERPAY && (
                      <Typography as={"span"} $fontWeight={500}>
                        {rewardSum(completedCount, row, rewards)}
                      </Typography>
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <Typography as={"span"} $fontWeight={600}>
                    총 예상 비용
                  </Typography>
                </td>
                <td colSpan={rewardsType.length - 1}>
                  <Typography as={"span"} $fontSize={20} $fontWeight={600}>
                    {rewardTotal(completedCount, rewards)}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </S.RewardContentLayout>
      </FlexBox>
    </S.RewardsLayout>
  );
};
