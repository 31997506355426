import { AgeUnitState, GenderUnitState } from "@bizchat/api-interface";
import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  axiosResponseData,
  AxiosResponseData,
} from "@libs/axios/axiosResponse.ts";

const SAMPLING_CONFIG = "/sampling/config";

/**
 * @description 연령 리스트
 */
export const ageUnitApi = async (): Promise<AgeUnitState | null> => {
  const url = `${SAMPLING_CONFIG}/age-unit`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<AgeUnitState>>
  >(url);
  return axiosResponseData<AgeUnitState>(data);
};

export const genderUnitApi = async (): Promise<GenderUnitState[]> => {
  const url = `${SAMPLING_CONFIG}/gender-unit`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<GenderUnitState[]>>
  >(url);
  const result = axiosResponseData<GenderUnitState[]>(data);
  if (!result) return [];
  return result;
};
