import { ChangeEvent, ReactElement } from "react";
import { SelectBox } from "@components/common/select-box/SelectBox.tsx";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { Button } from "@components/common/button/Button.tsx";
import { FaMinus, FaPlus } from "react-icons/fa6";
import {
  Options,
  RcsTemplateType,
  UrlAnalyzeList,
  UrlAnalyzeType,
} from "@bizchat/api-interface";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import * as S from "@components/project/message/styles/UrlAnalyzeGroup.styled";
import styled from "styled-components";
const SelectUrlType = [{ label: "URL 직접 입력", value: UrlAnalyzeType.URL }];

interface UrlAnalyzeGroupProps {
  urlLinkLists: UrlAnalyzeList[];
  onChangeUrlLinkListInput: (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  onChangeUrlLinkSelect: (option: Options, index: number) => void;
  onClickAddUrlLinkInput: () => void;
  onClickRemoveUrlLinkInput: (index: number) => void;
  rcsType: RcsTemplateType;
}

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  gap: 10px;
`;

export const UrlAnalyzeGroup = (props: UrlAnalyzeGroupProps): ReactElement => {
  const {
    urlLinkLists,
    onChangeUrlLinkListInput,
    onChangeUrlLinkSelect,
    onClickAddUrlLinkInput,
    onClickRemoveUrlLinkInput,
    rcsType,
  } = props;

  const handleChangeSelectBox = (option: Options, index: number) => {
    const selectExcelType = option.value === UrlAnalyzeType.EXCEL;

    if (selectExcelType) {
      const findExcelType = urlLinkLists.findIndex(
        (link) => link.type === UrlAnalyzeType.EXCEL,
      );
      if (findExcelType > -1) {
        alert("엑셀파일이 존재 합니다.");
        return;
      }
    }

    onChangeUrlLinkSelect(option, index);
  };

  return (
    <ContentsLayout label={"URL 분석"} $alignItems={"start"} $width={150}>
      <S.UrlAnalyzeGroupLayout>
        {urlLinkLists.map((list, index) => (
          <FlexBox
            key={`urlLink_box_${index}`}
            $flexDirection={"row"}
            $justifyContent={"flex-start"}
            $alignItems={"center"}
            $gap={20}
          >
            <SelectBox
              value={list.type}
              options={SelectUrlType}
              disabled={true}
              onChange={(option: Options) =>
                handleChangeSelectBox(option, index)
              }
            />
            <S.UrlAnalyzeInputGroup>
              <BasicInput
                key={index}
                name={"url"}
                readOnly={index === 0}
                disabled={index === 0}
                value={list.value}
                placeholder={"URL 직접 입력"}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChangeUrlLinkListInput(e, index)
                }
              />
              {rcsType !== 2 && (
                <ButtonGroup>
                  {index !== 0 && (
                    <Button
                      variant={"icon"}
                      onClick={() => onClickRemoveUrlLinkInput(index)}
                    >
                      <FaMinus size={14} />
                    </Button>
                  )}
                  {urlLinkLists.length < 3 &&
                    urlLinkLists.length - 1 === index && (
                      <Button
                        variant={"icon"}
                        aria-label={"URL 분석 추가 버튼"}
                        onClick={onClickAddUrlLinkInput}
                      >
                        <FaPlus size={14} />
                      </Button>
                    )}
                </ButtonGroup>
              )}
            </S.UrlAnalyzeInputGroup>
          </FlexBox>
        ))}
      </S.UrlAnalyzeGroupLayout>
    </ContentsLayout>
  );
};
