import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * @description 모달 관련 스토어
 */

export enum ModalCompType {
  EMPTY = "",
  CAMPAIGN_DETAIL = "campaign-details",
  SEND_TEST_CAMPAIGN = "send-test",
  TEMPLATE_LIST = "template-list",
  CAMPAIGN_LIST = "campaign-list",
  CREATE_DATA_LOADING = "create-filter-data-loading",
}

export namespace Modal {
  const MODAL_TYPE = {
    FADE: "fade",
    BOTTOM_SLIDE: "modal_type",
  };
  type ModalType = (typeof MODAL_TYPE)[keyof typeof MODAL_TYPE];

  export interface State {
    type: ModalCompType;
    modalType: ModalType;
    isOpen: boolean;
  }

  export interface SliceState {
    modal: State;
  }
}

const initialState: ModalStore = {
  modal: {
    type: ModalCompType.EMPTY,
    modalType: "fade",
    isOpen: false,
  },
};

export const ModalSlice = createSlice({
  name: "modalStore",
  initialState,
  reducers: {
    setModalOpen(state: ModalStore, action: PayloadAction<ModalState>) {
      state.modal = action.payload;
    },
  },
});

export type ModalStore = Modal.SliceState;
export type ModalState = Modal.State;

export const { setModalOpen } = ModalSlice.actions;
