import { useEffect, useRef } from "react";
import { EventSourcePolyfill, NativeEventSource } from "event-source-polyfill";

type EventHandlerProps = {
  id: string;
  token: string;
  eventListeners: Array<{ eventName: string; callback: (data?: any) => void }>;
};

const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.VITE_PRODUCTION_HOST
    : process.env.VITE_LOCAL_HOST;

const apiTargetUrl = "api/server-events/subscribe";

export const EventHandler = ({
  id,
  token,
  eventListeners,
}: EventHandlerProps) => {
  const EventSource = EventSourcePolyfill || NativeEventSource;
  const eventSourceRef = useRef<EventSource | null>(null);

  useEffect(() => {
    const connect = () => {
      const url = `${baseUrl}/${apiTargetUrl}/${id}`;
      const eventSource = new EventSource(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Connection: "keep-alive",
          Accept: "text/event-stream",
        },
      });

      eventSourceRef.current = eventSource;

      eventListeners.forEach(({ eventName, callback }) => {
        eventSource.addEventListener(eventName, callback);
      });

      eventSource.onerror = () => {
        if (eventSource.readyState === EventSource.CLOSED) {
          setTimeout(() => {
            connect();
          }, 3000); // 재연결 대기 시간
        }
      };
    };

    connect();

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, [id]);

  return null;
};
