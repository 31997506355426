import { AnimatePresence } from "framer-motion";
import { ReactElement } from "react";
import * as Base from "@components/common/modal/styles/base.styled.ts";
import * as F from "@components/common/modal/styles/FadeModal.styled.ts";
import { ModalProps } from "@components/common/modal/ModalHandler";
import useModalHook from "@hooks/useModalHook";
import { AiOutlineClose } from "react-icons/ai";

export const FadeModal = (props: ModalProps): ReactElement => {
  const { isOpen, children, ele, showClose, outClick, maxWidth } = props;
  const { onRequestClose, outerClickEvent } = useModalHook(ele);

  return (
    <AnimatePresence>
      {isOpen && (
        <Base.ModalLayout
          key={"fade-modal-key"}
          onClick={outClick ? outerClickEvent : () => {}}
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <F.ModalContainer>
            <F.ModalBody ref={ele} $showClose={showClose} $maxWidth={maxWidth}>
              {showClose && (
                <Base.ModalCloseButtonWrapper>
                  <button
                    type={"button"}
                    aria-label={"modal-close-button"}
                    onClick={onRequestClose}
                  >
                    <AiOutlineClose size={22} color={"#222"} />
                  </button>
                </Base.ModalCloseButtonWrapper>
              )}

              <Base.ModalContentsBox ref={ele}>
                {children}
              </Base.ModalContentsBox>
            </F.ModalBody>
          </F.ModalContainer>
        </Base.ModalLayout>
      )}
    </AnimatePresence>
  );
};
