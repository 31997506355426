import { Outlet, useOutletContext } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { projectSendCheckApi } from "@apis/project";
import { useCallback, useEffect } from "react";

export const ProjectStatusGuard = () => {
  const { id } = useOutletContext<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const checkProject = useCallback(async () => {
    const data = await projectSendCheckApi(id);
    if (pathname.includes("/edit") && pathname.includes("/targeting")) {
      const targetPath = data?.toCallApi ? "request" : "targeting";
      navigate({ pathname: `/edit/${id}/${targetPath}` });
    }
  }, [id, pathname]);

  useEffect(() => {
    checkProject();
  }, [checkProject]);

  return <Outlet context={{ id }} />;
};
