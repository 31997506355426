import styled from "styled-components";
import { motion } from "framer-motion";
import { INNER_WIDTH } from "@styles/styled-components";

export const SIDEBAR_WIDTH = 280;

export const SideNavBarLayout = styled(motion.aside)`
  width: ${SIDEBAR_WIDTH}px;
  min-width: ${SIDEBAR_WIDTH}px;
  max-width: ${SIDEBAR_WIDTH}px;
  min-height: 100vh;
  height: auto;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0 0 0 2px;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 1000;
`;

export const Overlay = styled(motion.div)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명한 검정색 배경 */
  z-index: 999; /* 사이드바 아래에 위치하도록 함 */

  @media screen and (max-width: ${INNER_WIDTH}px) {
    display: inline-block;
  }
`;
