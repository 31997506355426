import { ReactElement, useEffect, useState } from "react";
import classNames from "classnames";
import { TemplateType } from "@bizchat/api-interface";
import * as S from "@components/project/message/styles/EditorType.styled";

interface Props {
  onClickTemplateType?: (type: TemplateType) => void;
  templateType: TemplateType;
}

const editorTypeState: TemplateType[] = [TemplateType.MMS, TemplateType.RCS];

export const EditorType = (props: Props): ReactElement => {
  const { templateType } = props;

  const [currentTab, setCurrentTab] = useState<TemplateType>(
    templateType || TemplateType.MMS,
  );

  useEffect(() => {
    setCurrentTab(templateType);
  }, [templateType]);

  const active = (type: TemplateType) => {
    return currentTab === type;
  };

  const editorLabel = (type: TemplateType) => {
    return type === TemplateType.MMS ? "일반" : "RCS";
  };

  return (
    <S.GridBox>
      <S.TemplateTableContainer>
        {editorTypeState.map((type) => {
          return (
            <span
              className={classNames(active(type) && "active")}
              key={crypto.randomUUID()}
            >
              {editorLabel(type)}
            </span>
          );
        })}
      </S.TemplateTableContainer>
    </S.GridBox>
  );
};
