import { ReactElement } from "react";
import styled from "styled-components";
import { MAX_WIDTH } from "@styles/styled-components";
import { Button } from "@components/common/button";
import { useNavigate } from "react-router-dom";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";

const NotFoundPageLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const NotFoundPageContainer = styled.main`
  max-width: ${MAX_WIDTH}px;
  height: 100%;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

export const NotFoundPage = (): ReactElement => {
  const navigate = useNavigate();

  const handleClickRedirectHome = () => {
    navigate({ pathname: "/" }, { replace: true });
  };

  return (
    <NotFoundPageLayout>
      <NotFoundPageContainer>
        <FlexBox $gap={16}>
          <Typography $fontSize={24} $fontWeight={700}>
            찾을 수 없는 페이지입니다.
          </Typography>
          <Typography
            $fontSize={18}
            $fontWeight={600}
            $fontColor={"textBlack200"}
          >
            존재하지 않는 주소를 입력하셨거나
          </Typography>
          <Typography
            $fontWeight={600}
            $fontSize={18}
            $fontColor={"textBlack200"}
          >
            요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.
          </Typography>
        </FlexBox>

        <Button
          variant={"black000"}
          onClick={handleClickRedirectHome}
          type={"button"}
        >
          <Typography $fontColor={"textWhite"}>홈으로 돌아가기</Typography>
        </Button>
      </NotFoundPageContainer>
    </NotFoundPageLayout>
  );
};
