import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import React from "react";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import styled from "styled-components";

export interface CampaignUrlListProps {
  urlList: string[];
}

const UrlListLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;

  .input-wrapper {
    width: 100%;
  }
`;

export const UrlList = (props: CampaignUrlListProps) => {
  const { urlList } = props;

  return (
    <UrlListLayout>
      {urlList.map((url) => {
        return (
          <div className={"input-wrapper"} key={url}>
            <BasicInput readOnly={true} disabled={true} value={url} />
          </div>
        );
      })}
    </UrlListLayout>
  );
};
