import styled from "styled-components";

const size = 1;
const total = 100;

export const TableLayout = styled.div`
  width: auto;
  border-top: 1px solid ${({ theme }) => theme.colors.black000};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black000};

  table thead tr th {
    padding: 0.5em 0;
  }

  table tbody tr td {
    padding: 0.8em 0;
  }

  table tbody tr:not(.active):hover {
    cursor: pointer;
    background: #f6f6f6;
  }

  .active {
    background-color: ${({ theme }) => theme.colors.purple300};
  }

  ${Array.from(
    { length: total },
    (_, index) => `
    .w-${index + 1} {
      width: ${(index + 1) * size}% !important;
    }
  `,
  ).join("\n")}
`;

export const TableContainer = styled.table`
  table-layout: fixed;
  margin-bottom: 0;
  border: none;
  width: 100%;
  background: white;

  .subject {
    color: #2e5eb0;
    font-weight: 500;
  }
`;

export const THead = styled.thead`
  tr {
    background-color: #eeeeee;

    th {
      &:not(:first-child):not(:last-child) {
        border-right: 1px solid #adafba;
        border-left: 1px solid #adafba;
      }
      padding: 0;
    }
  }

  @media screen and (max-width: 767px) {
    tr {
      .subject {
        width: 70%;
      }
    }
  }
`;

export const TBody = styled.tbody`
  background: white;

  tr {
    .subject {
      text-align: left;
    }

    td {
      text-align: center;
      width: auto;
      height: 2em;
      padding: 0.5em 0.75em;
      font-size: 1em;
      line-height: 1.2;
      box-sizing: border-box;
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
      overflow: visible;

      &:not(:first-child):not(:last-child) {
        border-right: 1px solid #adafba;
        border-left: 1px solid #adafba;
      }
    }
  }
`;
