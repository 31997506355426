import { ReactElement, useEffect, useMemo, useState } from "react";
import { CustomSelect } from "@components/common/select-box/styles/SelectBox.styled.ts";
import { StylesConfig } from "react-select";

export interface Option {
  name?: string;
  value: string | number;
  label: string;
}

interface Props {
  options: Option[];
  value?: string | number;
  onChange: (e: Option) => void;
  placeholder?: string;
  disabled?: boolean;
  isDefaultValue?: boolean;
}

export const SelectBox = (props: Props): ReactElement => {
  const {
    options,
    onChange,
    placeholder,
    value,
    disabled = false,
    isDefaultValue = true,
  } = props;
  const defaultState = isDefaultValue ? options[0] : null;
  const [defaultValue, setDefaultValue] = useState<Option | null>(defaultState);

  useEffect(() => {
    const newValue = options.filter((option) => option.value === value);
    if (newValue.length > 0) setDefaultValue(newValue[0]);
    else setDefaultValue(null);
  }, [value]);

  const customStyles: StylesConfig = useMemo(
    () => ({
      option: (provided, state: any) => ({
        ...provided,
        border: "1px border #ededed",
        color: state.isSelected ? "#fff" : state.data.color,
        backgroundColor: state.isSelected ? "#222" : "#fff",
        opacity: 0.8,
        padding: 10,
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#e8e8e8",
          color: "#858585",
        },
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      control: (provided, state: any) => ({
        ...provided,
        borderColor: state.isFocused ? "var(--primary)" : "#ededed",
        boxShadow: state.isFocused ? "var(--primary)" : "#ededed",
        caretColor: "transparent",
        "& svg": {
          fill: state.isFocused ? "var(--primary)" : "currentColor",
        },
        "&:hover": {
          cursor: "pointer",
        },
      }),
      singleValue: (provided, state: any) => ({
        ...provided,
        color: state.data.color,
      }),
      menuList: (provided) => ({
        ...provided,
        "&:hover": {
          cursor: "pointer",
        },
      }),
    }),
    [],
  );

  /** @description react-select의 이벤트는 action에 따라 달라지므로 타입을 명시하기 어려움*/
  const handleChangeSelect = (e: any) => {
    onChange && onChange(e);
  };

  return (
    <CustomSelect
      options={options}
      isDisabled={disabled}
      onChange={handleChangeSelect}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={defaultValue}
      styles={customStyles}
    />
  );
};
