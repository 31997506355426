import { CSSProperties, ReactElement } from "react";
import { MoonLoader } from "react-spinners";

const override: CSSProperties = {
  display: "inline-block",
};

type Props = {
  color?: string;
  isLoading?: boolean;
  size?: number;
};

const primary = "#8869EA";

export const Spinner = (props: Props): ReactElement => {
  const { isLoading = true, size = 30, color = primary } = props;

  return (
    <MoonLoader
      color={color}
      loading={isLoading}
      cssOverride={override}
      size={size}
      speedMultiplier={0.8}
      aria-label="Loading Spinner"
    />
  );
};
