import React, { ReactElement } from "react";
import { Typography } from "@components/common/Typography";
import * as S from "./styles/SendResult.styled.ts";
import { CampaignSendResultState } from "@bizchat/api-interface";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { IoIosSend } from "react-icons/io";
import { SendResultTitle } from "@components/campaign/SendResultTitle.tsx";
import { SendResultSummary } from "@components/campaign/SendResultSummary.tsx";
import { SendResultReactionTable } from "@components/campaign/SendResultReactionTable.tsx";

interface SendResultProps {
  sendResult: CampaignSendResultState | undefined | null;
  sendCount: number;
}

export const SendResult = ({
  sendResult,
  sendCount,
}: SendResultProps): ReactElement => {
  return (
    <S.SendResultLayout>
      <section>
        <SendResultTitle statDate={sendResult?.statDate || ""} />
        <SendResultSummary sendCount={sendCount} sendResult={sendResult} />
      </section>

      <SendResultReactionTable sendResult={sendResult} />
    </S.SendResultLayout>
  );
};
