import { MmsState } from "@bizchat/api-interface";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import React from "react";
import styled from "styled-components";
import { UrlList } from "@components/campaign/UrlList.tsx";

interface MmsTemplateProps {
  mms: MmsState;
}

export const TemplateTextareaLayout = styled.div`
  width: 100%;
  height: auto;
  font-size: 14px;

  textarea {
    all: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    min-height: 300px;
    background: white;
    border: 1px solid #ededed;
    resize: none;
    overflow: auto;
    box-sizing: border-box;
    padding: 0.6em;
    outline: none;
    color: black;
    white-space: pre-wrap;

    &::placeholder {
      color: #d3d3d3;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:disabled {
      border-color: ${({ theme }) => theme.colors.gray050};
      background-color: #ededed;
    }
  }
`;

export const MmsTemplate = ({ mms }: MmsTemplateProps) => {
  return (
    <>
      <ContentsLayout label={"제목"}>
        <BasicInput readOnly={true} disabled={true} value={mms.title} />
      </ContentsLayout>
      <ContentsLayout label={"내용"} $alignItems={"flex-start"}>
        <TemplateTextareaLayout>
          <textarea
            readOnly={true}
            disabled={true}
            value={mms.msg || ""}
          ></textarea>
        </TemplateTextareaLayout>
      </ContentsLayout>
      <ContentsLayout label={"URL 분석"}>
        {mms.urlLink.list && <UrlList urlList={mms.urlLink.list} />}
      </ContentsLayout>
    </>
  );
};
