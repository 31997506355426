import styled from "styled-components";

export const Layout = styled.div`
  display: grid;
  grid-template-columns: 4fr 0.5fr 1.5fr;
`;

export const StateLayout = styled.ul`
  border: 1px solid #e7e7e7;
  border-bottom: none;
  min-width: 110px;
  max-height: 320px;
  overflow-y: auto;

  li {
    //cursor: pointer;
    padding: 0.4rem;
    border-bottom: 1px solid #e7e7e7;
  }

  .current {
    background-color: #b9ccff;
  }
`;

export const CheckboxLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  max-height: 300px;
  width: fit-content;
  overflow-y: auto;
  padding: 10px;
  white-space: nowrap;

  input[type="checkbox"],
  label {
    cursor: pointer;
  }
`;
