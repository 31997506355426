import { useNavigate } from "react-router-dom";
import { Toast } from "@components/common/toast/Toast.tsx";
import { Button } from "@components/common/button";
import { Typography } from "@components/common/Typography";
import { IoAddOutline } from "react-icons/io5";
import { TbFolderX } from "react-icons/tb";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import classNames from "classnames";

interface ListOptionsGroupProps {
  deletedListIds: string[];
  onClickRemoveProject: () => void;
}

export const ListOptionGroup = ({
  deletedListIds,
  onClickRemoveProject,
}: ListOptionsGroupProps) => {
  const navigate = useNavigate();

  const handleClickDelete = async () => {
    if (!deletedListIds.length) {
      Toast.error("삭제할 프로젝트를 선택해주세요.");
      return;
    } else {
      onClickRemoveProject();
    }
  };

  return (
    <FlexBox
      $flexDirection={"row"}
      $justifyContent={"space-between"}
      className={classNames("mt-16", "mb-39")}
    >
      <Button
        disabled={deletedListIds.length === 0}
        type={"button"}
        onClick={handleClickDelete}
        variant={"gray000"}
      >
        <TbFolderX
          color={deletedListIds.length === 0 ? "#fff" : "#5D5D5D"}
          size={22}
        />
        <Typography as={"span"} $fontColor={"textWhite"}>
          선택삭제
        </Typography>
      </Button>
      <Button
        type={"button"}
        onClick={() => {
          navigate("/project/registration");
        }}
      >
        <IoAddOutline color={"#fff"} size={22} />
        <Typography as={"span"} $fontColor={"textWhite"}>
          등록하기
        </Typography>
      </Button>
    </FlexBox>
  );
};
