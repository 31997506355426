import { ReactElement, useState } from "react";
import CustomCalendar from "@components/common/datepicker/CustomCalendar.tsx";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import { Button } from "@components/common/button";
import * as S from "@components/project/list/styles/ListDownloadGroup.styled.ts";
import { Toast } from "@components/common/toast/Toast.tsx";
import { excelDownloadApi } from "@apis/download";
import { dateToString } from "@utils/date.ts";

export const ListDownloadGroup = (): ReactElement => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleClickDownload = async () => {
    try {
      const start = dateToString(startDate);
      const end = dateToString(endDate);
      await excelDownloadApi({ startDate: start, endDate: end });
    } catch (e) {
      Toast.error("다운로드에 실패하였습니다.");
    }
  };

  return (
    <S.DownloadGroupLayout>
      <FlexBox $flexDirection={"row"} $justifyContent={"flex-start"} $gap={10}>
        <CustomCalendar
          disabled={true}
          selectedDate={startDate}
          setSelectedDate={setStartDate}
        />
        <Typography as={"span"} $fontWeight={700}>
          ~
        </Typography>
        <CustomCalendar
          disabled={true}
          selectedDate={endDate}
          setSelectedDate={setEndDate}
        />
        <Button
          disabled={true}
          variant={"black000"}
          type={"button"}
          onClick={handleClickDownload}
        >
          <Typography as={"span"} $fontColor={"textWhite"}>
            다운로드
          </Typography>
        </Button>
      </FlexBox>
    </S.DownloadGroupLayout>
  );
};
