import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { SendTestCampaignBody, TestCampaignInfo } from "@bizchat/api-interface";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  AxiosResponseData,
  axiosResponseData,
} from "@libs/axios/axiosResponse.ts";

const VERSION = "v1";
const DOMAIN = `/${VERSION}/campaign`;

const COMMON_DOMAIN = `/${VERSION}/common`;

/**
 * @description 비즈챗 파일 업로드
 * @param file
 * @param rcsType
 */
export const templateImageUploadApi = async (
  file: FormData,
  rcsType?: number,
) => {
  let url = `${COMMON_DOMAIN}/file`;
  if (rcsType && rcsType === 1) url += `?rcs=${rcsType}`;
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const { data } = await axiosInstance.post(url, file, {
    headers,
  });
  return data;
};

/**
 * @description 테스트 환경에서만 사용 가능
 * Mdn csv 파일 업로드
 * @param file
 */
export const mdnFileUploadApi = async (file: FormData) => {
  // csv 파일의 경우 type은 4
  let url = `${COMMON_DOMAIN}/file?type=4`;
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const { data } = await axiosInstance.post(url, file, {
    headers,
  });
  return data;
};

export const testSendApi = async (
  campaignId: string,
  body: SendTestCampaignBody,
) => {
  const url = `${DOMAIN}/test?campaignId=${campaignId}`;
  const { data } = await axiosInstance.post(url, body);
  return data;
};

/**
 * @description 테스트 발송 상태 조회
 * @param campaignId
 */
export const testListStatusApi = async (
  campaignId: string,
): Promise<TestCampaignInfo[] | null> => {
  const url = `${DOMAIN}/test?campaignId=${campaignId}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<TestCampaignInfo[]>>
  >(url);
  return axiosResponseData<TestCampaignInfo[]>(data);
};
