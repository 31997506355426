import styled from "styled-components";

export const SelectedLocationLayout = styled.div`
  border: 1px solid #e7e7e7;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 100px;
  max-height: 300px;
  overflow: auto;

  .title-container {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;
  }

  .selected-location {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 10px;
    padding: 10px 20px;

    .badge {
      background-color: #e7e7e7;
      text-align: center;
      border-radius: 10px;
      padding: 0.2rem 0;
    }
  }
`;
