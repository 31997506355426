import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import React, { Dispatch, SetStateAction } from "react";
import styles from "./styles/Calendar.module.scss";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const YEARS = Array.from(
  { length: getYear(new Date()) + 1 - 2000 },
  (_, i) => getYear(new Date()) - i,
);
const MONTHS = [
  "1월",
  "2월",
  "3월",
  "4월",
  "5월",
  "6월",
  "7월",
  "8월",
  "9월",
  "10월",
  "11월",
  "12월",
];

interface Props {
  selectedDate: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  disabled?: boolean;
}

const CustomCalendar: React.FC<Props> = ({
  selectedDate,
  setSelectedDate,
  disabled = false,
}) => {
  return (
    <DatePicker
      disabled={disabled || false}
      dateFormat="yyyy.MM.dd" // 날짜 형태
      formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
      showYearDropdown
      scrollableYearDropdown
      yearDropdownItemNumber={100}
      shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
      minDate={new Date("2000-01-01")} // minDate 이전 날짜 선택 불가
      maxDate={new Date()} // maxDate 이후 날짜 선택 불가
      selected={selectedDate || new Date()}
      onChange={(date: Date) => setSelectedDate(date)}
      className={styles.datePicker}
      calendarClassName={styles.calenderWrapper}
      dayClassName={(_) => styles.unselectedDay}
      renderCustomHeader={({
        date,
        changeYear,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className={styles.customHeaderContainer}>
          <div>
            <span className={styles.month}>{MONTHS[getMonth(date)]}</span>
            <select
              value={getYear(date)}
              className={styles.year}
              onChange={({ target: { value } }) => changeYear(+value)}
            >
              {YEARS.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div>
            <button
              type="button"
              onClick={decreaseMonth}
              className={styles.monthButton}
              disabled={prevMonthButtonDisabled}
            >
              <FaArrowLeft color={"#fff"} />
            </button>
            <button
              type="button"
              onClick={increaseMonth}
              className={styles.monthButton}
              disabled={nextMonthButtonDisabled}
            >
              <FaArrowRight color={"#fff"} />
            </button>
          </div>
        </div>
      )}
    />
  );
};

export default CustomCalendar;
