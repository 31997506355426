import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import classNames from "classnames";
import { CreateCampaignBody, GenerateStatus } from "@bizchat/api-interface";
import * as S from "@components/project/views/styles/RequestView.styled.ts";
import { BounceSpinner } from "@components/common/spinner/BounceLoader.tsx";
import { Typography } from "@components/common/Typography";
import { Button } from "@components/common/button";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Tooltip } from "react-tooltip";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { BellIcon } from "@components/project/icons/BellIcon.tsx";
import { RequestAtsList } from "@components/project/views/RequestView.tsx";
import { TbLocation } from "react-icons/tb";
import { FaAngleRight, FaFilter } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import { parseStringToNumber } from "@components/project/utils";
import {
  setCheckedRequestList,
  setRequestList,
  setRequestShownHistoryCampaign,
  setShownHistoryCampaign,
} from "@store/slices/requestSlice.ts";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import { RequestCheckBox } from "@components/project/sampling/request/RequestListTable/RequestListBody/RequestCheckBox.tsx";
import { Toast } from "@components/common/toast/Toast.tsx";
import moment from "moment";
import { createCampaignApi } from "@apis/campaign";
import { useOutletContext } from "react-router";
import { atsReGenerateApi } from "@apis/ats";
import { RootState, store } from "@store/index.ts";
import { AxiosError } from "axios";

export interface RequestListMainRowProps {
  index: number;
  request: RequestAtsList;
  handleCreateCampaignSingle: (state: number) => void;
  onClickAtsUpdate: (index?: number) => void;
}

// 날짜 꺼내기
const selectRequestDate = (state: RootState) => state.requestStore.requestDate;

export const RequestListMainRow = React.memo(
  ({
    index,
    request,
    onClickAtsUpdate,
    handleCreateCampaignSingle,
  }: RequestListMainRowProps): ReactElement => {
    const { id: projectId } = useOutletContext<{ id: string }>();
    const dispatch = useAppDispatch();
    const shownHistoryCampaign = useAppSelector(
      (state) => state.requestStore.shownHistoryCampaign,
    );
    const mdnUpload = useAppSelector(
      (state) => state.campaignStore.mdnUploader,
    );
    const mdnFileId = useAppSelector((state) => state.campaignStore.mdnFileId);

    const clickMultiply = useAppSelector(
      (state) => state.requestStore.clickMultiply,
    );
    const {
      _id,
      filterQuery,
      mosuCount,
      status,
      atsGenerate,
      label: {
        gender,
        age,
        location: { label, state, city, town, range },
      },
      historyList,
      sampleCount,
      reject,
    } = request;
    const [requestCount, setRequestCount] = useState(0);
    const [errorCampaign, setErrorCampaign] = useState(-1);

    const handleClickShownHistory = (_id: string) => {
      dispatch(setShownHistoryCampaign(_id));
    };

    const validateRequestCount = (
      value: string | number,
      errorMsg: boolean,
    ) => {
      setErrorCampaign(-1);

      const parsedRequestCount =
        typeof value === "string" ? parseStringToNumber(value) : value;

      const overMax = 20000 < parsedRequestCount;
      if (errorMsg && overMax) setErrorCampaign(1);
      const requestCount = overMax ? 20000 : parsedRequestCount;

      const overMosuCount = mosuCount < requestCount;
      if (errorMsg && overMosuCount) setErrorCampaign(2);
      return overMosuCount ? mosuCount : requestCount;
    };

    const handleChangeRequestCount = (e: ChangeEvent<HTMLInputElement>) => {
      const { target } = e;
      const count = validateRequestCount(target.value, true);
      setRequestCount(count);
      dispatch(setRequestList({ _id, requestCount: count }));
      dispatch(setCheckedRequestList({ _id, requestCount: count }));
    };

    const handleClickAtsReGenerate = async () => {
      try {
        const result = await atsReGenerateApi(_id);
        if (result) Toast.info("선택한 ATS를 다시 조회 합니다.");
        onClickAtsUpdate(index);
      } catch (e) {
        Toast.error("ATS 조회에 실패하였습니다.");
      }
    };

    const handleClickCreateCampaignSingle = async (): Promise<void> => {
      try {
        if (mdnUpload && mdnFileId.length > 0) {
          const mdnConfirm = confirm(
            "업로드 된 mdn 파일이 있습니다. 업로드된 MDN 번호로 캠페인 발송하시겠습니까?",
          );
          if (!mdnConfirm) {
            Toast.info(
              "캠페인 발송을 취소하였습니다. MDN 파일을 지우고 캠페인을 생성해 주세요.",
            );
            return;
          }
          await createCampaign(index);
        } else {
          await createCampaign(index);
        }
      } catch (e: any) {
        if (e instanceof AxiosError) {
          if (e.response) {
            Toast.error(
              `캠페인 생성 실패 - ${e.response.data.message || e.response.status}`,
            );
          } else if (e.request) {
            Toast.error("캠페인 생성 실패 - 서버로부터 응답이 없습니다.");
          } else {
            Toast.error(`캠페인 생성에 실패하였습니다: ${e.message}`);
          }
        } else if (e instanceof Error) {
          Toast.error(`캠페인 생성에 실패하였습니다: ${e.message}`);
        } else {
          Toast.error(`캠페인 생성 중 알 수 없는 오류가 발생했습니다. - ${e}`);
        }
      }
    };

    const createCampaign = async (index: number): Promise<void> => {
      if (request.status === GenerateStatus.CREATING) {
        Toast.error("발송 요청 중입니다.");
        return;
      }

      const requestDate = selectRequestDate(store.getState());
      const dateToString = moment(new Date(requestDate)).format(
        "YYYY-MM-DD, HH:mm:ss",
      );

      const body: CreateCampaignBody[] = [
        {
          atsId: _id,
          sendDate: dateToString,
          sendCount: requestCount,
          mdnFileId,
        },
      ];

      const result = await createCampaignApi(projectId, body);
      if (result) {
        handleCreateCampaignSingle(index);
        dispatch(setRequestShownHistoryCampaign(_id));
        dispatch(setCheckedRequestList({ _id, requestCount: 0 }));
      }
    };

    useEffect(() => {
      if (
        clickMultiply &&
        sampleCount &&
        (status === GenerateStatus.READY || status === GenerateStatus.FAILED)
      ) {
        const count = validateRequestCount(
          requestCount + sampleCount * 10,
          false,
        );
        setRequestCount(count);
      }
    }, [clickMultiply]);

    useEffect(() => {
      setRequestCount(0);
    }, [request]);

    return (
      <div
        className={classNames("tr", {
          "exist-history": historyList.length > 0,
          "current-item": shownHistoryCampaign === _id,
          reject: reject,
        })}
      >
        {(status === GenerateStatus.CREATING ||
          atsGenerate === GenerateStatus.CREATING) && (
          <S.AtsSpinnerBox>
            <BounceSpinner size={20} />
          </S.AtsSpinnerBox>
        )}
        {atsGenerate === GenerateStatus.FAILED && (
          <S.AtsSpinnerBox>
            <Typography $fontSize={14}>ATS 조회에 실패하였습니다.</Typography>
            <Button onClick={() => handleClickAtsReGenerate()}>
              <TbLocation size={16} color={"#fff"} />
              <Typography as={"span"}>발송 모수 재조회</Typography>
            </Button>
          </S.AtsSpinnerBox>
        )}
        <div className={"td"}>
          <RequestCheckBox _id={_id} requestCount={requestCount} />
        </div>
        <div className={"td"} onClick={() => handleClickShownHistory(_id)}>
          <FlexBox
            $flexDirection={"row"}
            $justifyContent={"space-between"}
            $alignItems={"flex-start"}
          >
            <ul>
              <li className={classNames("pt-1", "pb-1")}>
                <Typography as={"span"} $fontSize={16}>
                  {gender}
                </Typography>
              </li>
              <li className={classNames("pt-1", "pb-1")}>
                <Typography as={"span"} $fontSize={16}>
                  {age}
                </Typography>
              </li>
              <li
                className={classNames("location", "pt-1", "pb-1")}
                data-tooltip-id={`loc-${_id}`}
              >
                <Typography as={"span"} $fontSize={16}>
                  지역
                  {range === 1
                    ? "(시/도)"
                    : range === 2
                      ? "(시/구/군)"
                      : "(읍/면/동)"}
                  : {state || city || town}
                </Typography>
              </li>
              <Tooltip id={`loc-${_id}`} place="top" style={{ width: 300 }}>
                {label}
              </Tooltip>
            </ul>
            {filterQuery.length > 0 && <FaFilter color={"#8869EA"} />}
            <FaAngleRight
              className={classNames("history-arrow", {
                "history-arrow-open": shownHistoryCampaign === _id,
              })}
              color={"#2E5EB0"}
              size={20}
            />
          </FlexBox>
        </div>
        <div className={"td"}>
          <Typography as={"span"} $fontSize={16}>
            {sampleCount.toLocaleString()}
          </Typography>
        </div>
        <div className={"td"}>
          <Typography as={"span"} $fontSize={16}>
            {mosuCount.toLocaleString()}
          </Typography>
        </div>
        <div className={"td"}>
          <FlexBox>
            <BasicInput
              disabled={mosuCount === 0}
              value={requestCount.toLocaleString()}
              onChange={handleChangeRequestCount}
            />
            <Typography as={"span"} $fontColor={"textRed000"} $fontSize={12}>
              {errorCampaign === 1 && "최대 20,000 까지 가능합니다."}
              {errorCampaign === 2 && "발송 가능수보다 클 수 없습니다."}
            </Typography>
          </FlexBox>
        </div>
        <div className={"td"}>
          <Button
            style={{ color: "#fff" }}
            disabled={requestCount < 1}
            onClick={handleClickCreateCampaignSingle}
          >
            <FlexBox
              $flexDirection={"row"}
              $justifyContent={"center"}
              $gap={20}
            >
              <Typography as={"span"} $fontSize={14}>
                발송
              </Typography>
              <FaArrowCircleRight size={18} />
            </FlexBox>
          </Button>
        </div>
        <div className={"td"}>
          <Button
            variant={"second"}
            disabled={requestCount < 1 || historyList.length < 1}
          >
            <FlexBox $flexDirection={"row"} $gap={20}>
              <Typography as={"span"} $fontColor={"textWhite"} $fontSize={16}>
                리마인더 발송
              </Typography>
              <BellIcon
                color={"#CB03CD"}
                disabled={requestCount < 1 || historyList.length < 1}
              />
            </FlexBox>
          </Button>
        </div>
      </div>
    );
  },
  (prevProps: any, nextProps: any) => {
    return (
      prevProps.index === nextProps.index &&
      prevProps.request === nextProps.request &&
      prevProps.onClickAtsUpdate === nextProps.onClickAtsUpdate &&
      prevProps.handleCreateCampaignSingle ===
        nextProps.handleCreateCampaignSingle &&
      prevProps.requestDate === nextProps.requestDate
    );
  },
);
