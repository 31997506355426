import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, {
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
} from "react";
import { ko } from "date-fns/locale";
import styles from "./styles/Calendar.module.scss";

interface Props {
  selectedDate: Date | null | undefined;
  maxDate?: Date | null | undefined;
  minDate?: Date | null | undefined;
  minTime?: Date;
  maxTime?: Date;
  showTimeSelect?: boolean | undefined;
  setSelectedDate: Dispatch<SetStateAction<Date>> | ((date: Date) => void);
  dateFormat?: string;
  disabled?: boolean;
}

const Calendar: React.FC<Props> = ({
  selectedDate,
  setSelectedDate,
  showTimeSelect,
  minDate,
  maxDate,
  minTime,
  maxTime,
  dateFormat,
  disabled = false,
}) => {
  const ExampleCustomInput = forwardRef(
    ({ value, onClick }: any, ref: ForwardedRef<HTMLButtonElement>) => (
      <button
        disabled={disabled}
        type={"button"}
        style={{
          border: "#ccc 1px solid",
          padding: "0.5rem 1rem",
          backgroundColor: `${disabled ? "#ededed" : "#ffffff"}`,
        }}
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
    )
  );

  return (
    <DatePicker
      dateFormat={dateFormat} // 날짜 형태
      showYearDropdown
      showTimeSelect={showTimeSelect}
      timeIntervals={10}
      scrollableYearDropdown
      yearDropdownItemNumber={100}
      shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
      minDate={minDate || new Date("2000-01-01")} // minDate 이전 날짜 선택 불가
      maxDate={maxDate} // maxDate 이후 날짜 선택 불가
      minTime={minTime}
      maxTime={maxTime}
      selected={selectedDate}
      className={styles.datePicker}
      calendarClassName={styles.calenderWrapper}
      dayClassName={(_) => styles.unselectedDay}
      locale={ko}
      disabled={disabled}
      fixedHeight
      onChange={(date: Date) => setSelectedDate(date)}
      customInput={<ExampleCustomInput />}
    />
  );
};

export default Calendar;
