import {
  RcsTemplateType,
  TemplateType,
  UpdateTemplateState,
  UrlAnalyzeType,
} from "@bizchat/api-interface";

export const projectMessageTemplateState: UpdateTemplateState = {
  type: TemplateType.MMS,
  imageFiles: [],
  mdnFileId: "",
  billingType: 0,
  rcsType: RcsTemplateType.STANDARD,
  mms: {
    title: "",
    msg: "",
    fileInfo: {
      list: [],
    },
    urlLink: {
      list: [
        {
          type: UrlAnalyzeType.URL,
          value: "",
        },
      ],
    },
  },
  rcs: {
    title: "",
    msg: "",
    slideNum: 1,
    imgOrigId: "",
    urlLink: {
      list: [
        {
          type: UrlAnalyzeType.URL,
          value: "",
        },
      ],
    },
    buttons: {
      list: [],
    },
    opts: {
      list: [],
    },
  },
};
