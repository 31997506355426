import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TeamsSignInButton } from "@components/teams/SignInButton.tsx";
import { useAuth } from "@providers/AuthProvider.tsx";
import * as S from "./styles/SignInPage.styled.ts";
import { SignInLogo } from "@components/common/logo/SignInLogo.tsx";
import { SignInTitle } from "@components/common/logo/SignInTitle.tsx";

export const LightBall1 = () => {
  return (
    <S.LightBallLayout1>
      <div className={"area"} />
    </S.LightBallLayout1>
  );
};

export const LightBall2 = () => {
  return (
    <S.LightBallLayout2>
      <div className={"area"} />
    </S.LightBallLayout2>
  );
};

export const LightBall3 = () => {
  return (
    <S.LightBallLayout3>
      <div className={"area"} />
    </S.LightBallLayout3>
  );
};

export const SignInPage = (): ReactElement => {
  const { getToken } = useAuth();
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) navigate("/", { replace: true });
  }, [token]);

  return (
    <S.SignInPageLayout>
      <LightBall1 />
      <LightBall3 />
      <LightBall2 />
      <S.SignInPageContainer>
        <SignInLogo className={"logo"} />
        <div className={"sign-in-body"}>
          <SignInLogo
            className={"responsive-logo"}
            color={"black"}
            width={300}
          />

          <div className={"sign-in-button-wrapper"}>
            <div className={"sign-in-button-group"}>
              <SignInTitle />
              <TeamsSignInButton />
            </div>
          </div>
        </div>
      </S.SignInPageContainer>
    </S.SignInPageLayout>
  );
};
