import { ReactElement, useEffect, useMemo, useState } from "react";
import { CustomSelect } from "@components/common/select-box/styles/CampaignStatusSelectBox.styled.ts";
import { StylesConfig } from "react-select";

export interface Option {
  name?: string;
  value: string | number;
  label: string;
}

interface Props {
  options: Option[];
  value?: string | number;
  onChange: (e: Option) => void;
  placeholder?: string;
  disabled?: boolean;
  isDefaultValue?: boolean;
}

export const CampaignStatusSelectBox = (props: Props): ReactElement => {
  const {
    options,
    onChange,
    placeholder,
    value,
    disabled = false,
    isDefaultValue = true,
  } = props;
  const defaultState = isDefaultValue ? options[0] : null;
  const [defaultValue, setDefaultValue] = useState<Option | null>(defaultState);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (menuIsOpen) {
        setMenuIsOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [menuIsOpen]);

  useEffect(() => {
    const newValue = options.filter((option) => option.value === value);
    if (newValue.length > 0) setDefaultValue(newValue[0]);
    else setDefaultValue(null);
  }, [value]);

  const customStyles: StylesConfig = useMemo(
    () => ({
      option: (provided, state: any) => ({
        ...provided,
        border: "1px border transparent",
        color: state.isSelected ? "#fff" : state.data.color,
        backgroundColor: state.isSelected ? "#222" : "#fff",
        opacity: 0.8,
        padding: 10,
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#e8e8e8",
          color: "#858585",
        },
      }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      control: (provided, state: any) => ({
        ...provided,
        borderColor: state.isFocused ? "transparent" : "transparent",
        boxShadow: state.isFocused ? "transparent" : "transparent",
        backgroundColor: "var(--purple000)",
        color: "#fff",
        caretColor: "transparent",
        borderRadius: 6,
        height: 28,
        "& svg": {
          fill: state.isFocused ? "#fff" : "currentColor",
        },
        "&:hover": {
          cursor: "pointer",
        },
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#fff",
      }),
      singleValue: (provided, state: any) => ({
        ...provided,
        color: "#fff",
      }),
      menuList: (provided) => ({
        ...provided,
        "&:hover": {
          cursor: "pointer",
        },
      }),
    }),
    [],
  );

  /** @description react-select의 이벤트는 action에 따라 달라지므로 타입을 명시하기 어려움*/
  const handleChangeSelect = (e: any) => {
    onChange && onChange(e);
  };

  return (
    <CustomSelect
      options={options}
      isDisabled={disabled}
      onChange={handleChangeSelect}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={defaultValue}
      menuPortalTarget={document.body}
      styles={customStyles}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
    />
  );
};
