import { HTMLAttributes, PropsWithChildren } from "react";
import styled from "styled-components";

interface Props extends PropsWithChildren, HTMLAttributes<HTMLElement> {}

const CardLayout = styled.article`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white000};
  border: 1px solid #e7e7e7;
  border-radius: 2px;

  width: 100%;
  overflow-x: auto;
`;

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
`;

export const Card = (props: Props) => {
  const { children, ...rest } = props;
  return (
    <CardLayout {...rest}>
      <CardContainer>{children}</CardContainer>
    </CardLayout>
  );
};
