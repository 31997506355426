import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import React, { ReactElement, useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "@components/common/button";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import {
  ModalCompType,
  ModalState,
  setModalOpen,
} from "@store/slices/modalSlice.ts";
import { Campaign } from "@components/campaign/Campaign.tsx";
import {
  useCampaignDetailQuery,
  useCampaignResult,
} from "@services/queries/hooks/useCampaign.ts";
import { CampaignStatus } from "@components/campaign/CampaignStatus.tsx";
import { SpinnerBox } from "@components/common/spinner/SpinnerBox.tsx";
import { AtsFilterOption } from "@components/campaign/AtsFilterOption.tsx";
import { CampaignDetailsTab } from "@components/campaign/CampaignDetailTab.tsx";
import { SendResult } from "@components/campaign/SendResult.tsx";
import { CampaignDetailModalLayout } from "@components/campaign/modal/styles/CampaignDetailModal.styled.ts";
import { campaignCancelApi } from "@apis/campaign";
import { CampaignStateType } from "@bizchat/api-interface";
import { Toast } from "@components/common/toast/Toast.tsx";

interface CampaignDetailModalProps {
  targetProject: string;
}

export const Line = styled.hr`
  border: 0;
  height: 1px;
  background: ${({ theme }) => theme.colors.borderColor2};
  margin: 24px 0;
`;

export enum CampaignTab {
  CAMPAIGN = "campaign", // 캠페인
  SEND = "send", // 발송 결과
}

export const CampaignDetailModal = (
  props: CampaignDetailModalProps,
): ReactElement => {
  const campaignId = useAppSelector((state) => state.campaignStore.campaignId);
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState<CampaignTab>(CampaignTab.CAMPAIGN);
  const campaignQueryData = useCampaignDetailQuery(campaignId);
  const sendResultQueryData = useCampaignResult(
    campaignId,
    props.targetProject,
  );
  const onClickTab = useCallback((tabType: CampaignTab) => setTab(tabType), []);

  const handleClickCampaignModal = useCallback(() => {
    dispatch(
      setModalOpen({
        type: ModalCompType.CAMPAIGN_DETAIL,
        modalType: "fade",
        isOpen: false,
      } as ModalState),
    );
  }, [dispatch]);

  if (campaignQueryData.isLoading && sendResultQueryData.isLoading)
    return <SpinnerBox />;

  const campaignDetails = campaignQueryData?.data || null;

  const handleClickCampaignCancel = async () => {
    const confirmed = confirm("캠페인을 취소하시겠습니까?");

    if (!confirmed) {
      handleClickCampaignModal();
      return;
    }

    const sendTimestamp = campaignQueryData.data?.campaign.atsSndStartDate || 0;
    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (sendTimestamp - currentTimestamp <= 3600) {
      Toast.error(
        "발송 시간이 현재 시간보다 1시간 이하일 경우 캠페인을 취소할 수 없습니다.",
      );
      handleClickCampaignModal();
      return;
    }

    await campaignCancelApi(campaignId);
    handleClickCampaignModal();
  };

  return (
    <CampaignDetailModalLayout>
      <CampaignDetailsTab currentTab={tab} onClickTab={onClickTab} />
      <article className={"campaign-details-form"}>
        <CampaignStatus
          status={campaignDetails?.campaign?.status || 10}
          messages={campaignDetails?.campaign.rejectMessage || ""}
        />

        {tab === CampaignTab.CAMPAIGN && (
          <div className={"campaign-details-container"}>
            {/*<ProjectSummary project={campaignDetails?.project} />*/}

            <Campaign
              campaign={campaignDetails?.campaign}
              campaignId={campaignId}
            >
              <AtsFilterOption ats={campaignDetails?.ats} />
            </Campaign>
          </div>
        )}

        {tab === CampaignTab.SEND && (
          <SendResult
            sendCount={campaignDetails?.campaign.sndGoalCnt || 0}
            sendResult={sendResultQueryData?.data}
          />
        )}
      </article>

      {!!campaignDetails && (
        <FlexBox $flexDirection={"row"} $justifyContent={"flex-end"} $gap={10}>
          {campaignDetails?.campaign.status !== CampaignStateType.CANCEL &&
            campaignDetails?.campaign.status !==
              CampaignStateType.SELF_TERMINATE && (
              <Button variant={"red000"} onClick={handleClickCampaignCancel}>
                <Typography as={"span"} $fontColor={"textGray100"}>
                  캠페인 취소
                </Typography>
              </Button>
            )}

          <Button variant={"gray100"} onClick={handleClickCampaignModal}>
            <Typography as={"span"} $fontColor={"textGray100"}>
              닫기
            </Typography>
          </Button>
        </FlexBox>
      )}
    </CampaignDetailModalLayout>
  );
};
