import React, { ReactElement, useEffect, useState } from "react";
import * as S from "@components/project/views/styles/RequestView.styled.ts";
import { RequestFormProjectInfo } from "@bizchat/api-interface";
import { Typography } from "@components/common/Typography";
import { Spinner } from "@components/common/spinner/Spinner.tsx";
import { RequestAtsList } from "@components/project/views/RequestView.tsx";
import { Option } from "@components/common/select-box/SelectBox.tsx";
import { RequestListHeader } from "@components/project/sampling/request/RequestListTable/RequestListHeader/RequestListHeader.tsx";
import { RequestListFooter } from "@components/project/sampling/request/RequestListTable/RequestListFooter/RequestListFooter.tsx";
import { RequestListBody } from "@components/project/sampling/request/RequestListTable/RequestListBody/RequestListBody.tsx";
import { useAppSelector } from "@hooks/useRedux.ts";
import { ModalContainer } from "@containers/ModalContainer.tsx";
import { TestSendModal } from "@components/project/modal/TestSendModal.tsx";
import { CampaignDetailModal } from "@components/campaign/modal/CampaignDetailModal.tsx";
import { ModalCompType } from "@store/slices/modalSlice.ts";
import { useOutletContext } from "react-router";

interface RequestListProps {
  projectInfo: RequestFormProjectInfo;
  filterCodeList: string[];
  requestListState: RequestAtsList[];
  onClickAtsUpdate: (index?: number) => void;
  handleCreateCampaignSingle: (state: number) => void;
  handleCreateCampaignMultiple: (state: string[]) => void;
  handleChangeCampaignStatus: (
    v: Option,
    atsId: string,
    campaignId: string,
  ) => void;
  refetch: () => void;
}

export const RequestListTable = ({
  projectInfo,
  filterCodeList,
  requestListState,
  onClickAtsUpdate,
  handleCreateCampaignSingle,
  handleCreateCampaignMultiple,
  handleChangeCampaignStatus,
  refetch,
}: RequestListProps): ReactElement => {
  const [pending, setPending] = useState(false);
  useEffect(() => {
    if (requestListState.length > 0) setPending(true);
    else setTimeout(() => setPending(true), 200);
  }, [requestListState]);

  return (
    <S.TableContainer>
      <RequestListHeader
        projectInfo={projectInfo}
        requestListState={requestListState}
      />
      {!pending && (
        <S.RequestFormMessageBox>
          <Spinner />
        </S.RequestFormMessageBox>
      )}
      {requestListState.length === 0 && pending && (
        <S.RequestFormMessageBox>
          <Typography $fontWeight={600} $fontColor={"textBlack100"}>
            샘플링 정보가 존재하지 않습니다. 샘플링 데이터를 생성해 주세요.
          </Typography>
        </S.RequestFormMessageBox>
      )}
      <RequestListBody
        requestListState={requestListState}
        filterCodeList={filterCodeList}
        onClickAtsUpdate={onClickAtsUpdate}
        handleCreateCampaignSingle={handleCreateCampaignSingle}
        handleChangeCampaignStatus={handleChangeCampaignStatus}
        refetch={refetch}
      />
      <RequestListFooter
        requestListState={requestListState}
        handleCreateCampaignMultiple={handleCreateCampaignMultiple}
      />
      <CampaignModal />
    </S.TableContainer>
  );
};

const CampaignModal = () => {
  const modalType = useAppSelector((state) => state.modalStore.modal.type);
  const { id } = useOutletContext<{ id: string }>();

  return (
    <ModalContainer maxWidth={850} outClick={false}>
      {modalType === ModalCompType.CAMPAIGN_DETAIL && (
        <CampaignDetailModal targetProject={id} />
      )}
      {modalType === ModalCompType.SEND_TEST_CAMPAIGN && <TestSendModal />}
    </ModalContainer>
  );
};
