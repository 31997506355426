import styled from "styled-components";

export const RcsButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  border: 1px solid #ededed;
  padding: 1em;

  label {
    width: 100%;
  }
`;
