import React, { ReactElement } from "react";
import classNames from "classnames";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";

export const RequestListEmptyRow = (): ReactElement => {
  return (
    <div className={classNames("tr", "empty-history-tr")}>
      <div className={"td"} />
      <div className={"td"}>
        <Typography as={"span"} $fontWeight={600}>
          발송 내역이 없습니다.
        </Typography>
      </div>
    </div>
  );
};
