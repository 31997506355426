import { RcsState, RcsTemplateType } from "@bizchat/api-interface";
import React, { Fragment, ReactElement } from "react";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { TemplateTextareaLayout } from "@components/campaign/MmsTemplate.tsx";
import { UrlList } from "@components/campaign/UrlList.tsx";

interface RcsTemplateProps {
  rcs: RcsState[];
  rcsType: RcsTemplateType;
}

export const RcsTemplate = ({
  rcs,
  rcsType,
}: RcsTemplateProps): ReactElement => {
  const rcsTypeText = (rcsType: number): string => {
    switch (rcsType) {
      case 0:
        return "스탠다드";
      case 1:
        return "LMS";
      case 2:
        return "슬라이드";
      case 3:
        return "이미지 강조 A(3:4)";
      case 4:
        return "이미지 강조 B(1:1)";
      case 5:
        return "상품 소개 세로";
      default:
        return "";
    }
  };

  return (
    <div>
      <ContentsLayout label={"RCS 템플릿"}>
        <BasicInput
          readOnly={true}
          disabled={true}
          value={rcsTypeText(rcsType || 0)}
        />
      </ContentsLayout>

      {rcs.map((rcs, index) => {
        return (
          <Fragment key={`rcs-${index}`}>
            <ContentsLayout label={"제목"}>
              <BasicInput readOnly={true} disabled={true} value={rcs.title} />
            </ContentsLayout>
            <ContentsLayout label={"내용"}>
              <TemplateTextareaLayout>
                <textarea
                  readOnly={true}
                  disabled={true}
                  value={rcs.msg || ""}
                ></textarea>
              </TemplateTextareaLayout>
            </ContentsLayout>

            <ContentsLayout label={"URL 분석"}>
              {rcs.urlLink.list && <UrlList urlList={rcs.urlLink.list} />}
            </ContentsLayout>
          </Fragment>
        );
      })}
    </div>
  );
};
