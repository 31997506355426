import styled from "styled-components";

export const EndPageLayout = styled.div`
  background-color: #42a5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  justify-content: center;
  padding: 1em 2em;
  color: white;
  font-size: 1.5em;
  font-weight: 600;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
`;

export const Circle = styled.div`
  height: 2px;
  width: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 50%;
  transform: translate3d(-50%, 50%, 0);
  animation-name: ripple;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  will-change: transform, opacity;

  &.ripple-1 {
    animation-delay: 0s;
  }
  &.ripple-2 {
    animation-delay: 2s;
  }
  &.ripple-3 {
    animation-delay: 3s;
  }
  &.ripple-4 {
    animation-delay: 4s;
  }
  &.ripple-5 {
    animation-delay: 5s;
  }

  @keyframes ripple {
    0% {
      transform: translate3d(-50%, 50%, 0) scale(0);
      opacity: 0.33;
    }

    100% {
      transform: translate3d(-50%, 50%, 0) scale(2000);
      opacity: 0;
    }
  }
`;
