import { ReactElement } from "react";
import { Card } from "@components/common/card/Card.tsx";
import { ReportData, ReportProject } from "@interfaces/report/report.data.ts";
import { ProjectInfoSection } from "@components/report/ProjectInfoSection.tsx";
import { QuotaTableSection } from "@components/report/QuotaTableSection.tsx";
import { Quota } from "@bizchat/api-interface";

interface ReportInfoProps {
  project: ReportProject;
  statusData: Quota[];
  targetProject: string;
}

export const ReportInfoSection = ({
  project,
  statusData,
  targetProject,
}: ReportInfoProps): ReactElement => {
  return (
    <Card>
      <ProjectInfoSection project={project} targetProject={targetProject} />

      <QuotaTableSection
        statusData={statusData}
        targetProject={targetProject}
      />
    </Card>
  );
};
