import styled from "styled-components";

export const SamplingLayout = styled.form`
  margin: auto;
  height: 100%;
`;

export const SamplingAgeLayout = styled.div`
  display: grid;
  grid-template-columns: 200px auto;
  min-height: 220px;

  .age-unit-container {
    width: 150px;
    border: 1px solid ${({ theme }) => theme.colors.borderColor1};
    border-bottom: none;
    height: fit-content;
  }

  .age-unit-container li {
    text-align: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor1};
  }

  .age-range-container {
    border: 1px solid ${({ theme }) => theme.colors.borderColor1};
    width: fit-content;
    height: fit-content;
    padding: 0 1rem;
  }

  .age-range-container li {
    margin: 0.5rem 0;
  }

  .current-age {
    background-color: ${({ theme }) => theme.colors.purple000};
    color: ${({ theme }) => theme.colors.white000};
  }

  .custom-age-layout {
    display: flex;
    align-items: start;
    gap: 30px;
    width: 100%;

    .custom-age-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: fit-content;
      width: 100%;

      border: 1px solid ${({ theme }) => theme.colors.borderColor1};
      padding: 0.8em 1em;
    }

    .age-input {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      padding: 4px 0;

      input {
        width: auto;
      }
    }
  }
`;
