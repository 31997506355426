import { ReactElement } from "react";
import { Typography } from "@components/common/Typography";
import { Button } from "@components/common/button";
import { Toast } from "@components/common/toast/Toast.tsx";
import { excelDownload } from "@services/download/excel.download.ts";

interface QuotaDataDownloadButtonProps {
  targetProject: string;
}

export const QuotaDataDownloadButton = ({
  targetProject,
}: QuotaDataDownloadButtonProps): ReactElement => {
  const handleClickRawDataDownload = async () => {
    await excelDownload(targetProject);
    Toast.success("데이터 다운로드가 완료되었습니다.");
    try {
    } catch (e) {
      Toast.error("데이터 다운로드에 실패하였습니다.");
    }
  };

  return (
    <Button
      type={"button"}
      variant={"black000"}
      className={"download-btn"}
      onClick={handleClickRawDataDownload}
    >
      <Typography as={"span"}>다운로드</Typography>
    </Button>
  );
};
