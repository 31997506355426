import { ChangeEvent, ReactElement } from "react";
import * as S from "@components/project/views/styles/FilteringView.styled.ts";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import classNames from "classnames";
import { Radio, RadioGroup } from "@components/common/radio";
import { Button } from "@components/common/button";
import { MdOutlineCancel } from "react-icons/md";
import RangeSlider from "react-range-slider-input";
import { UsageFilterList } from "@components/project/sampling/filtering/UsageFilterList.tsx";
import { FilterListProps } from "@components/project/sampling/filtering/FilterList.tsx";
import {
  SamplingFilterNameType,
  SamplingFilterOptions,
  SamplingFilterType,
} from "@bizchat/api-interface";
import { UsageFilter } from "@components/project/views/FilteringView.tsx";

interface SubFilterListProps
  extends Omit<FilterListProps, "usageFilterData" | "handleSelectFilterClick"> {
  handleSelectAllChange: () => void;
  handleSelectOptionRadioClick: (e: ChangeEvent<HTMLInputElement>) => void;
  handleResetOptionChange: (state: string) => void;
  handleSelectOptionClick: (state: SamplingFilterOptions) => void;
  handleInputRange: (value: number[], code: string) => void;
  handleAddFilter: (
    filter: UsageFilter[],
    metaType: SamplingFilterType,
  ) => void;
  handleAddFilterList: (
    metaType: SamplingFilterType,
    filter: UsageFilter[],
  ) => void;
  handleRemoveFilter: (index: number, metaType: SamplingFilterType) => void;
}

export const SubFilterList = ({
  filterState,
  currentFilter,
  currentUsageFilter,
  filterData,
  appFilter,
  telFilter,
  handleSelectAllChange,
  handleSelectOptionRadioClick,
  handleResetOptionChange,
  handleSelectOptionClick,
  handleInputRange,
  handleAddFilter,
  handleAddFilterList,
  handleRemoveFilter,
}: SubFilterListProps): ReactElement => {
  const {
    MMS_SCORE,
    MOVE_DEPARTURE,
    PARENTS,
    SINGLE_PERSON_HOUSEHOLDS,
    DISTANCE,
  } = SamplingFilterNameType;

  const radioState = (targetCode: string) => {
    return (
      filterState
        .flatMap(({ options }) => options)
        .find(({ code }) => code === targetCode)?.selectOption[0]?.code || ""
    );
  };

  const checkedOption = (code: string) => {
    const found = currentFilter.options.find((option) => option.code === code);
    return !!found;
  };

  const isRange = () => {
    const { filterName } = currentFilter;
    return (
      filterName === MMS_SCORE ||
      filterName === MOVE_DEPARTURE ||
      filterName === PARENTS ||
      filterName === SINGLE_PERSON_HOUSEHOLDS ||
      filterName === DISTANCE
    );
  };

  const isDistance = () => {
    return currentFilter.filterName === DISTANCE;
  };

  const minValue = (code: string) => {
    return (
      filterState
        .flatMap(({ options }) => options)
        .find((option) => option.code === code)?.min || 0
    );
  };

  const maxValue = (code: string) => {
    return (
      filterState
        .flatMap(({ options }) => options)
        .find((option) => option.code === code)?.max || 0
    );
  };

  const minMaxDisabled = (code: string) => {
    const found = filterState
      .flatMap(({ options }) => options)
      .find((option) => option.code === code);
    return !found;
  };

  return (
    <S.FilterSubLists>
      {(currentFilter.filterName === "Life Stage Seg." ||
        currentFilter.filterName === "레저 관련 방문") && (
        <li className={"selected-all-container"}>
          <FlexBox $flexDirection={"row"} $justifyContent={"flex-start"}>
            <input
              id={"filter-all"}
              type={"checkbox"}
              checked={
                currentFilter.options.length ===
                filterData.find(
                  ({ filterName }) => filterName === currentFilter.filterName,
                )?.options.length
              }
              onChange={handleSelectAllChange}
            />
            <label htmlFor={"filter-all"}>
              <Typography $fontWeight={600} $fontColor={"textBlack100"}>
                ALL
              </Typography>
            </label>
          </FlexBox>
        </li>
      )}
      {filterData &&
        filterData
          .find(({ filterName }) => filterName === currentFilter.filterName)
          ?.options.map((option) => (
            <li key={option.code} className={classNames("mt-16", "mb-16")}>
              {!option.label && 0 < option.selectOption.length ? (
                <div className={option.label ? "leisure-container" : ""}>
                  <FlexBox
                    $flexDirection={"row"}
                    $justifyContent={"flex-start"}
                  >
                    <RadioGroup
                      state={radioState(option.code)}
                      onChange={handleSelectOptionRadioClick}
                    >
                      <FlexBox
                        $flexDirection={"row"}
                        $justifyContent={"flex-start"}
                        $gap={10}
                        style={{ flexWrap: "wrap" }}
                      >
                        {option.selectOption.map(
                          ({
                            label: selectOptionLabel,
                            code: selectOptionCode,
                          }) => (
                            <Radio
                              key={selectOptionCode}
                              name={option.code}
                              value={selectOptionCode}
                              label={selectOptionLabel}
                            />
                          ),
                        )}
                      </FlexBox>
                    </RadioGroup>
                    {option.label && (
                      <Button
                        variant={"icon"}
                        aria-label={"clear-button"}
                        className={"clear-button"}
                        onClick={() => handleResetOptionChange(option.code)}
                      >
                        <MdOutlineCancel color={"#535353"} size={20} />
                      </Button>
                    )}
                  </FlexBox>
                </div>
              ) : (
                <div
                  className={
                    option.label !== "MMS 수용성" ? "leisure-container" : ""
                  }
                >
                  {option.label !== "MMS 수용성" && (
                    <FlexBox
                      $justifyContent={"flex-start"}
                      $flexDirection={"row"}
                      $alignItems={"flex-start"}
                    >
                      <input
                        id={option.label}
                        checked={checkedOption(option.code)}
                        type="checkbox"
                        onChange={() => handleSelectOptionClick(option)}
                      />
                      <label htmlFor={option.label}>
                        <Typography
                          $fontWeight={400}
                          $fontColor={"textBlack100"}
                        >
                          {option.label}
                        </Typography>
                      </label>
                    </FlexBox>
                  )}
                  {isRange() && (
                    <FlexBox $gap={20}>
                      <RangeSlider
                        min={0}
                        max={isDistance() ? 200 : 1}
                        step={isDistance() ? 1 : 0.01}
                        value={[minValue(option.code), maxValue(option.code)]}
                        disabled={minMaxDisabled(option.code)}
                        onInput={(value: number[]) =>
                          handleInputRange(value, option.code)
                        }
                      />
                      <FlexBox
                        $flexDirection={"row"}
                        $justifyContent={"space-around"}
                        className={"pb-16"}
                      >
                        <Typography as={"span"}>
                          최소: {minValue(option.code)}{" "}
                          {isDistance() ? "km" : ""}
                        </Typography>
                        <Typography as={"span"}>
                          최대: {maxValue(option.code)}{" "}
                          {isDistance() ? "km" : ""}
                        </Typography>
                      </FlexBox>
                    </FlexBox>
                  )}
                </div>
              )}
            </li>
          ))}
      <UsageFilterList
        appFilter={appFilter}
        telFilter={telFilter}
        currentUsageFilter={currentUsageFilter}
        handleAddFilter={handleAddFilter}
        handleAddFilterList={handleAddFilterList}
        handleRemoveFilter={handleRemoveFilter}
      />
    </S.FilterSubLists>
  );
};
