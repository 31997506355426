import React from "react";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { IoIosSend } from "react-icons/io";
import * as S from "./styles/SendResult.styled.ts";
import { CampaignSendResultState } from "@bizchat/api-interface";

interface SendResultReactionTableProps {
  sendResult: CampaignSendResultState | undefined | null;
}

export const SendResultReactionTable = ({
  sendResult,
}: SendResultReactionTableProps) => {
  return (
    <section className={"reaction_table_section"}>
      <S.SendResultTitleContainer>
        <FlexBox
          $flexDirection={"row"}
          $justifyContent={"flex-start"}
          $gap={10}
        >
          <IoIosSend size={22} color={"#222"} />
          <Typography $fontWeight={600}>메시지 유형별 발송 결과</Typography>
        </FlexBox>
      </S.SendResultTitleContainer>

      <S.StyledFlexBox>
        <S.RowFlexBox>
          <S.SendResultReactionTableLayout>
            <thead>
              <tr>
                <th>유형</th>
                <th>발송 성공 수</th>
                <th>반응자 수(반응률)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>RCS 메시지</th>
                <td>{sendResult?.rcsMsgRecvCnt}</td>
                <td>
                  {sendResult?.rcsMsgReactCnt} (
                  {sendResult?.rcsMsgReactRatio || "0"}%)
                </td>
              </tr>
              <tr>
                <th>일반 메시지</th>
                <td>{sendResult?.vmgMsgRecvCnt}</td>
                <td>
                  {sendResult?.vmgMsgReactCnt} (
                  {sendResult?.vmgMsgReactRatio || "0"}
                  %)
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>합</th>
                <td>{sendResult?.msgRecvCnt}</td>
                <td>
                  {sendResult?.msgReactCnt} ({sendResult?.msgReactRatio || "0"}
                  %)
                </td>
              </tr>
            </tfoot>
          </S.SendResultReactionTableLayout>
        </S.RowFlexBox>
      </S.StyledFlexBox>
    </section>
  );
};
