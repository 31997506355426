import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SideMenuCategories {
  PROJECT = "project",
  PROJECT_LIST = "project-lists",
  PROJECT_REGISTRATION = "project-registration",
  NOTICE = "notice",
  HISTORY = "history",
}

export interface UtilityStore {
  sideMenuIsOpen: boolean;
  sideMenuCurrentTab: string;
}

const initialState: UtilityStore = {
  sideMenuIsOpen: false,
  sideMenuCurrentTab: "",
};

export const UtilitySlice = createSlice({
  name: "utilityStore",
  initialState,
  reducers: {
    setSideBarIsOpen(state: UtilityStore, action: PayloadAction<boolean>) {
      state.sideMenuIsOpen = action.payload;
    },
    setSideMenuCurrentTab(
      state: UtilityStore,
      action: PayloadAction<SideMenuCategories>,
    ) {
      state.sideMenuCurrentTab = action.payload;
    },
  },
});

export const { setSideBarIsOpen, setSideMenuCurrentTab } = UtilitySlice.actions;
