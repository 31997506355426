import styled from "styled-components";

export const SearchGroupLayout = styled.form`
  input {
    border-radius: 6px;
  }

  button {
    width: 66px;
  }
`;
