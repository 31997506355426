import { ReactElement } from "react";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Button } from "@components/common/button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { FaSave } from "react-icons/fa";

interface StepButtonGroup {
  onClickSave?: () => void;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  disabled?: boolean;
  pending?: boolean;
}
export const StepButtonGroup = ({
  disabled,
  onClickSave,
  onClickPrev,
  onClickNext,
  pending = false,
}: StepButtonGroup): ReactElement => {
  return (
    <FlexBox
      className={"mt-30"}
      $gap={10}
      $flexDirection={"row"}
      $justifyContent={"flex-end"}
    >
      {onClickSave && (
        <Button
          disabled={pending || disabled || false}
          style={{ width: 60 }}
          aria-label={"save-button"}
          onClick={onClickSave}
        >
          <FaSave color={"#fff"} size={20} />
        </Button>
      )}
      {onClickPrev && (
        <Button
          aria-label={"previous-button"}
          type={"button"}
          onClick={onClickPrev}
          disabled={pending}
        >
          <FaArrowLeft color={"#fff"} size={20} />
        </Button>
      )}
      {onClickNext && (
        <Button
          disabled={pending || disabled || false}
          aria-label={"submit-button"}
          type={"submit"}
          onClick={onClickNext}
        >
          <FaArrowRight color={"#fff"} size={20} />
        </Button>
      )}
    </FlexBox>
  );
};
