import styled from "styled-components";

export const TermsPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  justify-content: center;
  overflow-y: auto;
  padding: 1em 2em;

  .terms_title {
    width: 100%;
    text-align: center;
  }

  ${({ theme }) => theme.scroll.theme()};

  form {
    width: 100%;
    max-width: 600px;
    height: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .button {
    width: 100%;
    height: 40px;
    color: white;
    background-color: #8869ea;
    border-radius: 4px;
    padding: 0 0.8em;
  }
`;
