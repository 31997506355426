import { ChangeEvent, ReactElement } from "react";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Option, SelectBox } from "@components/common/select-box/SelectBox.tsx";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { ButtonsLists, Options, RcsTemplateType } from "@bizchat/api-interface";
import { rcsButtonNameMaxLength } from "@bizchat/utility";
import { RcsButtonGroup } from "@components/project/message/styles/RcsButtons.styled";

interface RcsButtonsProps {
  buttons: ButtonsLists[];
  onChangeButtonType: (option: Option, index: number) => void;
  onChangeValue: (e: ChangeEvent<HTMLInputElement>, index: number) => void;
  rcsButtonType: Options[];
  rcsTemplateType: RcsTemplateType;
}

interface ButtonsGroupProps
  extends Omit<
    RcsButtonsProps,
    "buttons" | "onChangeButtonType" | "onChangeValue" | "onChangeUrlChecked"
  > {
  button: ButtonsLists;
  onChangeButtonType: (e: Option) => void;
  onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ButtonsInputGroup = ({
  button,
  onChangeButtonType,
  onChangeValue,
  rcsButtonType,
  rcsTemplateType,
}: ButtonsGroupProps): ReactElement => {
  return (
    <FlexBox $gap={10} $justifyContent={"flex-start"}>
      <FlexBox $flexDirection={"row"} $justifyContent={"flex-start"} $gap={10}>
        <SelectBox
          value={+button.type}
          options={rcsButtonType}
          onChange={onChangeButtonType}
        />
        <BasicInput
          type={"text"}
          value={button.name}
          name={"name"}
          placeholder={"버튼명"}
          onChange={onChangeValue}
          maxLength={rcsButtonNameMaxLength(rcsTemplateType)}
        />
      </FlexBox>
      <FlexBox $flexDirection={"row"} $justifyContent={"flex-start"}>
        <BasicInput
          type={"text"}
          placeholder={"https://"}
          value={button.val1}
          name={"val1"}
          onChange={onChangeValue}
        />
      </FlexBox>
    </FlexBox>
  );
};

export const RcsButtons = ({
  buttons,
  onChangeButtonType,
  onChangeValue,
  rcsButtonType,
  rcsTemplateType,
}: RcsButtonsProps): ReactElement => {
  return (
    <RcsButtonGroup>
      {buttons.map((button, index) => (
        <ButtonsInputGroup
          key={index}
          button={button}
          rcsTemplateType={rcsTemplateType}
          onChangeButtonType={(option: Option) =>
            onChangeButtonType(option, index)
          }
          onChangeValue={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeValue(e, index)
          }
          rcsButtonType={rcsButtonType}
        />
      ))}
    </RcsButtonGroup>
  );
};
