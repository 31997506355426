import { Typography } from "@components/common/Typography";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import {
  setSideMenuCurrentTab,
  SideMenuCategories,
} from "@store/slices/utilitySlces.ts";
import { ReactElement, useLayoutEffect } from "react";
import {
  SideNavBarLogoBox,
  SideNavBarMenuContainer,
} from "@components/nav/styles/NavLists.styled.ts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PiSpeakerSimpleHighBold } from "react-icons/pi";
import { HiOutlineDocumentPlus } from "react-icons/hi2";
import { CgFolder } from "react-icons/cg";
import { TargetingLogo } from "@components/common/logo/TargetingLogo.tsx";

export const NavLists = (): ReactElement => {
  const { sideMenuCurrentTab } = useAppSelector((state) => state.utilityStore);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    const { pathname } = location;
    // 캠페인 메뉴
    const PROJECT = "project";

    if (pathname.includes(PROJECT)) {
      if (pathname.includes("lists") || pathname.includes("details"))
        dispatch(setSideMenuCurrentTab(SideMenuCategories.PROJECT_LIST));
      if (pathname.includes("registration"))
        dispatch(
          setSideMenuCurrentTab(SideMenuCategories.PROJECT_REGISTRATION),
        );
    }
    // 편집 중
    if (pathname.includes("edit"))
      dispatch(setSideMenuCurrentTab(SideMenuCategories.PROJECT_LIST));
    // 공지사항
    if (pathname.includes("notice"))
      dispatch(setSideMenuCurrentTab(SideMenuCategories.NOTICE));
    // 발송기록 메뉴
    if (pathname.includes("history")) {
      dispatch(setSideMenuCurrentTab(SideMenuCategories.HISTORY));
    }
  }, [location]);

  const active = (currentTab: string) => {
    return currentTab === sideMenuCurrentTab;
  };

  const handleClickMenuItem = (currentTab: string) => {
    navigate(`/${currentTab}`);
  };

  return (
    <>
      <SideNavBarLogoBox>
        <Link to={"/"}>
          <TargetingLogo />
        </Link>
      </SideNavBarLogoBox>
      <SideNavBarMenuContainer>
        <ul className={"item-lists"}>
          <li>
            <div
              className={classNames(
                "item",
                active(SideMenuCategories.PROJECT_LIST) && "active",
              )}
            >
              <button
                type={"button"}
                onClick={() => handleClickMenuItem("project")}
              >
                <CgFolder
                  size={24}
                  color={
                    active(SideMenuCategories.PROJECT_LIST) ? "#fff" : "#b9b9b9"
                  }
                />
                <Typography
                  $fontColor={
                    active(SideMenuCategories.PROJECT_LIST)
                      ? "textWhite"
                      : "textGray200"
                  }
                  $fontWeight={
                    active(SideMenuCategories.PROJECT_LIST) ? 700 : 400
                  }
                >
                  프로젝트 리스트
                </Typography>
              </button>
            </div>
          </li>
          <li>
            <div
              className={classNames(
                "item",
                active(SideMenuCategories.PROJECT_REGISTRATION) && "active",
              )}
            >
              <button
                type={"button"}
                onClick={() => handleClickMenuItem("project/registration")}
              >
                <HiOutlineDocumentPlus
                  size={24}
                  color={
                    active(SideMenuCategories.PROJECT_REGISTRATION)
                      ? "#fff"
                      : "#b9b9b9"
                  }
                />
                <Typography
                  $fontColor={
                    active(SideMenuCategories.PROJECT_REGISTRATION)
                      ? "textWhite"
                      : "textGray200"
                  }
                  $fontWeight={
                    active(SideMenuCategories.PROJECT_REGISTRATION) ? 700 : 400
                  }
                >
                  등록하기
                </Typography>
              </button>
            </div>
          </li>
          {/*<li>*/}
          {/*  <div*/}
          {/*    className={classNames(*/}
          {/*      "item",*/}
          {/*      active(SideMenuCategories.NOTICE) && "active"*/}
          {/*    )}*/}
          {/*  >*/}
          {/*    <button*/}
          {/*      type={"button"}*/}
          {/*      onClick={() => handleClickMenuItem(SideMenuCategories.NOTICE)}*/}
          {/*    >*/}
          {/*      <PiSpeakerSimpleHighBold*/}
          {/*        size={24}*/}
          {/*        color={active(SideMenuCategories.NOTICE) ? "#fff" : "#b9b9b9"}*/}
          {/*      />*/}
          {/*      <Typography*/}
          {/*        $fontColor={*/}
          {/*          active(SideMenuCategories.NOTICE)*/}
          {/*            ? "textWhite"*/}
          {/*            : "textGray200"*/}
          {/*        }*/}
          {/*        $fontWeight={active(SideMenuCategories.NOTICE) ? 700 : 400}*/}
          {/*      >*/}
          {/*        공지사항*/}
          {/*      </Typography>*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <div*/}
          {/*    className={classNames(*/}
          {/*      "item",*/}
          {/*      active(SideMenuCategories.HISTORY) && "active",*/}
          {/*    )}*/}
          {/*  >*/}
          {/*    <button*/}
          {/*      type={"button"}*/}
          {/*      onClick={() => handleClickMenuItem("history")}*/}
          {/*    >*/}
          {/*      <PiReadCvLogoBold*/}
          {/*        size={24}*/}
          {/*        color={*/}
          {/*          active(SideMenuCategories.HISTORY) ? "#fff" : "#b9b9b9"*/}
          {/*        }*/}
          {/*      />*/}
          {/*      <Typography*/}
          {/*        $fontColor={*/}
          {/*          active(SideMenuCategories.HISTORY)*/}
          {/*            ? "textWhite"*/}
          {/*            : "textGray200"*/}
          {/*        }*/}
          {/*        $fontWeight={active(SideMenuCategories.HISTORY) ? 700 : 400}*/}
          {/*      >*/}
          {/*        발송기록*/}
          {/*      </Typography>*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*</li>*/}
        </ul>
      </SideNavBarMenuContainer>
    </>
  );
};
