import React, { PropsWithChildren, ReactElement, useState } from "react";
import {
  BillingTypeState,
  CampaignDetails,
  RcvType,
} from "@bizchat/api-interface";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { CampaignTemplateTabs } from "@components/campaign/CampaignTemplateTabs.tsx";
import { CampaignThumbnail } from "@components/campaign/CampaignThumbnail.tsx";
import { MmsTemplate } from "@components/campaign/MmsTemplate.tsx";
import { RcsTemplate } from "@components/campaign/RcsTemplate.tsx";
import { Typography } from "@components/common/Typography";
import { CgTemplate } from "react-icons/cg";
import styled from "styled-components";
import classNames from "classnames";

const CampaignDetailLayout = styled.div`
  width: 100%;
  margin-top: 20px;
`;

interface CampaignProps extends PropsWithChildren {
  campaign: CampaignDetails | null | undefined;
  campaignId: string;
}

export const Campaign = (props: CampaignProps): ReactElement => {
  const { campaign, campaignId, children } = props;

  if (!campaign) {
    return (
      <div>
        <Typography>캠페인 정보가 없습니다.</Typography>
      </div>
    );
  }
  const rcvTypeText = (rcvType: number): string => {
    switch (rcvType) {
      case RcvType.ATS:
        return "ATS 일반 타켓팅";
      case RcvType.MAPTICS_REAL_TIME:
        return "Maptics 타켓팅 (실시간 보내기)";
      case RcvType.MAPTICS_COLLECT_SEND:
        return "Maptics 타켓팅 (모아서 보내기)";
      case RcvType.MDB_REDIRECT:
        return "MDN 직접 업로드 (테스트)";
      default:
        return "";
    }
  };

  const billingTypeText = (billingType: number): string => {
    switch (billingType) {
      case BillingTypeState.LMS:
        return "LMS";
      case BillingTypeState.RCS_MMS:
        return "RCS MMS";
      case BillingTypeState.MMS:
        return "MMS";
      case BillingTypeState.RCS_LMS:
        return "RCS LMS";
      default:
        return "";
    }
  };

  const isMms = (billingType: number): boolean => {
    return billingType === 0 || billingType === 2;
  };

  const [templateType, setTemplateType] = useState("mms");
  const onClickTemplateHandler = (type: string) => {
    setTemplateType(type);
  };

  const thumbnailImageId = (): string => {
    let fileId = "";

    const mms = templateType === "mms";
    if (campaign) {
      if (mms) {
        const { mms } = campaign;
        if (!!mms.fileInfo) {
          const file = mms?.fileInfo.list || [];
          if (file.length > 0) {
            fileId = file[file.length - 1].origId;
          }
        }
      } else {
        const { rcs } = campaign;
        if (!!rcs[0].imgOrigId) fileId = rcs[0].imgOrigId;
      }
    }
    return fileId;
  };

  return (
    <CampaignDetailLayout>
      <FlexBox
        $flexDirection={"row"}
        $justifyContent={"flex-start"}
        $alignItems={"center"}
        $gap={10}
        className={classNames("title_label", "mb-10")}
      >
        <CgTemplate size={24} color={"#3a3a3a"} />
        <Typography
          $fontWeight={600}
          $fontSize={18}
          $fontColor={"textBlack100"}
        >
          캠페인 상세정보
        </Typography>
      </FlexBox>
      <ContentsLayout label={"캠페인명"}>
        <BasicInput readOnly={true} disabled={true} value={campaign?.name} />
      </ContentsLayout>
      <ContentsLayout label={"캠페인 ID"}>
        <BasicInput readOnly={true} disabled={true} value={campaignId} />
      </ContentsLayout>
      {children}
      <FlexBox
        $flexDirection={"row"}
        $justifyContent={"space-between"}
        $gap={10}
        className={"mt-20"}
      >
        <ContentsLayout label={"과금 유형"}>
          <BasicInput
            readOnly={true}
            disabled={true}
            value={billingTypeText(campaign?.billingType || 0)}
          />
        </ContentsLayout>
        <ContentsLayout label={"수신자 구분"}>
          <BasicInput
            readOnly={true}
            disabled={true}
            value={rcvTypeText(campaign?.rcvType || 0)}
          />
        </ContentsLayout>
      </FlexBox>
      <CampaignThumbnail fileId={thumbnailImageId()} />
      <CampaignTemplateTabs
        isMms={isMms(campaign?.billingType || 0)}
        campaignTemplateType={onClickTemplateHandler}
      />
      {templateType === "mms" ? (
        <MmsTemplate mms={campaign!.mms} />
      ) : (
        <RcsTemplate rcs={campaign!.rcs} rcsType={campaign!.rcvType} />
      )}
    </CampaignDetailLayout>
  );
};
