import { ProjectSearchFilter } from "@bizchat/api-interface";

export const getQueryData = (
  getQueryString: (key: string) => string | null,
) => {
  const pageString = getQueryString("page") || "1";
  const searchFilterString =
    getQueryString("searchFilter") || ProjectSearchFilter.CAMPAIGN_NAME;
  const searchKeyword = getQueryString("searchKeyword") || "";
  const page = isNaN(Number(pageString)) ? 1 : +pageString;

  const searchFilter = Object.values(ProjectSearchFilter).includes(
    searchFilterString as ProjectSearchFilter,
  )
    ? searchFilterString
    : ProjectSearchFilter.CAMPAIGN_NAME;

  return {
    page,
    searchFilter: searchFilter as ProjectSearchFilter,
    searchKeyword,
  };
};
