import { ReactElement } from "react";
import { ProgressTab } from "@components/project/common/ProgressTab.tsx";
import { Tabs } from "@store/slices/projectSlice.ts";
import {SettingView} from "@components/project/views/SettingView.tsx";

export const RegistrationView = (): ReactElement => {
  return (
    <>
      <ProgressTab currentTab={Tabs.SETTING} />
      <SettingView />
    </>
  );
};
