import styled from "styled-components";

export const TermsContentLayout = styled.div`
  .agree_description {
    text-decoration: underline;
    text-underline-position: under;
  }
`;

export const TermsDescriptionBox = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 1em;
  border: 1px solid #ededed;
  border-radius: 4px;
  background-color: #fdfdfd;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
`;

export const TermsContentBox = styled.section`
  width: 100%;
  margin-bottom: 1em;
  height: 400px;
  overflow-y: auto;
  ${({ theme }) => theme.scroll.theme()};
  background-color: #fdfdfd;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;

  .terms-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 1em;
    border: 1px solid #ededed;
    padding: 1em;
  }

  .content {
    color: #3b3b3b;
    font-size: 15px;
  }

  .terms-info-container {
    border: 1px solid #ededed;
    padding: 1em;

    .title {
      color: #3b3b3b;
      font-size: 16px;
      font-weight: 600;
      line-height: 3;
      text-decoration: underline;
      text-underline-position: under;
    }

    .sub-title {
      color: #3b3b3b;
      font-size: 15px;
      font-weight: 700;
    }

    .sub-description {
      color: #575757;
      font-size: 14px;

      b {
        font-weight: 600;
        padding-left: 0.4em;
      }
    }
  }
`;
