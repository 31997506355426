import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import { Dotted } from "@components/common/line";
import classNames from "classnames";
import moment from "moment/moment";
import { ReportDataDownloadButton } from "@components/report/download/ReportDataDownloadButton.tsx";
import { ReportProject } from "@interfaces/report/report.data.ts";
import { createCampaignStatistics } from "@apis/campaign";
import { GenerateStatus } from "@bizchat/api-interface";
import { queryClient } from "@libs/tanstack/queryClient.ts";
import { reportQueryKey } from "@services/queries/keys";
import { MdDataSaverOff } from "react-icons/md";
import * as S from "./styles/ProjectInfoSection.styled.ts";

interface ProjectInfoSectionProps {
  project: ReportProject;
  targetProject: string;
}

export const ProjectInfoSection = ({
  targetProject,
  project,
}: ProjectInfoSectionProps) => {
  const { campaignTitle, startDate, endDate } = project;

  const handleClickCreateCampaignStatistics = async () => {
    alert(`캠페인 데이터를 생성합니다.`);
    await createCampaignStatistics(targetProject);
    await queryClient.invalidateQueries({
      queryKey: [reportQueryKey.projectInfo],
    });
  };

  const creating = project.statisticsGenerate === GenerateStatus.CREATING;

  return (
    <S.ProjectInfoLayout>
      <FlexBox
        className={"mb-10"}
        $flexDirection={"row"}
        $justifyContent={project.statisticsDate ? "space-between" : "flex-end"}
      >
        {project.statisticsDate && (
          <p>
            <Typography as={"span"} $fontColor={"textBlack100"}>
              업데이트 날짜 :
            </Typography>
            <Typography
              className={"ml-4"}
              $fontSize={14}
              as={"span"}
              $fontColor={"textBlack100"}
            >
              {moment(project.statisticsDate).format("YYYY-MM-DD, HH:mm:ss")}
            </Typography>
          </p>
        )}
        <button
          className={"create_button"}
          disabled={creating}
          type={"button"}
          onClick={handleClickCreateCampaignStatistics}
        >
          <MdDataSaverOff
            className={classNames("spinner", {
              active: creating,
            })}
            size={18}
            color={creating ? "#9b9b9b" : "#676767"}
          />
          <Typography
            $fontWeight={600}
            as={"span"}
            $fontColor={"textBlack200"}
            $fontSize={14}
          >
            반응률 조회
          </Typography>
        </button>
      </FlexBox>

      <FlexBox
        $flexDirection={"row"}
        $justifyContent={"space-between"}
        className={"pb-5"}
      >
        <Typography as={"h1"} $fontSize={22} $fontWeight={700}>
          {campaignTitle}
        </Typography>
        <div>
          <FlexBox $flexDirection={"row"} $gap={10}>
            <ReportDataDownloadButton targetProject={targetProject} />
          </FlexBox>
        </div>
      </FlexBox>

      <Dotted />

      <FlexBox
        $flexDirection={"row"}
        $justifyContent={"flex-start"}
        $gap={10}
        className={classNames("pt-10", "pb-30")}
      >
        <Typography as={"span"} $fontSize={17} $fontWeight={700}>
          기간 :
        </Typography>
        <Typography as={"span"} $fontSize={17}>
          {moment(startDate).format("yyyy.MM.DD. HH:mm:ss A")}
        </Typography>
        <Typography as={"span"} $fontSize={17}>
          ~
        </Typography>
        <Typography as={"span"} $fontSize={17}>
          {moment(endDate).format("yyyy.MM.DD. HH:mm:ss A")}
        </Typography>
      </FlexBox>
    </S.ProjectInfoLayout>
  );
};
