import TEAMS_LOGO from "@assets/teams.webp";
import styled from "styled-components";
import { Typography } from "@components/common/Typography";

const TeamsButtonLayout = styled.button`
  border: 1px solid transparent;
  background-color: #8869ea;
  margin-top: 20px;
  width: 100%;
  max-width: 320px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .teams_icon {
    img {
      width: 30px;
    }
  }
`;

export const TeamsSignInButton = () => {
  const handleClickTeamsSignIn = async () => {
    const TEAMS_URL = process.env.VITE_TEAMS_AUTH_URL;
    const REDIRECT_URL =
      process.env.NODE_ENV === "production"
        ? process.env.VITE_PRODUCTION_REDIRECT_URL
        : process.env.VITE_LOCAL_REDIRECT_URL;
    document.location.href = `${TEAMS_URL}?redirectUrl=${REDIRECT_URL}`;
  };

  return (
    <TeamsButtonLayout type={"button"} onClick={handleClickTeamsSignIn}>
      <span className={"teams_icon"}>
        <img src={TEAMS_LOGO} alt={"teams_logo"} height={"auto"} />
      </span>
      <Typography as={"span"} $fontColor={"textWhite"} $fontWeight={400}>
        팀즈로 로그인
      </Typography>
    </TeamsButtonLayout>
  );
};
