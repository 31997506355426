import { ReactElement } from "react";
import * as S from "@components/project/views/styles/FilteringView.styled.ts";
import classNames from "classnames";
import { Typography } from "@components/common/Typography";
import {
  SamplingFilterList,
  SamplingFilterNameType,
} from "@bizchat/api-interface";
import {
  UsageFilter,
  UsageFilterData,
} from "@components/project/views/FilteringView.tsx";

export interface FilterListProps {
  filterState: SamplingFilterList[];
  filterData: SamplingFilterList[];
  usageFilterData: UsageFilterData[];
  currentFilter: SamplingFilterList;
  currentUsageFilter: UsageFilterData;
  appFilter: UsageFilter[];
  telFilter: UsageFilter[];
  handleSelectFilterClick: (state: string) => void;
}

export const FilterList = ({
  filterState,
  filterData,
  currentFilter,
  usageFilterData,
  currentUsageFilter,
  appFilter,
  telFilter,
  handleSelectFilterClick,
}: FilterListProps): ReactElement => {
  const { CALL_USAGE } = SamplingFilterNameType;
  const checkedFilter = (filterName: string) => {
    const found = filterState.find((state) => state.filterName === filterName);
    return !!found;
  };

  const checkedUsageFilter = (filterName: string) => {
    if (filterName === CALL_USAGE) return 0 < telFilter.length;
    return 0 < appFilter.length;
  };

  return (
    <S.FilterLists>
      {filterData.map(({ filterName }) => (
        <li
          key={filterName}
          className={classNames(
            "filter-item",
            `${currentFilter.filterName === filterName ? "active" : "un-active"}`,
          )}
        >
          <input
            checked={checkedFilter(filterName)}
            id={filterName}
            type={"checkbox"}
            onChange={() => handleSelectFilterClick(filterName)}
          />
          <label htmlFor={filterName}>
            <Typography $fontWeight={400} $fontColor={"textBlack100"}>
              {filterName}
            </Typography>
          </label>
        </li>
      ))}
      {usageFilterData.map(({ filterName }) => (
        <li
          key={filterName}
          className={classNames(
            "filter-item",
            `${currentUsageFilter.filterName === filterName ? "active" : "un-active"}`,
          )}
        >
          <input
            checked={checkedUsageFilter(filterName)}
            id={filterName}
            type={"checkbox"}
            onChange={() => handleSelectFilterClick(filterName)}
          />
          <label htmlFor={filterName}>
            <Typography $fontWeight={400} $fontColor={"textBlack100"}>
              {filterName}
            </Typography>
          </label>
        </li>
      ))}
    </S.FilterLists>
  );
};
