import styled from "styled-components";

export const ImageUploadButtonLayout = styled.button`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 10px;
  border: 1px solid #ededed;
  background-color: white;
  text-align: center;
  padding: 1em 0.8em;

  &.disabled {
    background-color: #f8f8f8;
    color: #e3e3e3;

    &:hover {
      cursor: default;
    }

    .image-upload-button-text {
      color: #e3e3e3;
    }
  }
`;
