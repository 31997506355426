import React, { ReactElement } from "react";
import { Card } from "@components/common/card/Card.tsx";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import Calendar from "@components/common/datepicker/Calendar.tsx";
import { GenerateStatus, RequestFormProjectInfo } from "@bizchat/api-interface";
import classNames from "classnames";
import { Dotted } from "@components/common/line";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import { setRequestDate } from "@store/slices/requestSlice.ts";
import { minTime } from "@bizchat/utility";

interface RequestInfoProps {
  projectInfo: RequestFormProjectInfo;
}

export const RequestInfo = ({
  projectInfo,
}: RequestInfoProps): ReactElement => {
  const dispatch = useAppDispatch();
  const requestDateState = useAppSelector(
    (state) => state.requestStore.requestDate,
  );

  const handleChangeRequestDate = (requestDate: Date) => {
    dispatch(setRequestDate(requestDate.toISOString()));
  };

  return (
    <Card>
      <ContentsLayout label={"파일전송명"}>
        <BasicInput
          readOnly={true}
          disabled={true}
          name={"campaignTitle"}
          value={projectInfo.campaignTitle}
        />
      </ContentsLayout>

      <Dotted />
      <ContentsLayout label={"발송일정"} $alignItems={"baseline"}>
        <Calendar
          disabled={projectInfo.atsGenerate !== GenerateStatus.COMPLETED}
          minDate={new Date()}
          dateFormat="yyyy.MM.dd HH.mm.ss"
          showTimeSelect={true}
          selectedDate={new Date(requestDateState)}
          setSelectedDate={handleChangeRequestDate}
          minTime={minTime(requestDateState)}
          maxTime={new Date(new Date().setHours(19, 0))}
        />
        <Typography
          $fontSize={14}
          $fontColor={"textRed300"}
          $fontWeight={700}
          className={classNames("mt-10")}
        >
          * 발송일정은 현재 시간 + 1 시간부터 09시 ~ 19시 사이로 설정
          가능합니다.
        </Typography>
      </ContentsLayout>
      <ContentsLayout $width={200} label={"문자 발송 유형/발신번호"}>
        <FlexBox $flexDirection={"row"} $justifyContent={"start"} $gap={18}>
          <div>{projectInfo.sndNum.label}</div>
          <BasicInput
            readOnly={true}
            disabled={true}
            name={"sndNum"}
            value={projectInfo.sndNum.value}
          />
        </FlexBox>
      </ContentsLayout>
      <ContentsLayout label={"발송 건수(누적)"}>
        <FlexBox $flexDirection={"row"} $justifyContent={"start"} $gap={9}>
          <BasicInput
            $textAlign={"end"}
            readOnly={true}
            disabled={true}
            name={"projectTitle"}
            value={projectInfo.sndGoalCnt}
          />
          <Typography as={"span"}>명</Typography>
        </FlexBox>
      </ContentsLayout>
    </Card>
  );
};
