import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import classNames from "classnames";

interface CampaignTabProps {
  isMms: boolean;
  campaignTemplateType: (type: string) => void;
}

export const CampaignTemplateTabsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  button {
    text-align: center;
    width: 70px;
    height: 40px;
    padding: 0.2em 0.6em;
    border: 1px solid ${({ theme }) => theme.colors.borderColor2};
    border-radius: 4px;
    margin-bottom: 2em;

    background-color: #f3f3f3;
    color: #d3d3d3;

    &.active {
      border-color: transparent;
      background-color: ${({ theme }) => theme.colors.purple000};
      color: ${({ theme }) => theme.colors.white000};
    }

    &:hover {
      border-color: ${({ theme }) => theme.colors.borderColor2};
      filter: brightness(95%);
    }
  }
`;

export const CampaignTemplateTabs = (props: CampaignTabProps): ReactElement => {
  const [templateType, setTemplateType] = useState("mms");
  const { isMms, campaignTemplateType } = props;
  const handleClickChangeTab = (type: string): void => {
    setTemplateType(type);
    campaignTemplateType(type);
  };

  return (
    <CampaignTemplateTabsContainer>
      <button
        className={classNames(templateType === "mms" && "active")}
        type={"button"}
        onClick={() => handleClickChangeTab("mms")}
      >
        MMS
      </button>
      {!isMms && (
        <button
          className={classNames(templateType === "rcs" && "active")}
          type={"button"}
          onClick={() => handleClickChangeTab("rcs")}
        >
          RCS
        </button>
      )}
    </CampaignTemplateTabsContainer>
  );
};
