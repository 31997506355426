import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { TemplateListFilterOptions } from "@components/project/message/TemplateList.tsx";
import {
  axiosResponseData,
  AxiosResponseData,
} from "@libs/axios/axiosResponse.ts";
import { UpdateTemplate } from "@bizchat/api-interface";

const PROJECTS_TEMPLATE = "/projects/template";

/**
 * @description 프로젝트 상세 조회
 * @param {string} projectId 프로젝트 objectId
 */
export const findTemplateProjectApi = async (projectId: string) => {
  const url = `${PROJECTS_TEMPLATE}/${projectId}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<UpdateTemplate[]>>
  >(url);
  return axiosResponseData<UpdateTemplate[]>(data);
};

export const findTemplateListByKeywordAndTypeApi = async (
  type: string,
  keyword: string,
) => {
  const url = `${PROJECTS_TEMPLATE}/list?type=${type}&keyword=${keyword}`;
  return axiosInstance.get(url);
};

export const getTemplateListFilterApi =
  async (): Promise<TemplateListFilterOptions> => {
    try {
      const url = `${PROJECTS_TEMPLATE}/list/filter`;
      const { data } = await axiosInstance.get<
        AxiosRequestConfig,
        AxiosResponse<AxiosResponseData<TemplateListFilterOptions>>
      >(url);
      const result = axiosResponseData<TemplateListFilterOptions>(data);
      if (!result) return { campaignTitleList: [], customerNameList: [] };
      return result;
    } catch (e) {
      return { campaignTitleList: [], customerNameList: [] };
    }
  };
