import { Typography } from "@components/common/Typography";
import { CategoryFilterQueryType } from "@bizchat/api-interface";
import React from "react";
import styled from "styled-components";
import { IoFilterOutline } from "react-icons/io5";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import classNames from "classnames";

interface AtsFilterOptionProps {
  ats:
    | {
        ageQuery: CategoryFilterQueryType[];
        genderQuery: CategoryFilterQueryType[];
        locationQuery: CategoryFilterQueryType[];
        filterQuery: CategoryFilterQueryType[];
      }
    | undefined
    | null;
}

export const AtsFilterOptionContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
`;

export const FilterSection = styled.div`
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

export const FilterItem = styled.div`
  margin: 5px 0;
  font-size: 14px;
  color: #333;
`;

export const AtsFilterOption = ({ ats }: AtsFilterOptionProps) => {
  if (!ats)
    return (
      <div>
        <Typography>선택된 ATS가 없습니다.</Typography>
      </div>
    );
  return (
    <>
      <FlexBox
        $flexDirection={"row"}
        $justifyContent={"flex-start"}
        $alignItems={"center"}
        $gap={10}
        className={classNames("title_label")}
      >
        <IoFilterOutline size={24} color={"#151515"} />
        <Typography
          $fontWeight={600}
          $fontSize={16}
          $fontColor={"textBlack100"}
        >
          ATS 필터
        </Typography>
      </FlexBox>

      <AtsFilterOptionContainer>
        <FilterSection>
          {ats.genderQuery.length > 0 && (
            <>
              {ats.genderQuery.map((gender) => {
                return <FilterItem key={gender.code}>{gender.desc}</FilterItem>;
              })}
            </>
          )}
        </FilterSection>
        <FilterSection>
          {ats.ageQuery.length > 0 && (
            <>
              {ats.ageQuery.map((age) => {
                return <FilterItem key={age.code}>{age.desc}</FilterItem>;
              })}
            </>
          )}
        </FilterSection>
        <FilterSection>
          {ats.locationQuery.length > 0 && (
            <>
              {ats.locationQuery.map((location) => {
                return (
                  <FilterItem key={location.code}>{location.desc}</FilterItem>
                );
              })}
            </>
          )}
        </FilterSection>
        <FilterSection>
          {ats.filterQuery.length > 0 && (
            <>
              {ats.filterQuery.map((filter) => {
                return <FilterItem key={filter.code}>{filter.desc}</FilterItem>;
              })}
            </>
          )}
        </FilterSection>
      </AtsFilterOptionContainer>
    </>
  );
};
