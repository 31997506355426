import styled from "styled-components";

export const SignInPageLayout = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 2em;
  background: linear-gradient(
    90deg,
    #00d7f7 0%,
    #09bee8 7%,
    #1e86c9 25%,
    #2f59ae 43%,
    #3d359a 80%,
    #471b8b 89%,
    #4f0780 100%
  );
`;

export const SignInPageContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  gap: 104px;
  position: relative;

  .logo {
    display: inline-block;
  }

  .responsive-logo {
    display: none;
  }

  .sign-in-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 478px;
    width: 100%;
    height: 589px;
    background-color: ${({ theme }) => theme.colors.white000};
    z-index: 99;
    transition: ease-in-out 0.2ms;
  }

  .sign-in-button-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5em;
  }

  @media screen and (max-width: 1000px) {
    .sign-in-body {
      padding: 1em 3em;
      width: auto;
      min-width: auto;
      background-color: rgba(255, 255, 255, 0.98);
      justify-content: center;
      align-items: center;
      gap: 50px;
    }

    .logo {
      display: none;
    }

    .responsive-logo {
      width: 100%;
      display: inline-block;

      svg {
        width: 244px;
      }
    }

    .sign-in-button-wrapper {
      padding: 0;
      display: inline-block;
      height: auto;
    }

    .sign-in-button-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }
  }
`;

export const LightBallLayout1 = styled.div`
  position: absolute;
  top: 0;

  .area {
    position: relative;
    width: 100vw;
    border-radius: 50%;
    height: 100vh;
    left: -30%;
    top: -580px;
    filter: blur(100px);
    background: radial-gradient(
      ellipse,
      rgba(0, 229, 255, 0.18) 0%,
      rgba(0, 221, 246, 0.51) 3%,
      rgba(0, 221, 246, 0.71) 14%,
      rgba(0, 221, 246, 1) 38%,
      rgba(253, 253, 253, 0.55) 77%,
      rgba(255, 255, 255, 0.47) 92%,
      rgba(253, 253, 253, 0.4) 100%
    );
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const LightBallLayout2 = styled.div`
  position: absolute;
  bottom: 0;

  .area {
    position: relative;
    width: 90vw;
    border-radius: 50%;
    height: 26vh;
    left: -68%;
    bottom: 0;
    filter: blur(100px);
    background: radial-gradient(
      ellipse,
      rgba(0, 229, 255, 0.18) 0%,
      rgba(0, 221, 246, 0.51) 3%,
      rgba(0, 221, 246, 0.71) 14%,
      rgba(0, 221, 246, 1) 38%,
      rgba(253, 253, 253, 0.55) 77%,
      rgba(255, 255, 255, 0.47) 92%,
      rgba(253, 253, 253, 0.4) 100%
    );
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const LightBallLayout3 = styled.div`
  position: absolute;
  bottom: 0;

  .area {
    position: relative;
    width: 100vw;
    border-radius: 50%;
    height: 100vh;
    left: -72%;
    top: -580px;
    filter: blur(100px);
    background: radial-gradient(
      ellipse,
      rgba(255, 255, 255, 0.18) 0%,
      rgba(255, 255, 255, 0.51) 3%,
      rgba(255, 255, 255, 0.71) 14%,
      rgb(255, 255, 255) 38%,
      rgba(253, 253, 253, 0.55) 77%,
      rgba(255, 255, 255, 0.47) 92%,
      rgba(253, 253, 253, 0.4) 100%
    );
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
