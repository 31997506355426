import React, {
  ChangeEvent,
  ComponentProps,
  ForwardedRef,
  forwardRef,
} from "react";
import * as S from "./styles/BasicInput.styled.ts";
import { Typography } from "@components/common/Typography";
import classNames from "classnames";

type InputValue = string | number | ReadonlyArray<string>;
type InputChangeEvent = ChangeEvent<HTMLInputElement>;
type InputVariant = "default" | "input_button" | "checkbox";

interface InputProps extends ComponentProps<"input"> {
  $variant?: InputVariant;
  label?: string;
  value?: InputValue;
  $textAlign?: string;
}

export const BasicInput = React.memo(
  forwardRef((props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      $variant = "default",
      $textAlign = "start",
      label,
      placeholder,
      disabled,
      className,
      value,
      onChange,
      onKeyDown,
      id,
      type = "text",
      ...rest
    } = props;

    const onChangeHandler = (event: InputChangeEvent): void => {
      onChange && onChange(event);
    };

    return (
      <S.Label htmlFor={id}>
        {label && type !== "checkbox" && (
          <Typography $fontWeight={700} className={"pb-8"}>
            {label}
          </Typography>
        )}
        <S.Input
          $textAlign={$textAlign}
          ref={ref}
          className={classNames($variant, className)}
          disabled={disabled}
          id={id}
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={onChangeHandler}
          {...rest}
        />
        {label && type === "checkbox" && (
          <Typography className={"checkbox_label"}>{label}</Typography>
        )}
      </S.Label>
    );
  }),
);
