import { ReactElement } from "react";
import { Typography } from "@components/common/Typography";
import classNames from "classnames";
import { Tabs } from "@store/slices/projectSlice.ts";
import { ProjectProgressContainer } from "@components/project/common/styles/ProgressTab.styled.ts";
import { useNavigate, useParams } from "react-router";
import { projectSendCheckApi } from "@apis/project";

const tabs = [Tabs.SETTING, Tabs.MESSAGE, Tabs.SAMPLING, Tabs.REPORT] as Tabs[];

interface Props {
  currentTab: string;
}

export const ProgressTab = (props: Props): ReactElement => {
  const { currentTab } = props;
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const routeHandler = async (tab: Tabs) => {
    // 해당 탭 클릭 관련해서 프로젝트 체크 및 토큰 체크?
    if (tab === Tabs.REPORT) {
      navigate({ pathname: `/project/report/${params.id}` });
    }

    if (tab === Tabs.SAMPLING) {
      try {
        if (!params?.id) throw Error("잘못된 접근입니다.");
        const data = await projectSendCheckApi(params.id);
        const targetPath = data?.toCallApi ? "request" : "targeting";
        navigate({ pathname: `/edit/${params.id}/${targetPath}` });
      } catch (e) {
        navigate({ pathname: `/project/lists` });
      }
    }

    if (tab === Tabs.SETTING) {
      navigate({ pathname: `/edit/${params.id}` });
    }

    if (tab === Tabs.MESSAGE) {
      navigate({ pathname: `/edit/${params.id}/template` });
    }
  };

  return (
    <ProjectProgressContainer>
      {tabs.map((tab) => (
        <li key={crypto.randomUUID()} onClick={() => routeHandler(tab)}>
          <div
            className={classNames(
              currentTab === tab && "active",
              "label-group",
            )}
          >
            <Typography as={"span"} className={"label"}>
              {tab}
            </Typography>
          </div>
        </li>
      ))}
    </ProjectProgressContainer>
  );
};
