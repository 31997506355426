import { ReactElement, useState } from "react";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import Calendar from "@components/common/datepicker/Calendar.tsx";
import { Button } from "@components/common/button";
import { Card } from "@components/common/card/Card.tsx";
import { SamplingFilterList, SearchFilterList } from "@bizchat/api-interface";
import { searchFilterListApi } from "@apis/sampling/filter.api.ts";
import { useOutletContext } from "react-router";
import moment from "moment";
import { Toast } from "@components/common/toast/Toast.tsx";
import { FaSearch } from "react-icons/fa";
import { FaCopy } from "react-icons/fa6";
import {
  ModalCompType,
  ModalState,
  setModalOpen,
} from "@store/slices/modalSlice.ts";
import { useDispatch } from "react-redux";
import { MdOutlineCancel } from "react-icons/md";
import { Typography } from "@components/common/Typography";
import * as S from "@components/project/sampling/filtering/styles/FilterSearching.styled.ts";
import { Dotted } from "@components/common/line";
import classNames from "classnames";

interface FilterSearchingProps {
  setAllFilterData: (state: SamplingFilterList[]) => void;
}

const NOW = new Date();
const YEAR = NOW.getFullYear();
const MONTH = NOW.getMonth();

export const FilterSearching = ({
  setAllFilterData,
}: FilterSearchingProps): ReactElement => {
  const { id } = useOutletContext<{ id: string }>();
  const [startDate, setStartDate] = useState<Date>(new Date(YEAR, MONTH, 1));
  const [endDate, setEndDate] = useState<Date>(NOW);
  const [searchedFilterInfo, setSearchedFilterInfo] = useState<
    SearchFilterList[]
  >([]);
  const dispatch = useDispatch();
  const handleClickSearchFilter = async () => {
    try {
      const data = await searchFilterListApi(
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        id,
      );
      if (data) {
        setSearchedFilterInfo(data);
        data.length > 0
          ? Toast.success("필터가 조회되었습니다.")
          : Toast.info("조회된 필터가 없습니다.");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleApplyFilter = (index: number) => {
    setAllFilterData(searchedFilterInfo[index].filters);
    const modalState: ModalState = {
      type: ModalCompType.TEMPLATE_LIST,
      modalType: "fade",
      isOpen: false,
    };
    dispatch(setModalOpen(modalState));
    Toast.success("필터가 적용되었습니다.");
  };

  const handleClickRemoveFilter = (
    projectId: string,
    filterName: string,
    code: string,
  ) => {
    const newSearchedList = [...searchedFilterInfo];
    const foundPrj = newSearchedList.find(
      (item) => item.projectId === projectId,
    );
    if (foundPrj) {
      const foundFilter = foundPrj.filters.find(
        (filter) => filter.filterName === filterName,
      );
      if (foundFilter) {
        const newOptions = foundFilter?.options.filter(
          (filter) => filter.code !== code,
        );
        if (newOptions && newOptions.length === 0) {
          foundPrj.filters.splice(foundPrj.filters.indexOf(foundFilter), 1);
        }
        foundFilter.options = newOptions;
        setSearchedFilterInfo(newSearchedList);
      }
    }
  };

  const handleClickRemoveUsageFilter = (
    projectId: string,
    filterName: string,
    index: number,
  ) => {
    const newSearchedList = [...searchedFilterInfo];
    const foundPrj = newSearchedList.find(
      (item) => item.projectId === projectId,
    );
    if (foundPrj) {
      const foundFilter = foundPrj.filters.find(
        (filter) => filter.filterName === filterName,
      );
      if (foundFilter) {
        foundFilter?.usage.splice(index, 1);
        if (!foundFilter?.usage.length) {
          foundPrj.filters.splice(foundPrj.filters.indexOf(foundFilter), 1);
        }
        setSearchedFilterInfo(newSearchedList);
      }
    }
  };

  return (
    <S.FilterSearchLayout>
      <FlexBox
        $flexDirection={"row"}
        $justifyContent={"end"}
        $gap={10}
        className={"mb-16"}
      >
        <div className={"calender-container"}>
          <Calendar
            dateFormat="yyyy.MM.dd"
            setSelectedDate={(date: Date) => {
              setStartDate(date);
              if (endDate < date) setEndDate(date);
            }}
            selectedDate={startDate}
            maxDate={endDate}
          />
        </div>
        <Typography as={"span"} $fontSize={17} $fontWeight={700}>
          -
        </Typography>
        <div className={"calender-container"}>
          <Calendar
            dateFormat="yyyy.MM.dd"
            setSelectedDate={(date: Date) => {
              setEndDate(date);
            }}
            minDate={startDate}
            selectedDate={endDate}
          />
        </div>
        <Button
          variant={"black000"}
          type={"button"}
          className={"search-btn"}
          onClick={() => handleClickSearchFilter()}
        >
          <FaSearch />
        </Button>
      </FlexBox>
      <Dotted />
      <div className={"search-info-container"}>
        {searchedFilterInfo.map(
          ({ campaignTitle, projectId, filters }, index) => (
            <Card key={projectId} className={"mb-16"}>
              <FlexBox
                $flexDirection={"row"}
                $justifyContent={"space-between"}
                $alignItems={"center"}
                className={"mb-16"}
              >
                <Typography as={"span"} $fontSize={17} $fontWeight={700}>
                  프로젝트: {campaignTitle}
                </Typography>
                <Button
                  className={"copy-btn"}
                  onClick={() => handleApplyFilter(index)}
                >
                  <FaCopy color={"#fff"} />
                </Button>
              </FlexBox>
              <div className={"project-layout"}>
                {filters.map(({ filterName, options, usage }) => (
                  <Card key={`${projectId}-${filterName}`}>
                    <Typography as={"span"} $fontWeight={600}>
                      {filterName}
                    </Typography>
                    <ul className={classNames("pt-8", "pl-16")}>
                      {options.map(({ label, selectOption, code }) => (
                        <li
                          className={"pb-8"}
                          key={`${projectId}-${label ? label : selectOption[0]?.label}`}
                        >
                          <FlexBox
                            $flexDirection={"row"}
                            $justifyContent={"flex-start"}
                            $gap={3}
                          >
                            <Typography as={"span"}>
                              {label && !selectOption[0]?.label && `- ${label}`}
                            </Typography>
                            <Typography as={"span"}>
                              {!label &&
                                selectOption[0]?.label &&
                                `- ${selectOption[0]?.label}`}
                            </Typography>
                            <Typography as={"span"}>
                              {label &&
                                selectOption[0]?.label &&
                                `- ${label}: ${selectOption[0]?.label}`}
                            </Typography>
                            <Button
                              variant={"icon"}
                              onClick={() =>
                                handleClickRemoveFilter(
                                  projectId,
                                  filterName,
                                  code,
                                )
                              }
                            >
                              <MdOutlineCancel color={"#535353"} size={20} />
                            </Button>
                          </FlexBox>
                        </li>
                      ))}
                    </ul>
                    <ul className={classNames("pt-8", "pl-16")}>
                      {0 < usage.length &&
                        usage.map(
                          (
                            {
                              cat1: { name: name1 },
                              cat2: { name: name2 },
                              cat3: { name: name3 },
                            },
                            index,
                          ) => (
                            <li
                              className={"pb-8"}
                              key={`${projectId}-${name1}-${name2}-${name3}`}
                            >
                              <FlexBox
                                $flexDirection={"row"}
                                $alignItems={"center"}
                                $gap={3}
                              >
                                <Typography as={"span"}>
                                  - {name1} {">"} {name2} {">"} {name3}
                                </Typography>
                                <Button
                                  variant={"icon"}
                                  onClick={() =>
                                    handleClickRemoveUsageFilter(
                                      projectId,
                                      filterName,
                                      index,
                                    )
                                  }
                                >
                                  <MdOutlineCancel
                                    color={"#535353"}
                                    size={20}
                                  />
                                </Button>
                              </FlexBox>
                            </li>
                          ),
                        )}
                    </ul>
                  </Card>
                ))}
              </div>
            </Card>
          ),
        )}
        {!searchedFilterInfo.length && (
          <FlexBox
            $justifyContent={"flex-start"}
            style={{
              minHeight: 150,
            }}
          >
            <Typography as={"span"} $fontSize={17} $fontWeight={700}>
              조회된 필터가 없습니다.
            </Typography>
          </FlexBox>
        )}
      </div>
    </S.FilterSearchLayout>
  );
};
