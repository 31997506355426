import React, { ReactElement } from "react";
import styled from "styled-components";

const UserIconLayout = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserIcon = (): ReactElement => {
  return (
    <UserIconLayout>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="26" height="26" rx="5.57143" fill="white" />
        <circle
          cx="12.747"
          cy="8.35714"
          r="3.71429"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9236 15.6428C15.6488 15.0792 14.2076 14.7857 12.747 14.7857C10.9533 14.7857 9.18889 15.2284 7.72599 16.0703C6.26367 16.9118 5.14763 18.1294 4.62986 19.5787C4.44405 20.0988 4.71503 20.671 5.23512 20.8568C5.75521 21.0426 6.32746 20.7716 6.51327 20.2515C6.84198 19.3315 7.59118 18.4554 8.72358 17.8037C9.85539 17.1524 11.2701 16.7857 12.747 16.7857C13.1516 16.7857 13.5515 16.8132 13.9422 16.8665C14.2453 16.1475 14.9567 15.6428 15.786 15.6428L16.9236 15.6428Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4999 13.9286C20.4999 13.3763 20.0522 12.9286 19.4999 12.9286C18.9476 12.9286 18.4999 13.3763 18.4999 13.9286V16.6429L15.7856 16.6429C15.2334 16.6429 14.7856 17.0906 14.7856 17.6429C14.7856 18.1951 15.2334 18.6429 15.7856 18.6429H18.4999V21.3571C18.4999 21.9094 18.9476 22.3571 19.4999 22.3571C20.0522 22.3571 20.4999 21.9094 20.4999 21.3571V18.6429H23.2142C23.7665 18.6429 24.2142 18.1951 24.2142 17.6429C24.2142 17.0906 23.7665 16.6429 23.2142 16.6429L20.4999 16.6429V13.9286Z"
          fill="url(#paint0_linear_369_1416)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_369_1416"
            x1="14.6022"
            y1="15.5522"
            x2="25.4814"
            y2="17.0108"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#54B6FF" />
            <stop offset="1" stopColor="#CB03CD" />
          </linearGradient>
        </defs>
      </svg>
    </UserIconLayout>
  );
};
