import { axiosInstance } from "@libs/axios/axiosInstance.tsx";

const USERS = `/users`;

export const verifyApi = async (token: string): Promise<boolean | void> => {
  if (!token) throw new Error("로그인 정보가 존재하지 않습니다.");
  const url = `${USERS}/verify/${token}`;
  return axiosInstance.get(url);
};

// 로그아웃
export const signOutApi = async (): Promise<boolean> => {
  const url = `${USERS}/sign-out`;
  const { data } = await axiosInstance.get(url);
  return data.result;
};
