import { ReactElement, useEffect } from "react";
import { Outlet, useParams } from "react-router";
import styled from "styled-components";
import { ProgressTab } from "@components/project/common/ProgressTab.tsx";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import { useLocation } from "react-router-dom";
import { setProjectTab, Tabs } from "@store/slices/projectSlice.ts";

const EditPageLayout = styled.div``;

const EditFormLayout = styled.div`
  width: 100%;
  height: 100%;
`;

export const EditPage = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { currentTab } = useAppSelector((state) => state.projectStore);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const locationPathObserver = () => {
    const { pathname } = location;
    const pathSegments = pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    if (lastSegment === "template") dispatch(setProjectTab(Tabs.MESSAGE));
    else if (
      lastSegment === "targeting" ||
      lastSegment === "filtering" ||
      lastSegment === "request"
    )
      dispatch(setProjectTab(Tabs.SAMPLING));
    else if (lastSegment === "report") dispatch(setProjectTab(Tabs.REPORT));
    else dispatch(setProjectTab(Tabs.SETTING));
  };

  useEffect(() => {
    locationPathObserver();
  }, [location]);

  return (
    <EditPageLayout>
      <ProgressTab currentTab={currentTab} />
      <EditFormLayout>
        <Outlet
          context={{
            id,
          }}
        />
      </EditFormLayout>
    </EditPageLayout>
  );
};
