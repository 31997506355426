import { useOutletContext } from "react-router";
import { CreateProjectState, EditProjectState } from "@bizchat/api-interface";
import {
  editState,
  RegistrationFormState,
} from "@components/project/state/registrationFormState.ts";
import {
  createProjectApi,
  findProjectApi,
  updateProjectApi,
} from "@apis/project";
import { ReactElement, SetStateAction, useEffect, useState } from "react";
import { Reward } from "@bizchat/api-interface";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Toast } from "@components/common/toast/Toast.tsx";
import { useProjectConfigQuery } from "@services/queries/hooks/useProjectConfig.ts";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { ProjectInformation } from "@components/project/setting/registration/ProjectInformation.tsx";
import { ProjectOptions } from "@components/project/setting/registration/ProjectOptions.tsx";
import { Card } from "@components/common/card/Card.tsx";
import { RewardCalculationTable } from "@components/project/setting/registration/RewardCalculationTable.tsx";
import { Dotted } from "@components/common/line";
import { StepButtonGroup } from "@components/project/common/StepButtonGroup.tsx";
import { SpinnerBox } from "@components/common/spinner/SpinnerBox.tsx";

export const SettingView = (): ReactElement => {
  const context = useOutletContext<{ id: string } | undefined>();
  const id = context?.id;
  // id 존재 기존 프로젝트 업데이트 없으면 생성
  const [projectState, setProjectState] = useState<
    EditProjectState | CreateProjectState
  >(id ? editState : RegistrationFormState);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();

  // id로 기존 프로젝트 정보 불러오기
  const fetchData = async () => {
    if (!id) return;
    setPending(true);

    try {
      const result = await findProjectApi(id);
      if (result && result.length > 0) {
        const state: EditProjectState = result[0];

        state.startDate = new Date(state.startDate);
        state.endDate = new Date(state.endDate);
        setProjectState({ ...projectState, ...state });
      }
    } catch (e) {
      const error = e instanceof AxiosError;
      if (error) {
        const axiosError = e as unknown as AxiosError;
        if (axiosError.response) {
          const { message } = axiosError.response.data as AxiosError;
          Toast.error(message);
        }
      }
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchData().catch();
  }, [id]);

  // 문자 발송 유형/발신 번호, 리워드 옵션 불러오기
  const configQuery = useProjectConfigQuery();
  if (configQuery.isLoading) return <SpinnerBox />;
  const { data } = configQuery;

  const onChangeInput = (name: string, value: string | number): void => {
    setProjectState({
      ...projectState,
      [name]: value,
    });
  };

  const onChangeDate = (date: SetStateAction<Date>, dateType: string) => {
    setProjectState({
      ...projectState,
      [dateType]: date as Date,
    });
  };

  const onChangeMessageType = (name: string, value: string, key: string) => {
    setProjectState({
      ...projectState,
      sndNum: {
        label: name,
        value,
        key,
      },
    });
  };

  const onChangeConsent = (value: boolean, rewards?: Reward[]) => {
    if (!value && rewards) {
      setProjectState({
        ...projectState,
        informationConsent: value,
        rewards,
      });
    } else {
      setProjectState({
        ...projectState,
        informationConsent: value,
      });
    }
  };

  const onChangeRewards = (rewards: Reward[]) => {
    setProjectState({
      ...projectState,
      rewards,
    });
  };

  const handleClickCreateProject = async () => {
    setPending(true);
    try {
      const { data } = await createProjectApi(projectState);
      if (data && data.result) {
        Toast.success("프로젝트가 생성되었습니다.");
        const { _id } = data.data;
        navigate(`/edit/${_id}/template`);
      }
    } catch (e) {
      const error = e instanceof AxiosError;
      if (error) {
        const axiosError = e as unknown as AxiosError;
        if (axiosError.response) {
          const { message } = axiosError.response.data as AxiosError;
          Toast.error(message);
        }
      }
    } finally {
      setPending(false);
    }
  };

  const handleClickSaveProject = async () => {
    setPending(true);
    try {
      const { data } = await updateProjectApi(projectState);
      if (data.result) {
        Toast.success("프로젝트가 저장되었습니다.");
        return data.result;
      }
      return false;
    } catch (e) {
      const error = e instanceof AxiosError;
      if (error) {
        const axiosError = e as unknown as AxiosError;
        if (axiosError.response) {
          const { message } = axiosError.response.data as AxiosError;
          Toast.error(message);
        }
      }
      return false;
    } finally {
      setPending(false);
    }
  };

  const handleClickUpdateProject = async () => {
    if (!id) return;
    const result = await handleClickSaveProject();
    if (result) navigate(`/edit/${id}/template`);
  };

  return (
    <>
      <FlexBox $gap={30}>
        <Card>
          <ProjectInformation
            projectState={projectState}
            onChangeTextInput={onChangeInput}
            onChangeNumberInput={onChangeInput}
            onChangeDate={onChangeDate}
          />
        </Card>
        <Card>
          <ProjectOptions
            rewardsType={data?.rewardsType || []}
            messageType={data?.messageType || []}
            onChangeMessageType={onChangeMessageType}
            onChangeConsent={onChangeConsent}
            sndNum={projectState.sndNum}
            informationConsent={projectState.informationConsent}
            completedCount={projectState.completedCount}
            rewards={projectState.rewards}
          />
          <Dotted />
          <RewardCalculationTable
            rewards={projectState.rewards}
            completedCount={projectState.completedCount}
            informationConsent={projectState.informationConsent}
            rewardsType={data?.rewardsType || []}
            onChangeMessageType={onChangeMessageType}
            onChangeConsent={onChangeConsent}
            onChangeRewards={onChangeRewards}
          />
        </Card>
      </FlexBox>
      {id && (
        <StepButtonGroup
          pending={pending}
          onClickSave={handleClickSaveProject}
          onClickNext={handleClickUpdateProject}
        />
      )}
      {!id && (
        <StepButtonGroup
          pending={pending}
          onClickNext={handleClickCreateProject}
        />
      )}
    </>
  );
};
