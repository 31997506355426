import React, { ChangeEvent, ReactElement } from "react";
import { RewardPoint, RewardType } from "@bizchat/api-interface";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { RewardSelectLayout } from "@components/terms/styles/RewardSelect.styled.ts";

interface RewardSelectProps {
  rewards: RewardPoint[];
  rewardType: RewardType;
  onChangeRewardType: (type: RewardType) => void;
}

export const RewardSelect = (props: RewardSelectProps): ReactElement => {
  const { rewardType, rewards, onChangeRewardType } = props;

  const handleChangeRewardType = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const type = value as RewardType;
    onChangeRewardType(type);
  };

  const rewardLabel = (rewardType: RewardType, pay: number) => {
    if (rewardType === RewardType.GIFTCERTIFICATE)
      return `문화상품권 (${pay}원권)`;
    else if (rewardType === RewardType.OKCASHBACK)
      return `OK 캐쉬백 (${pay}원)`;
    else if (rewardType === RewardType.NAVERPAY) return `네이버페이 (${pay}원)`;
  };

  return (
    <RewardSelectLayout>
      <p className={"reward_title"}>* 지급받고 싶은 리워드를 선택해주세요.</p>
      <FlexBox $flexDirection={"row"} $justifyContent={"flex-start"} $gap={10}>
        {rewards.map((reward) => {
          return (
            <label key={reward.type}>
              <input
                type="radio"
                name="reward"
                value={reward.type}
                checked={rewardType === reward.type}
                onChange={handleChangeRewardType}
              />
              <Typography
                as={"span"}
                $fontColor={"textBlack200"}
                $fontSize={14}
                $fontWeight={500}
              >
                {rewardLabel(reward.type, reward.point)}
              </Typography>
            </label>
          );
        })}
      </FlexBox>
    </RewardSelectLayout>
  );
};
