import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { AtsReGenerateBody, SamplingFilterList } from "@bizchat/api-interface";
import {
  AxiosResponseData,
  axiosResponseData,
} from "@libs/axios/axiosResponse.ts";
import { AxiosRequestConfig, AxiosResponse } from "axios";

const SAMPLING_FILTER = `/sampling/filter`;

export const atsReGenerateApi = async (atsId: string) => {
  const url = `${SAMPLING_FILTER}/ats/re-generate/${atsId}`;
  const { data } = await axiosInstance.patch(url);
  return data;
};

// 발송 요청서 필터 조회
export const getFilterListApi = async (
  targetProject: string,
): Promise<SamplingFilterList[] | null> => {
  const url = `${SAMPLING_FILTER}/ats/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<SamplingFilterList[]>>
  >(url);
  return axiosResponseData<SamplingFilterList[]>(data);
};

// 발송 모수 재 요청
export const generateAtsMosuApi = async (targetProject: string) => {
  const url = `${SAMPLING_FILTER}/ats/re-generate/${targetProject}`;
  const { data } = await axiosInstance.get(url);
  return data;
};

// 발송 모수 재 생성
export const reGenerateAtsMosuApi = async (body: AtsReGenerateBody) => {
  const url = `${SAMPLING_FILTER}/ats/re-generate`;
  const { data } = await axiosInstance.patch(url, body);
  return data;
};
