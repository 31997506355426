import { useQuery } from "@tanstack/react-query";
import { projectQueryKey } from "@services/queries/keys";
import {
  customerListOptionApi,
  registrationFormConfigApi,
} from "@apis/project";
import { Options, ProjectConfig } from "@bizchat/api-interface";

export const useProjectConfigQuery = () => {
  return useQuery<unknown, unknown, ProjectConfig, string[]>({
    queryKey: [projectQueryKey.registFormConfig],
    queryFn: registrationFormConfigApi,
    gcTime: 6 * 60 * 60 * 1000, // 6 시간
    staleTime: 6 * 60 * 60 * 1000,
  });
};

export const useCustomerSelectList = () => {
  return useQuery<unknown, unknown, Options[], string[]>({
    queryKey: [projectQueryKey.customerList],
    queryFn: customerListOptionApi,
    gcTime: 6 * 60 * 60 * 1000, // 6 시간
    staleTime: 6 * 60 * 60 * 1000,
  });
};
