import React, { ReactElement } from "react";
import { RequestListMainRow } from "@components/project/sampling/request/RequestListTable/RequestListBody/RequestListMainRow.tsx";
import { RequestListHistoryRow } from "@components/project/sampling/request/RequestListTable/RequestListBody/RequestListHistoryRow.tsx";
import { RequestListEmptyRow } from "@components/project/sampling/request/RequestListTable/RequestListBody/RequestListEmptyRow.tsx";
import { RequestAtsList } from "@components/project/views/RequestView.tsx";
import { useAppSelector } from "@hooks/useRedux.ts";
import { Option } from "@components/common/select-box/SelectBox.tsx";
interface RequestListBodyProps {
  requestListState: RequestAtsList[];
  filterCodeList: string[];
  handleCreateCampaignSingle: (state: number) => void;
  onClickAtsUpdate: (index?: number) => void;
  handleChangeCampaignStatus: (
    v: Option,
    atsId: string,
    campaignId: string,
  ) => void;
  refetch: () => void;
}
export const RequestListBody = ({
  requestListState,
  filterCodeList,
  onClickAtsUpdate,
  handleCreateCampaignSingle,
  handleChangeCampaignStatus,
  refetch,
}: RequestListBodyProps): ReactElement => {
  const shownHistoryCampaign = useAppSelector(
    (state) => state.requestStore.shownHistoryCampaign,
  );
  return (
    <div className={"tbody-container"}>
      <div className={"tbody"}>
        {requestListState.map((request, index) => (
          <React.Fragment key={`ats-${request._id}`}>
            <RequestListMainRow
              request={request}
              index={index}
              handleCreateCampaignSingle={handleCreateCampaignSingle}
              onClickAtsUpdate={onClickAtsUpdate}
            />
            {shownHistoryCampaign === request._id &&
              0 < request.historyList.length && (
                <RequestListHistoryRow
                  request={request}
                  filterCodeList={filterCodeList}
                  handleChangeCampaignStatus={handleChangeCampaignStatus}
                  refetch={refetch}
                />
              )}
            {shownHistoryCampaign === request._id &&
              request.historyList.length < 1 && <RequestListEmptyRow />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
