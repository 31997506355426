import {
  SamplingFilterList,
  SamplingFilterType,
  SearchFilterList,
} from "@bizchat/api-interface";
import { axiosInstance } from "@libs/axios/axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  axiosResponseData,
  AxiosResponseData,
} from "@libs/axios/axiosResponse";
import { UsageFilterData } from "@components/project/views/FilteringView.tsx";
import { SearchedFilter } from "@components/project/sampling/filtering/UsageFilterList.tsx";

const SAMPLING = "/sampling";

export const filterApi = async (): Promise<SamplingFilterList[] | null> => {
  const url = `${SAMPLING}/config/filter`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<SamplingFilterList[]>>
  >(url);
  return axiosResponseData<SamplingFilterList[]>(data);
};

export const filterFallbackApi = async (): Promise<
  SamplingFilterList[] | null
> => {
  const url = `${SAMPLING}/config/filter/fallback`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<SamplingFilterList[]>>
  >(url);
  return axiosResponseData<SamplingFilterList[]>(data);
};

export const usageFilterFallbackApi = async (): Promise<
  UsageFilterData[] | null
> => {
  const url = `${SAMPLING}/config/usage/filter`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<UsageFilterData[]>>
  >(url);
  return axiosResponseData<UsageFilterData[]>(data);
};

export const searchUsageFilterApi = async (
  metaType: SamplingFilterType,
  keyword: string,
): Promise<SearchedFilter[] | null> => {
  const url = `${SAMPLING}/config/search/usage/filter?type=${metaType}&keyword=${keyword}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<SearchedFilter[]>>
  >(url);
  return axiosResponseData<SearchedFilter[]>(data);
};

export const searchFilterListApi = async (
  startDate: string,
  endDate: string,
  projectId: string,
): Promise<SearchFilterList[]> => {
  const url = `${SAMPLING}/filter/list`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<SearchFilterList[]>>
  >(url, {
    params: {
      startDate,
      endDate,
      projectId,
    },
  });
  const result = axiosResponseData<SearchFilterList[]>(data);
  if (!result) return [];
  return result;
};

/**
 * @description 샘플링 저장 (+ATS 생성)
 * @param body
 */
export const saveFilterApi = async (body: {
  data: SamplingFilterList[];
  targetProject: string;
}): Promise<SamplingFilterList[]> => {
  const url = `${SAMPLING}/filter/ats`;
  const { data } = await axiosInstance.post(url, body);
  const result = axiosResponseData<SamplingFilterList[]>(data);
  if (!result) return [];
  return result;
};

/**
 * @description ATS 생성하지 않고 샘플링 저장
 * @param body
 */
export const onlyFilterSaveNotMakeAtsApi = async (body: {
  data: SamplingFilterList[];
  targetProject: string;
}) => {
  const url = `${SAMPLING}/filter`;
  const { data } = await axiosInstance.post(url, body);
  const result = axiosResponseData<SamplingFilterList[]>(data);
  if (!result) return [];
  return result;
};

export const searchFilter = async (targetProject: string) => {
  const url = `${SAMPLING}/filter/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<SamplingFilterList[]>>
  >(url);
  return axiosResponseData<SamplingFilterList[]>(data);
};
