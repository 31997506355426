import styled from "styled-components";

export const MessageTemplateFormLayout = styled.form`
  width: 100%;
  height: 100%;

  .warning-message {
    color: #ffa5a5;
    font-weight: 700;
    text-align: right;
    width: 100%;
    padding: 1em;
  }
`;

export const ImageLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  width: fit-content;
  height: auto;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .image-remove-button {
    position: relative;
    border: 1px solid #ff7373;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0.4em;
    top: 16px;
    left: 94%;
    background-color: #fbdada;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
`;

export const ButtonsGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`;

export const RewardLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;

export const RcsButtonType = styled.button`
  padding: 0.4em 0.8em;
  background-color: ${({ theme }) => theme.colors.white100};
  width: 120px;
  height: 44px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor2};

  &.active {
    background-color: ${({ theme }) => theme.colors.purple000};
    color: ${({ theme }) => theme.colors.white000};
  }
`;
