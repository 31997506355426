import styled from "styled-components";

/**
 * ===========================================
 * @description 기본 input Style
 * Component - Input.tsx
 * ===========================================
 */
export const Label = styled.label`
  min-width: fit-content;
  width: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .pb-8 {
    padding-bottom: 8px;
  }

  .checkbox_label {
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const Input = styled.input<{ $textAlign: string }>`
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.white000};
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.gray050};
  padding: 10px;
  width: 100%;
  text-align: ${(props) => props.$textAlign};
  font-family: "Pretendard Variable", Pretendard, "Nanum Gothic", sans-serif;

  &:disabled {
    background-color: #ededed;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary} !important;
    background-color: #fff !important;
  }

  &::placeholder {
    color: #adadad;
  }

  &.checkbox {
    display: none;
  }
`;
