import { ReportProject } from "@interfaces/report/report.data.ts";
import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  axiosResponseData,
  AxiosResponseData,
} from "@libs/axios/axiosResponse.ts";
import {
  DonutChart,
  GenerateStatus,
  LineChartInfo,
  Quota,
  ReactionCount,
  ReactionTotalCount,
  SendSuccessCount,
} from "@bizchat/api-interface";

export const getStatusCountInReportApi = async (
  targetProject: string,
): Promise<Quota[]> => {
  const url = `/report/status/count/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<Quota[]>>
  >(url);
  const result = axiosResponseData<Quota[]>(data);
  if (!result) return [];
  return result;
};

export const dailyParticipationApi = async (
  targetProject: string,
): Promise<LineChartInfo> => {
  const url = `/report/daily/participation/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<LineChartInfo>>
  >(url);
  const result = axiosResponseData<LineChartInfo>(data);
  if (!result)
    return {
      categories: [],
      data: [],
    };
  return result;
};

export const timeParticipationApi = async (
  targetProject: string,
): Promise<LineChartInfo> => {
  const url = `/report/time/participation/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<LineChartInfo>>
  >(url);
  const result = axiosResponseData<LineChartInfo>(data);
  if (!result)
    return {
      categories: [],
      data: [],
    };
  return result;
};

export const reportProjectInfoApi = async (
  targetProject: string,
): Promise<ReportProject> => {
  const url = `/projects/report/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<ReportProject>>
  >(url);

  const result = axiosResponseData<ReportProject>(data);
  if (!result)
    return {
      campaignTitle: "",
      startDate: null,
      endDate: null,
      statisticsGenerate: GenerateStatus.READY,
      statisticsDate: null,
    };
  return result;
};

export const preferenceRewardTypeApi = async (
  targetProject: string,
): Promise<DonutChart[]> => {
  const url = `/report/preference/reward/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<DonutChart[]>>
  >(url);
  const result = axiosResponseData<DonutChart[]>(data);
  if (!result) return [];
  return result;
};

export const messageSendSuccessCountApi = async (targetProject: string) => {
  const url = `/report/send/message/count/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<SendSuccessCount[]>>
  >(url);
  const result = axiosResponseData<SendSuccessCount[]>(data);
  if (!result) return [];
  return result;
};

export const reactionTotalCountApi = async (targetProject: string) => {
  const url = `/report/reaction/total/count/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<ReactionTotalCount[]>>
  >(url);
  const result = axiosResponseData<ReactionTotalCount[]>(data);
  if (!result) return [];
  return result;
};

export const reactionCountApi = async (targetProject: string) => {
  const url = `/report/reaction/count/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<ReactionCount[]>>
  >(url);
  const result = axiosResponseData<ReactionCount[]>(data);
  if (!result) return [];
  return result;
};
