import {
  CityInfo,
  LocationCategory,
  LocationSearchList,
  StateInfo,
  TownInfo,
} from "@bizchat/api-interface";
import { axiosInstance } from "@libs/axios/axiosInstance";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  axiosResponseData,
  AxiosResponseData,
} from "@libs/axios/axiosResponse";

const LOCATION = "/location";

export const stateApi = async (): Promise<StateInfo[] | null> => {
  const url = `${LOCATION}/state`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<StateInfo[]>>
  >(url);
  return axiosResponseData<StateInfo[]>(data);
};

export const cityApi = async (
  stateCode: number,
): Promise<CityInfo[] | null> => {
  const url = `${LOCATION}/city/${stateCode}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<CityInfo[]>>
  >(url);
  return axiosResponseData<CityInfo[]>(data);
};

export const townApi = async (
  stateCode: number,
): Promise<TownInfo[] | null> => {
  const url = `${LOCATION}/town/${stateCode}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<TownInfo[]>>
  >(url);
  return axiosResponseData<TownInfo[]>(data);
};

export const locationCategoryApi = async (): Promise<
  LocationCategory[] | null
> => {
  const url = `${LOCATION}/category`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<LocationCategory[]>>
  >(url);
  return axiosResponseData<LocationCategory[]>(data);
};

/**
 * @description locationCategoryApi fallback function
 * locationCategoryApi 의 요청이 실패하면 실행됩니다.
 */
export const locationUnitApi = async (): Promise<LocationCategory[]> => {
  const url = `${LOCATION}/category/fallback`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<LocationCategory[]>>
  >(url);
  const result = axiosResponseData<LocationCategory[]>(data);
  if (!result) return [];
  return result;
};

export const searchAddressApi = async (
  range: number,
  keyword: string,
): Promise<LocationSearchList[]> => {
  const url = `${LOCATION}/search?depth=${range}&keyword=${keyword}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<LocationSearchList[]>>
  >(url);
  const result = axiosResponseData<LocationSearchList[]>(data);
  if (!result) return [];
  return result;
};
