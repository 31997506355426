import { ReadProjectState } from "@bizchat/api-interface";
import { projectDetailState } from "@components/project/state/registrationFormState.ts";
import { findProjectApi } from "@apis/project";
import React, { ReactElement, useEffect, useState } from "react";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Button } from "@components/common/button/Button.tsx";
import { useProjectConfigQuery } from "@services/queries/hooks/useProjectConfig.ts";
import { useNavigate } from "react-router-dom";
import { Typography } from "@components/common/Typography";
import { ProjectInformation } from "@components/project/setting/registration/ProjectInformation.tsx";
import { ProjectOptions } from "@components/project/setting/registration/ProjectOptions.tsx";
import { RewardOptions } from "@components/project/setting/registration/RewardOptions.tsx";
import { Card } from "@components/common/card/Card.tsx";
import { ModalCompType, ModalState } from "@store/slices/modalSlice.ts";
import { useAppSelector } from "@hooks/useRedux.ts";
import { ModalContainer } from "@containers/ModalContainer.tsx";
import { BillingType } from "@components/project/message/BillingType.tsx";
import { SpinnerBox } from "@components/common/spinner/SpinnerBox.tsx";
import { CampaignListModal } from "@components/campaign/modal/CampaignListModal.tsx";
import {
  ProjectAlertModal,
  ProjectStatusModalProps,
} from "@components/project/modal/ProjectAlertModal.tsx";

export const showModal = (isOpen: boolean, type: ModalCompType) => {
  const modalState: ModalState = {
    type,
    modalType: "fade",
    isOpen,
  };
  return modalState;
};

export const Details = ({ projectId }: { projectId: string }): ReactElement => {
  const [projectState, setProjectState] =
    useState<ReadProjectState>(projectDetailState);
  const navigate = useNavigate();
  const configQuery = useProjectConfigQuery();

  const fetchData = async () => {
    try {
      const result = await findProjectApi(projectId);
      if (result && result.length > 0) {
        const state = result[0];
        state.startDate = new Date(state.startDate);
        state.endDate = new Date(state.endDate);
        setProjectState({ ...projectState, ...state });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData().catch();
  }, [projectId]);

  if (configQuery.isLoading) return <SpinnerBox />;
  const { data } = configQuery;

  const handleClickNavigate = (type: string) => {
    if (type === "cancel") navigate(`/project`);
    else if (type === "edit") navigate(`/edit/${projectId}`);
    else if (type === "request") navigate(`/edit/${projectId}/request`);
  };

  return (
    <>
      <FlexBox $gap={30}>
        <Card>
          <ProjectInformation readOnly={true} projectState={projectState} />
        </Card>

        <Card>
          <ProjectOptions
            readOnly={true}
            rewardsType={data?.rewardsType || []}
            messageType={data?.messageType || []}
            sndNum={projectState.sndNum}
            informationConsent={projectState.informationConsent}
            completedCount={projectState.completedCount}
            rewards={projectState.rewards}
          />

          <BillingType
            readOnly={true}
            billingType={projectState.billingType}
            billingTypeOptions={data?.billingType || []}
          />

          <RewardOptions
            readOnly={true}
            projectState={projectState}
            rewardsType={data?.rewardsType || []}
            billingTypeOptions={data?.billingType || []}
          />
        </Card>
        <FlexBox $flexDirection={"row"} $gap={20} $justifyContent={"flex-end"}>
          <Button
            type={"button"}
            onClick={() => handleClickNavigate("request")}
          >
            <Typography $fontColor={"textWhite"}>
              발송 요청서 바로가기
            </Typography>
          </Button>
          <Button type={"button"} onClick={() => handleClickNavigate("edit")}>
            <Typography $fontColor={"textWhite"}>편집</Typography>
          </Button>
        </FlexBox>
      </FlexBox>
      <ProjectStatusModal
        projectId={projectId}
        projectState={projectState}
        setProjectState={setProjectState}
      />
    </>
  );
};

const ProjectStatusModal = ({
  projectState,
  projectId,
  setProjectState,
}: ProjectStatusModalProps): ReactElement => {
  const modalType = useAppSelector((state) => state.modalStore.modal.type);

  return (
    <ModalContainer>
      {modalType === "template-list" && (
        <ProjectAlertModal
          projectId={projectId}
          projectState={projectState}
          setProjectState={setProjectState}
        />
      )}
      {modalType === "campaign-list" && (
        <CampaignListModal projectId={projectId} />
      )}
    </ModalContainer>
  );
};
