import React, { useEffect, useState } from "react";
import { readFileApi } from "@apis/file";
import { Toast } from "@components/common/toast/Toast.tsx";
import * as S from "@components/project/views/styles/MessageView.styled.ts";
import { Image } from "@components/common/image/Image.tsx";
import styled from "styled-components";

interface CampaignThumbnailProps {
  fileId: string;
}

export const ThumbnailLayout = styled.div`
  margin-top: 2em;
`;
export const CampaignThumbnail = (props: CampaignThumbnailProps) => {
  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const { fileId } = props;

  useEffect(() => {
    if (fileId) readImageFile(fileId);
  }, [fileId]);

  const readImageFile = async (fileId: string) => {
    try {
      const response = await readFileApi(fileId);
      const base64 = arrayBufferToBase64(response.data);
      const mimeType = response.headers["content-type"];
      setImagePreview([`data:${mimeType};base64,${base64}`]);
    } catch (e) {
      Toast.error("이미지를 불러오는데 실패하였습니다.");
    }
  };

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  return (
    <ThumbnailLayout>
      {imagePreview.map((url) => (
        <S.ImageLayout key={crypto.randomUUID()}>
          <S.ImageWrapper>
            <Image src={url} alt={"image-preview"} />
          </S.ImageWrapper>
        </S.ImageLayout>
      ))}
    </ThumbnailLayout>
  );
};
